import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import Component, { ElementsType, Options } from './Component';
import { querySelector } from '../utils/dom';
import {
    DESKTOP_MAX_WIDTH,
    DESKTOP_MIN_WIDTH,
    TABLET_MIN_WIDTH,
} from '../constants';

class Banners extends Component<Options> {
    private sliderInstance!: Swiper;

    constructor(el: ElementsType) {
        super(el);

        const nextButton = querySelector<HTMLElement>(
            '.js-banners-control-next',
            this.el,
        );
        const prevButton = querySelector<HTMLElement>(
            '.js-banners-control-prev',
            this.el,
        );
        const sliderContainer = querySelector<HTMLElement>(
            '.js-banners-slider',
            this.el,
        );

        if (!sliderContainer) {
            return;
        }

        this.sliderInstance = new Swiper(sliderContainer, {
            slidesPerView: 'auto',
            spaceBetween: 18,
            loop: false,
            centeredSlides: false,
            modules: [Navigation],
            height: 576,
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
                disabledClass: 'banners__control--disabled',
            },
            breakpoints: {
                [TABLET_MIN_WIDTH]: {
                    spaceBetween: 32,
                    height: 284,
                },
                [DESKTOP_MIN_WIDTH]: {
                    spaceBetween: 32,
                    height: 296,
                },
                [DESKTOP_MAX_WIDTH]: {
                    spaceBetween: 32,
                    height: 296,
                },
            },
        });
    }
}

export default Banners;
