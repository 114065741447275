import Choices, { Choice, Item } from 'choices.js';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _defaultsDeep from 'lodash/defaultsDeep';
import { ElementsType } from '../../Component';
import AbstractFormField, {
    Options as AbstractFormFieldOptions,
    AllowedTypes,
    AllowedTypesValues,
    Events as AbstractFormFieldEvents,
    defaultOptions as abstractFormDefaultOptions,
} from './AbstractFormField';
import {
    querySelector,
    querySelectorAll,
    addEventListener as addEventListenerCustom,
    removeEventListener as removeEventListenerCustom,
} from '../../../utils/dom';
import { HIDDEN_CLASS } from '../../../constants';

export const Events = {
    ...AbstractFormFieldEvents,
} as const;

export type Options = AbstractFormFieldOptions & {
    initialValue: string[];
    selectJsClass: string;
    hasValueClass: string;
    focusClass: string;
    disabledClass: string;
    labelJsClass: string;
    clearBtnJsClass: string;
    outerContainerJsClass: string;
    outerContainerOpenClass: string;
    outerContainerFocusClass: string;
    outerContainerDisabledClass: string;
    innerContainerJsClass: string;
    listDropdownClass: string;
    listMultipleClass: string;
    itemClass: string;
    placeholderClass: string;
    searchJsClass: string;
    searchHasValueClass: string;
    clearSearchBtnJsClass: string;
    clearSearchBtnHiddenClass: string;
    searchEnabled: boolean;
    multipleSelect: boolean;
    selectParams: {
        searchPlaceholderValue: string;
    };
};

export const defaultOptions: Options = _defaultsDeep(
    {
        initialValue: [],
        selectJsClass: 'js-field-select',
        errorClass: 'field-select--error',
        hasValueClass: 'field-select--has-value',
        focusClass: 'field-select--focused',
        disabledClass: 'field-select--disabled',
        labelJsClass: 'js-field-select-label',
        clearBtnJsClass: 'js-field-select-clear-btn',
        outerContainerJsClass: 'js-field-select-outer',
        outerContainerOpenClass: 'is-open',
        outerContainerFocusClass: 'is-focused',
        outerContainerDisabledClass: 'is-disabled',
        innerContainerJsClass: 'js-field-select-inner',
        listDropdownClass: 'field-select__list--dropdown',
        listMultipleClass: 'field-select__list--multiple',
        itemClass: 'field-select__item',
        placeholderClass: 'field-select__placeholder',
        searchJsClass: 'js-field-select-search-field',
        searchHasValueClass: 'field-select__search--has-value',
        clearSearchBtnJsClass: 'js-field-select-clear-btn-search',
        clearSearchBtnHiddenClass: 'field-select__clear-btn-search--hidden',
        searchEnabled: false,
        multipleSelect: false,
        selectParams: {
            position: 'bottom',
            shouldSort: false,
            itemSelectText: '',
            allowHTML: false,
            removeItemButton: true,
            removeItems: false,
            searchEnabled: false,
            searchFields: ['label'],
            searchResultLimit: 10,
            searchPlaceholderValue: 'Поиск',
            noResultsText: 'По вашему запросу ничего не найдено',
            renderSelectedChoices: 'always',
            resetScrollPosition: false,
            classNames: {
                containerOuter: 'choices field-select__outer js-field-select-outer',
                containerInner: 'choices__inner field-select__inner js-field-select-inner',
                inputCloned: 'choices__input--cloned field-select__search js-field-select-search-field',
                listSingle: 'choices__list--single field-select__list--single',
                listItems: 'choices__list--multiple field-select__list--multiple',
                listDropdown: 'field-select__list--dropdown',
                item: 'choices__item field-select__item',
                button: 'choices__button field-select__clear-btn js-field-select-clear-btn',
                placeholder: 'field-select__placeholder',
            },
        },
    },
    abstractFormDefaultOptions,
);

class Select extends AbstractFormField<Options> {
    protected choicesInstance!: Choices;

    protected select: HTMLSelectElement;

    protected selectValue: string[];

    protected selectLabel?: HTMLElement;

    protected outerContainer: HTMLElement | null;

    protected innerContainer: HTMLElement | null;

    protected listDropdown: HTMLElement | null;

    protected listMultiple: HTMLElement | null;

    protected selectPlaceholder: Choice | null;

    protected searchField: HTMLInputElement | null;

    protected clearSearchBtn?: HTMLButtonElement;

    protected clearSearchBtnShow: boolean;

    protected initialSelectValue: string[];

    protected reset: boolean;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const select = querySelector<HTMLSelectElement>(`.${this.options.selectJsClass}`, this.el);

        if (!select) {
            throw new Error('Block "select" not found.');
        }

        this.select = select;

        this.selectValue = [];

        const selectLabel = querySelector<HTMLElement>(`.${this.options.labelJsClass}`, this.el);
        if (selectLabel) {
            this.selectLabel = selectLabel;
        }

        const clearSearchBtn = querySelector<HTMLButtonElement>(`.${this.options.clearSearchBtnJsClass}`, this.el);
        if (clearSearchBtn) {
            this.clearSearchBtn = clearSearchBtn;
        }

        this.clearSearchBtnShow = false;

        this.outerContainer = null;

        this.innerContainer = null;

        this.listDropdown = null;

        this.listMultiple = null;

        this.selectPlaceholder = null;

        this.searchField = null;

        this.initialSelectValue = [];

        this.reset = false;

        this.initChoices();

        this.observeSelect();

        this.init();
    }

    public getName(): string {
        return this.select.name;
    }

    public getType(): AllowedTypesValues {
        return AllowedTypes.select;
    }

    public getValue(): string[] {
        return [...this.selectValue];
    }

    public setValue(values: string[]): this {
        if (_isArray(values) && values.length) {
            this.choicesInstance.setChoiceByValue([...values]);
        } else {
            throw new Error('Unsupported type of value.');
        }

        this.trigger(Events.change);
        return this;
    }

    public disabled(): this {
        this.el.classList.add(this.options.disabledClass);
        this.select.disabled = true;

        if (this.outerContainer) {
            this.outerContainer.classList.add(this.options.outerContainerDisabledClass);
        }

        this.trigger(Events.changeDisabled);
        return this;
    }

    public notDisabled(): this {
        this.select.disabled = false;
        this.el.classList.remove(this.options.disabledClass);

        if (this.outerContainer) {
            this.outerContainer.classList.remove(this.options.outerContainerDisabledClass);
        }

        this.trigger(Events.changeDisabled);
        return this;
    }

    public isDisabled(): boolean {
        return this.select.disabled;
    }

    public resetToInitial(initialValue?: Options['initialValue']): this {
        // FIXME remove trigger a event and using library api
        const clearBtn = querySelector<HTMLElement>(
            `.${this.options.clearBtnJsClass}`,
            this.el,
        );

        if (clearBtn) {
            const clearBtnMouseEvent = new MouseEvent('mousedown', {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            clearBtn.dispatchEvent(clearBtnMouseEvent);
        }

        console.log(initialValue);

        // if (_isArray(initialValue) && initialValue.length) {
        //     this.setValue(initialValue as string[]);
        // } else if (_isArray(this.options.initialValue) && this.options.initialValue.length) {
        //     this.setValue(this.options.initialValue);
        // } else if (_isArray(this.initialSelectValue) && this.initialSelectValue.length && !this.reset) {
        //     this.selectValue = [];
        //     this.resetChoices();
        //     this.observeSelect();
        //     if (this.options.multipleSelect) {
        //         this.observeListMultiple();
        //     }
        //     this.choicesInstance.clearChoices();
        // } else if (_isArray(this.initialSelectValue) && this.initialSelectValue.length && this.reset) {
        //     this.selectValue = [];
        //     this.hasNoValue();
        //     this.resetChoices();
        //     this.observeSelect();
        //     this.removeSelectedItems();
        //     if (this.selectPlaceholder) {
        //         this.setPlaceholder(this.selectPlaceholder);
        //     }
        //     this.choicesInstance.clearChoices();
        // } else {
        //     this.selectValue = [];
        //     this.hasNoValue();
        //     this.resetChoices();
        //     this.observeSelect();
        // }

        this.trigger(Events.reset);
        return this;
    }

    protected init(): void {
        if (this.options.searchEnabled) {
            addEventListenerCustom(this.clearSearchBtn, 'mousedown', this.handlerClearSearchBtnOnClick);
            addEventListenerCustom(this.clearSearchBtn, 'touchstart', this.handlerClearSearchBtnOnClick);
        }

        const initialSelectValue = this.choicesInstance.getValue(true) as string | string[];

        if (initialSelectValue) {
            this.selectValue = Array.isArray(initialSelectValue)
                ? initialSelectValue.filter((item) => item.length)
                : [initialSelectValue];

            if (this.selectValue.length) {
                this.hasValue();
                this.initialSelectValue = [...this.selectValue];
            }

            if (this.options.multipleSelect) {
                this.observeListMultiple();
            }

            if ((initialSelectValue.length > this.selectValue.length) && this.selectValue.length) {
                this.reset = true;
            }
        }

        this.trigger(Events.initialized);
    }

    protected initChoices(): void {
        const choices = new Choices(this.select, {
            ...this.options.selectParams,
        });

        this.choicesInstance = choices;

        choices.passedElement.element.addEventListener(
            'choice',
            (event: any) => {
                const choice = { ...event.detail.choice };

                if (this.selectValue.includes(choice.value)) {
                    const currentSelectValue = this.choicesInstance.getValue() as Item | Item[];
                    let removeSelectedItem = {} as Item;

                    if (Array.isArray(currentSelectValue)) {
                        removeSelectedItem = currentSelectValue.filter((item) => item.choiceId === choice.id)[0] as Item;
                    } else {
                        removeSelectedItem = currentSelectValue;
                    }

                    if (this.options.multipleSelect) {
                        this.selectValue = this.selectValue.filter((item) => item !== choice.value);

                        if (!this.selectValue.length) {
                            this.hasNoValue();
                        }

                        setTimeout(() => {
                            this.removeSelectedItem(removeSelectedItem);
                            if (this.selectPlaceholder && !this.selectValue.length) {
                                this.setPlaceholder(this.selectPlaceholder);
                            }
                            this.observeListMultiple();
                        }, 100);
                    } else {
                        this.selectValue = [];
                        this.hasNoValue();

                        setTimeout(() => {
                            this.removeSelectedItem(removeSelectedItem);
                            if (this.selectPlaceholder) {
                                this.setPlaceholder(this.selectPlaceholder);
                            }
                        }, 100);
                    }
                } else {
                    this.hasValue();

                    if (this.options.multipleSelect) {
                        if (!this.selectValue.includes(choice.value)) {
                            this.selectValue.push(choice.value);

                            setTimeout(() => {
                                this.observeListMultiple();
                            }, 100);
                        }
                    } else {
                        this.selectValue = [choice.value];
                    }
                }

                if (this.selectValue.length) {
                    this.removePlaceholder();
                }

                if (this.options.multipleSelect) {
                    setTimeout(() => {
                        this.choicesInstance.hideDropdown();
                    }, 100);
                }

                this.trigger(Events.change);
            },
            false,
        );

        choices.passedElement.element.addEventListener(
            'highlightItem',
            () => {
                if (this.options.multipleSelect) {
                    choices.showDropdown();
                    this.observeListMultiple();
                }
            },
            false,
        );

        choices.passedElement.element.addEventListener(
            'removeItem',
            () => {
                this.trigger(Events.reset);
            },
            false,
        );
    }

    protected resetChoices(): void {
        if (this.options.searchEnabled) {
            removeEventListenerCustom(this.searchField, 'input', this.handlerSearchFieldOnInput);

            if (this.listDropdown) {
                removeEventListenerCustom(this.listDropdown, 'focusin', this.handlerListDropdownOnFocusIn);
                removeEventListenerCustom(this.listDropdown, 'focusout', this.handlerListDropdownOnFocusOut);
            }
        }

        removeEventListenerCustom(this.innerContainer, 'mousedown', this.handlerClearBtn);
        removeEventListenerCustom(this.innerContainer, 'touchstart', this.handlerClearBtn);

        this.choicesInstance.destroy();
        this.choicesInstance.init();
    }

    protected observeListMultiple(): void {
        if (!this.listMultiple) {
            return;
        }

        const placeholder = querySelector<HTMLElement>(`.${this.options.placeholderClass}`, this.el);
        const listItems = querySelectorAll<HTMLElement>(`.${this.options.itemClass}`, this.listMultiple);
        const listValues = [...listItems].filter((item) => !item.classList.contains(this.options.placeholderClass));

        if (listValues.length === 1 && placeholder) {
            placeholder.classList.add(HIDDEN_CLASS);
        }

        if (listValues.length >= 2) {
            [...listItems].forEach((item) => item.classList.add(HIDDEN_CLASS));

            const newListItem = document.createElement('div');
            newListItem.classList.add('field-select__item', 'field-select__item--count');
            newListItem.innerHTML = `
                <div class="field-select__count-wrapper">
                    Выбрано
                    <div class="field-select__count">${listValues.length}</div>
                </div>
                <button
                    type="button"
                    class="choices__button field-select__clear-btn js-field-select-clear-btn"
                >
                    Remove item
                </button>
            `;
            this.listMultiple.append(newListItem);
        }
    }

    protected observeSelect(): void {
        this.outerContainer = querySelector<HTMLElement>(`.${this.options.outerContainerJsClass}`, this.el);
        this.innerContainer = querySelector<HTMLElement>(`.${this.options.innerContainerJsClass}`, this.el);
        this.listDropdown = querySelector<HTMLElement>(`.${this.options.listDropdownClass}`, this.el);
        this.selectPlaceholder = this.choicesInstance._store.placeholderChoice as Choice;

        if (this.options.searchEnabled) {
            this.searchField = querySelector<HTMLInputElement>(`.${this.options.searchJsClass}`, this.el);
            addEventListenerCustom(this.searchField, 'input', this.handlerSearchFieldOnInput);

            if (this.listDropdown) {
                addEventListenerCustom(this.listDropdown, 'focusin', this.handlerListDropdownOnFocusIn);
                addEventListenerCustom(this.listDropdown, 'focusout', this.handlerListDropdownOnFocusOut);
            }
        }

        if (this.options.multipleSelect) {
            this.listMultiple = querySelector<HTMLElement>(`.${this.options.listMultipleClass}`, this.el);

            if (this.innerContainer && this.listDropdown && this.searchField) {
                this.innerContainer.removeChild(this.searchField);
                this.listDropdown.prepend(this.searchField);

                this.searchField.placeholder = this.options.selectParams.searchPlaceholderValue;
            }
        }

        addEventListenerCustom(this.innerContainer, 'mousedown', this.handlerClearBtn);
        addEventListenerCustom(this.innerContainer, 'touchstart', this.handlerClearBtn);
    }

    protected hasValue(): void {
        if (!this.el.classList.contains(this.options.hasValueClass)) {
            this.el.classList.add(this.options.hasValueClass);
        }

        if (this.selectLabel && this.selectLabel.classList.contains(HIDDEN_CLASS)) {
            this.selectLabel.classList.remove(HIDDEN_CLASS);
        }
    }

    protected hasNoValue(): void {
        if (this.el.classList.contains(this.options.hasValueClass)) {
            this.el.classList.remove(this.options.hasValueClass);
        }

        if (this.selectLabel && !this.selectLabel.classList.contains(HIDDEN_CLASS)) {
            this.selectLabel.classList.add(HIDDEN_CLASS);
        }
    }

    protected setPlaceholder(placeholder: Choice): void {
        this.choicesInstance._selectPlaceholderChoice(placeholder);
    }

    protected removePlaceholder(): void {
        if (!this.selectPlaceholder) {
            return;
        }

        const placeholder = this.selectPlaceholder;
        const currentSelectValue = this.choicesInstance.getValue() as Item | Item[];
        let placeholderItem = {} as Item;

        if (Array.isArray(currentSelectValue)) {
            placeholderItem = currentSelectValue.filter((item) => item.label === placeholder.label)[0] as Item;
        } else if (currentSelectValue.label === placeholder.label) {
            placeholderItem = currentSelectValue;
        }

        if (!_isEmpty(placeholderItem)) {
            this.choicesInstance._removeItem(<Item>placeholderItem);
        }
    }

    protected removeSelectedItem(item: Item): void {
        this.choicesInstance._removeItem(item);
    }

    protected removeSelectedItems(): void {
        this.choicesInstance.removeActiveItems(0);
    }

    protected handlerClearBtn = (event: Event) => {
        if (this.isDisabled() || (event instanceof MouseEvent && event.which !== 1)) {
            return;
        }

        const target = <HTMLElement>event.target;

        if (!target.classList.contains(this.options.clearBtnJsClass)) {
            return;
        }

        this.selectValue = [];
        this.hasNoValue();

        if (this.options.multipleSelect) {
            this.removeSelectedItems();
        }

        if (this.selectPlaceholder) {
            this.setPlaceholder(this.selectPlaceholder);
        }

        this.trigger(Events.change);
    };

    protected handlerClearSearchBtnOnClick = (event: Event) => {
        if (this.isDisabled()) {
            return;
        }

        if (event instanceof MouseEvent && event.which !== 1) {
            setTimeout(() => {
                this.choicesInstance.showDropdown();
            }, 1);

            return;
        }

        const targetBtn = <HTMLButtonElement>event.currentTarget;
        targetBtn.classList.add(HIDDEN_CLASS);

        this.hasNoValue();
        this.resetChoices();
        this.observeSelect();

        setTimeout(() => {
            this.choicesInstance.showDropdown();
        }, 100);

        const initialSelectValue = this.choicesInstance.getValue() as Item | Item[];

        if (this.selectValue.length) {
            this.hasValue();
            this.setValue(this.selectValue);

            if (initialSelectValue && Array.isArray(initialSelectValue) && this.options.multipleSelect) {
                initialSelectValue.forEach((item) => {
                    if (!this.selectValue.includes(item.value)) {
                        this.removeSelectedItem(<Item>item);
                    }
                });
            }

            if (this.options.multipleSelect) {
                this.observeListMultiple();
            }
        }

        if (initialSelectValue && !this.selectValue.length) {
            setTimeout(() => {
                if (this.options.multipleSelect) {
                    this.removeSelectedItems();
                } else {
                    this.removeSelectedItem(<Item>initialSelectValue);
                }

                if (this.selectPlaceholder) {
                    this.setPlaceholder(this.selectPlaceholder);
                }
            }, 100);
        }

        this.trigger(Events.change);
    };

    protected handlerSearchFieldOnInput = (event: Event) => {
        if (this.isDisabled()) {
            return;
        }

        const searchField = <HTMLInputElement>event.target;

        if (searchField.value) {
            if (this.clearSearchBtn) {
                this.clearSearchBtnShow = true;
                this.clearSearchBtn.classList.remove(HIDDEN_CLASS);
            }

            if (!searchField.classList.contains(this.options.searchHasValueClass)) {
                searchField.classList.add(this.options.searchHasValueClass);
            }
        } else {
            if (this.clearSearchBtn) {
                this.clearSearchBtnShow = false;
                this.clearSearchBtn.classList.add(HIDDEN_CLASS);
            }

            if (searchField.classList.contains(this.options.searchHasValueClass)) {
                searchField.classList.remove(this.options.searchHasValueClass);
            }
        }
    };

    protected handlerListDropdownOnFocusIn = () => {
        if (this.isDisabled() || !this.clearSearchBtn || !this.clearSearchBtnShow) {
            return;
        }

        this.clearSearchBtn.classList.remove(this.options.clearSearchBtnHiddenClass);
    };

    protected handlerListDropdownOnFocusOut = (event: Event) => {
        if (this.isDisabled() || !this.clearSearchBtn || !this.clearSearchBtnShow) {
            return;
        }

        const target = <HTMLElement>(<FocusEvent>event).relatedTarget;

        if (target === this.clearSearchBtn) {
            return;
        }

        this.clearSearchBtn.classList.add(this.options.clearSearchBtnHiddenClass);
    };
}

export default Select;
