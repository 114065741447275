// @ts-nocheck
/* eslint-disable */
import Component, { ElementsType, Options } from '../Component';

class KamchatkaMap extends Component<Options> {
    constructor(el: ElementsType) {
        super(el);

        this.init();
    }

    protected init(): void {
        const self = this;

        /**
         * Конвертация переданного значения в тип Integer
         * @param val
         * @returns {Number}
         */
        function intval(val) {
            return parseInt(val, 10);
        }

        /**
         * Узнать, существует ли html-элемент
         * Использование: $('div').isset()
         * @returns {boolean} true, если элемент существует, иначе false
         */
        window.$.fn.isset = function() {
            return this.length > 0;
        };

        /**
         * Карта Камчатки
         * @type {{svg: {Function}, html: {Function}}}
         */
        var kamchatkaMap = (function () {
            var multiselect = false;

            /**
             * Список районов
             * @type {Object} Формат - {'id района': ['Название города', 'svg-path - линия-указатель на город'], ...}
             */
            var mapRegions = {
                'pk': ['Петропавловск-Камчатский', '68,330 100,340 252,340'],
                'elizovo': ['Елизово', '59,325 100,370 155,370'],
                'viluchinsk': ['Вилючинск', '62,332 100,400 167,400'],
                'milkovskiy': ['Мильково', '70,281 137,272 200,272'],
                'sobolevskiy': ['Соболево', '27,295 111,318 175,318'],
                'bistrinskiy': ['Эссо', '72,251 150,225 190,225'],
                'tigilskiy': ['Тигиль', '74,198 60,180 14,180'],
                'palana': ['Палана', '96,163 80,140 34,140'],
                'karaginskiy': ['Оссора', '138,158 73,180 24,180'],
                'olyutorskiy': ['Тиличики', '180,121 85,156 25,156'],
                'penzhinskiy': ['Каменское', '180,67 111,119 44,119'],
                'aleutskiy': ['Никольское', '188,285 170,313 100,313'],
                'ust_kamchatskiy': ['Усть-Камчатск', '128,242 155,220 240,220'],
                'ust_bolsheretskiy': ['Усть-Большерецк', '26,333 73,360 175,360']
            };

            /**
             * Карта Камчатки SVG
             */
            var svgMap = function () {
                /**
                 * URL svg-карты
                 * @type {string}
                 */
                var svgMapUrl = self.el.dataset.svgMapUrl || '';

                /**
                 * Показать регион
                 * @param {string} id ID района в SVG карте
                 */
                var showRegionOnMap = function (id) {
                    var region = $('.kamchatka-svg .svg-area#' + id);

                    // Set inactive others regions
                    if (!multiselect) {
                        $('.kamchatka-svg .svg-line, .kamchatka-svg .svg-txt, .kamchatka-svg .svg-point').hide();
                        $('.kamchatka-svg path').attr('class', '');
                        deselectLabels();

                        // Activate selected region
                        if (id == 'city_pk') {
                            region = $('.kamchatka-svg .svg-area#elizovskiy');
                            region.find('path').attr('class', 'cur');
                            moveRegionToEndOfList(region);
                            showRegionInfo('elizovskiy');
                            showCityIfExists('pk', 1);
                            showCityIfExists('elizovo', 2);
                            showCityIfExists('viluchinsk', 3);
                        } else {
                            region.find('path').attr('class', 'cur');
                            moveRegionToEndOfList(region);
                            showRegionInfo(id);
                            showCityIfExists(id, 1);

                            if (id == 'elizovskiy') {
                                showCityIfExists('pk', 1);
                                showCityIfExists('elizovo', 2);
                                showCityIfExists('viluchinsk', 3);
                            } else if (id == 'tigilskiy') {
                                showCityIfExists('tigilskiy', 1);
                                showCityIfExists('palana', 2);
                            }
                        }
                    } else {
                        if (region.find('path').attr('class') == 'cur') {
                            region.find('path').attr('class', '')
                        } else {
                            region.find('path').attr('class', 'cur');
                        }
                    }
                };

                var showCityIfExists = function (id, index) {
                    if (id in mapRegions) {
                        showLabel(index, mapRegions[id], id);
                    }
                };

                /**
                 * Переместить регион в цонек групп-g для того, что бы можно
                 * было установить border, идущий поверх border-ов соседних регионов.
                 * @param {jQuery} region g-элемент с районом
                 */
                var moveRegionToEndOfList = function (region) {
                    var last = $('.kamchatka-svg g').last();
                    if (region.attr('id') != last.attr('id')) {
                        region.insertAfter(last);
                    }
                };

                /**
                 * Показать надпись
                 * @param {number} labelNum Номер надписи (всего их три, т.е. на карте одновременно может быть показано не более трех городов)
                 * @param {Array} label Массив с описанием района ['Город', 'dvg-path линии указателя на город']
                 * @param {string} id ID района
                 */
                var showLabel = function (labelNum, label, id) {
                    var coord = label[1].split(' ');
                    var start = coord[0].split(',');
                    var mdl = coord[1].split(',');
                    var end = coord[2].split(',');

                    $('.kamchatka-svg .svg-point-' + labelNum).show().attr({
                        cx: start[0],
                        cy: start[1]
                    });

                    $('.kamchatka-svg .svg-line-' + labelNum).show().attr({
                        points: label[1]
                    });

                    $('.kamchatka-svg .svg-txt-' + labelNum).show().text(label[0]).attr({
                        x: intval(end[0]) < intval(mdl[0]) ? intval(end[0]) + 5 : intval(mdl[0]) + 5,
                        y: intval(mdl[1]) - 5,
                        id: 'city_' + id
                    });
                };

                /**
                 * Снять выделение с надписей
                 */
                var deselectLabels = function () {
                    $('.kamchatka-svg .svg-txt').each(function () {
                        $(this).attr('class', $(this).attr('class').replace(' cur', ''));
                    });
                };

                /**
                 * Обработка нажатия на название города
                 */
                var labelClick = function () {
                    clickCity($(this).attr('id'));
                };

                var clickCity = function (cityId) {
                    var obj = $('#'+cityId);

                    deselectLabels();
                    obj.attr('class', obj.attr('class') + ' cur');

                    showRegionInfo(cityId);

                    if (typeof window.regionLinks !== 'undefined') {
                        window.location.href = window.regionLinks[cityId];
                    }
                };

                // Инициализация карты
                $('.kamchatka-svg').css('background','none').load(svgMapUrl, function () {
                    multiselect = $('.map-multiselect').isset();
                    var activeAreaBlock = $('.area-block.active');

                    $(this).on('click', '.svg-area', function () {
                        showRegionOnMap($(this).attr('id'));
                    });

                    $(this).on('click', '.svg-txt', labelClick);

                    if (!multiselect && typeof window.activeRegion === 'undefined' && activeAreaBlock.length) {
                        window.activeRegion = activeAreaBlock.data('id');
                    }

                    if (!multiselect && typeof window.activeRegion !== 'undefined') {
                        showRegionOnMap(window.activeRegion);
                    }

                    if ($(this).data('click')) {
                        clickCity($(this).data('click'));
                    }

                    if ($('.area-map').data('allowedCities')) {
                        var allowed = $('.area-map').data('allowedCities');

                        $.each(mapRegions, function (index) {
                            if (allowed.indexOf(index) === -1) {
                                delete mapRegions[index];
                            }
                        });
                    }

                    if ($('.area-map').data('sizeModifier')) {
                        resizeMap($('.kamchatka-svg svg'), $('.area-map').data('sizeModifier'));
                    }
                });
            };


            /**
             * Карта Камчатки HTML
             */
            var htmlMap = function () {
                /**
                 * URL документа с описанием полигонов карты.
                 * @type {string}
                 */
                var htmlMapUrl = self.el.dataset.htmlMapUrl || '';

                /**
                 * Объект для работы с векторными указателями городов и точками городов.
                 * @type {Raphael}
                 */
                var paper = null;

                /**
                 * Объект для работы с текстовыми лэйблами
                 * @type {HtmlMapLabel}
                 */
                var label = null;

                /**
                 * Настройки подсветки районов на карте.
                 * @type {Object}
                 */
                var mapStyle = {
                    mapKey: 'data-key',
                    stroke: true,
                    strokeColor: '065584',
                    fillColor: '1c89cf',
                    fillOpacity: 1,
                    render_highlight: {
                        fillOpacity: 0.2,
                        strokeOpacity: 0.2
                    }
                };

                /**
                 * Обработка клика по надписи населенному пункту
                 */
                var labelClick = function () {
                    clickCity($(this).attr('id'));
                };

                var clickCity = function (cityId) {
                    var obj = $('#'+cityId);

                    deselectLabels();
                    obj.attr('class', obj.attr('class') + ' cur');

                    showRegionInfo(cityId);

                    if (typeof window.regionLinks !== 'undefined') {
                        window.location.href = window.regionLinks[cityId];
                    }
                };

                /**
                 * Снять выделение с надписей
                 */
                var deselectLabels = function () {
                    $('.area-label').removeClass('cur');
                };

                /**
                 * Скрыть все надписи
                 */
                var hideAllLabels = function () {
                    deselectLabels();
                    $('.area-label').hide();

                    paper.forEach(function (element) {
                        element.hide();
                    });
                };

                /**
                 * Обработка клика по области карты (району)
                 * @param data
                 */
                mapStyle.onClick = function (data) {
                    if (!multiselect) {
                        $('area').mapster('deselect'); // Снимаем выделение с области карты
                        hideAllLabels(); // Скрываем надписи
                        label.show(data.key);
                        showRegionInfo(data.key);
                    }
                };

                var bigMap = $('.big-map');
                if (bigMap.length) {
                    htmlMapUrl = self.el.dataset.htmlBigMapUrl || '';
                }

                // Инициализация карты
                $('.area-map').load(htmlMapUrl, function () {
                    multiselect = $('.map-multiselect').isset();

                    if(!bigMap.length) {
                        paper = Raphael('raphael', 265, 410);
                    } else {
                        paper = Raphael('raphael', bigMap.width(), bigMap.height());
                    }

                    label = new HtmlMapLabel(paper);

                    if ($('.area-map').data('allowedCities')) {
                        var allowed = $('.area-map').data('allowedCities');

                        $.each(mapRegions, function (index) {
                            if (allowed.indexOf(index) === -1) {
                                delete mapRegions[index];
                            }
                        });
                    }

                    label.load(mapRegions);

                    if (!multiselect) {
                        $('.area-label').on('click', labelClick);

                        if (typeof window.activeRegion !== 'undefined') {
                            $('.kamchatka-png img').mapster(mapStyle).mapster('set', true, activeRegion);
                            $('.area-' + activeRegion).show();

                            if ($(this).data('click')) {
                                clickCity($(this).data('click'));
                            }

                            label.show(window.activeRegion);
                        } else {
                            var img = $('.kamchatka-png img').mapster(mapStyle);

                            var activeAreaBlock = $('.area-block.active');
                            if (activeAreaBlock.length) {
                                img.mapster('set', true, 'elizovskiy');
                                $('.area-elizovskiy').show();
                                $('#area-label-pk').trigger('click');
                                label.show('elizovskiy');
                            }
                        }
                    } else {
                        $('.kamchatka-png img').mapster(mapStyle);
                    }

                    if ($('.area-map').data('sizeModifier')) {
                        var cityLabelSize = $('.area-label').css('font-size');
                        var cityLineHeight = $('.area-label').css('line-height');

                        $('.area-label').css('font-size', (parseInt(cityLabelSize) * $('.area-map').data('sizeModifier')) + 'px');
                        $('.area-label').css('line-height', (parseInt(cityLineHeight) / $('.area-map').data('sizeModifier')) + 'px');
                    }
                });
            };

            /**
             * Показать информацию о регионе
             * @param id
             */
            var showRegionInfo = function (id) {
                $('.area-block').hide();
                $('.area-' + id).show();
            };

            /**
             * Лэйбл города для HTML-карты Камчатки
             */
            var HtmlMapLabel = (function ($) {
                // Горизонтальный отступ у названия города
                var labelPadding = 3;

                // Вертикальный отступ от линии-указателя
                var verticalShift = -14;

                // Фикс нивелирующий разницу в позиционировании текста и линии
                var labelPositionFix = 2;

                /**
                 * @type {Raphael}
                 */
                var paper = null;

                /**
                 * Конструктор
                 * @param {Raphael} paperObj
                 * @constructor
                 */
                function Map(paperObj) {
                    paper = paperObj;
                }

                /**
                 * Добавить город на карту с указателем и точкой.
                 * @param {String} start_coordinate Координата города: "x y"
                 * @param {String} mdl_coordinate Координата серидины выносной линии: "x y"
                 * @param {String} text Название города
                 * @param {String} id ID района, к которому относится город
                 * @return {Object}
                 */
                Map.prototype.add = function (start_coordinate, mdl_coordinate, text, id) {
                    var startArr = start_coordinate.split(' ');
                    var mdlArr = mdl_coordinate.split(' ');

                    var start = {x: intval(startArr[0]), y: intval(startArr[1])},
                        mdl = {x: intval(mdlArr[0]), y: intval(mdlArr[1])},
                        end = {x: mdl.x, y: mdl.y};

                    var coefficient = 1;

                    if ($('.area-map').data('sizeModifier')) {
                        coefficient = $('.area-map').data('sizeModifier');
                        start.x *= coefficient;
                        start.y *= coefficient;
                    }

                    var $label = this.addCityLabel(start, mdl, text, id);
                    var labelWidth = $label.width() + labelPadding * 2;

                    if (mdl.x < 1) {
                        end.x -= labelWidth;
                        $label.css('left', intval($label.css('left')) - labelWidth);
                    } else {
                        end.x += labelWidth * coefficient;
                    }

                    var polyline = this.drawPolyline(start, mdl, end, id);
                    var circle = this.drawCircle(start, mdl, id);

                    return {
                        polyline: polyline,
                        circle: circle,
                        label: $label
                    };
                };

                /**
                 * Добавить название блок с названием города.
                 * @param {{x:int, y:int}} start
                 * @param {{x:int, y:int}} mdl
                 * @param {string} title
                 * @param {string} id
                 * @returns {*|jQuery}
                 */
                Map.prototype.addCityLabel = function (start, mdl, title, id) {
                    var position = {
                        left: (start.x + mdl.x + labelPositionFix + labelPadding) + 'px',
                        top: (start.y + mdl.y + verticalShift) + 'px'
                    };

                    return $('<div/>')
                        .addClass('area-label')
                        .attr('id', 'city_' + id)
                        .css(position)
                        .text(title)
                        .hide()
                        .appendTo('.area-label-container');
                };

                /**
                 * Создать точки из общего с svg-картой массива точек mapRegions
                 * @param {Object} points Список районов в формате - {'id района': ['Название города', 'svg-path - линия-указатель на город'], ...}
                 */
                Map.prototype.load = function (points) {
                    // Значение для корректировки по X, относительно координат в points
                    var xCorrectionValue = -20;

                    // Значение для корректировки по Y, относительно координат в points
                    var yCorrectionValue = 0;

                    var coord, start, mdl;

                    for (var id in points) {
                        coord = points[id][1].split(' ');
                        start = coord[0].split(',');
                        mdl = coord[1].split(',');

                        mdl[0] -= intval(start[0]);
                        mdl[1] -= intval(start[1]);
                        start[0] = intval(start[0]) + xCorrectionValue;
                        start[1] = intval(start[1]) + yCorrectionValue;
                        this.add(start[0] + ' ' + start[1], mdl[0] + ' ' + mdl[1], points[id][0], id);
                    }
                };

                /**
                 * Показать группу: точка, линия, текст.
                 * @param {String} id
                 */
                Map.prototype.show = function (id) {
                    if (id == 'elizovskiy') {
                        this.show('pk');
                        this.show('elizovo');
                        this.show('viluchinsk');
                    } else if(id == 'tigilskiy') {
                        if (paper.getById('area-polyline-' + id)) {
                            paper.getById('area-polyline-' + id).show();
                        }

                        if (paper.getById('area-circle-' + id)) {
                            paper.getById('area-circle-' + id).show();
                        }

                        $('#city_' + id).show();
                        this.show('palana');
                    } else {
                        if (paper.getById('area-polyline-' + id)) {
                            paper.getById('area-polyline-' + id).show();
                        }

                        if (paper.getById('area-circle-' + id)) {
                            paper.getById('area-circle-' + id).show();
                        }

                        $('#city_' + id).show();
                    }
                };

                /**
                 * Добавить линию-указатель на город.
                 * @param {{x:int, y:int}} start
                 * @param {{x:int, y:int}} mdl
                 * @param {{x:int, y:int}} end
                 * @param {String} id
                 * @returns {Element}
                 */
                Map.prototype.drawPolyline = function (start, mdl, end, id) {
                    var path = 'M' + start.x + ' ' + start.y
                        + ' L' + (start.x + mdl.x) + ' ' + (start.y + mdl.y)
                        + ' L' + (start.x + end.x) + ' ' + (start.y + end.y);
                    var obj = paper.path(path);
                    obj.attr({ 'stroke': '#aaa', 'stroke-width': '1px' });
                    obj.id = 'area-polyline-' + id;
                    obj.hide();
                    return obj;
                };

                /**
                 * Добавить точку-город.
                 * @param {{x:int, y:int}} start
                 * @param mdl
                 * @param {number} id
                 * @returns {Element}
                 */
                Map.prototype.drawCircle = function (start, mdl, id) {
                    var radius = 3;
                    var obj = paper.circle(start.x, start.y, radius);
                    obj.attr({ fill: '#065584', 'stroke': 'none' });
                    obj.id = 'area-circle-' + id;
                    obj.hide();
                    return obj;
                };

                return Map;
            })(jQuery);

            // Возвращаем svg и html варианты карты
            return {
                svg: svgMap,
                html: htmlMap
            };
        })();

        /**
         * resizeMap($('.kamchatka-svg svg'), 2);
         *
         * @param map
         * @param koef
         */
        function resizeMap(map, koef) {
            var width = map.width() * koef;
            var height = map.height() * koef;
            map.attr('width', width);
            map.attr('height', height);
        }

        kamchatkaMap.svg();
    }
}

export default KamchatkaMap;
/* eslint-enable */
