import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';
import { addEventListener, querySelector } from '../utils/dom';

type Options = {
    hiddenClass: string;
    storageKey: string;
};

export const defaultOptions: Options = {
    hiddenClass: 'broadcast-live--hidden',
    storageKey: 'isNotificationClosed',
};

class Notification extends Component<Options> {
    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        if (sessionStorage.getItem(this.options.storageKey)) {
            el.classList.add(this.options.hiddenClass);
        } else {
            el.classList.remove(this.options.hiddenClass);
        }

        const closeButton = querySelector<HTMLElement>(
            '.js-notification-close',
            this.el,
        );

        addEventListener(closeButton, 'click', (): void => {
            el.classList.add(this.options.hiddenClass);
            sessionStorage.setItem(this.options.storageKey, 'true');
        });
    }
}

export default Notification;
