import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';

export const Events = {
    tick: 'tick',
} as const;

export type Options = {
    interval: number;
    events: {
        [Events.tick]?: () => void;
    };
};

export const defaultOptions: Options = {
    interval: 1000,
    events: {},
};

class SimpleTimer extends Component<Options> {
    protected timer!: unknown | null;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);
    }

    public start() {
        if (this.timer !== null) {
            clearInterval(this.timer as number);
            this.timer = null;
        }
        this.timer = setInterval(() => {
            this.handlerOnChange();
        }, this.options.interval);
    }

    public stop() {
        if (this.timer !== null) {
            clearInterval(this.timer as number);
            this.timer = null;
        }
    }

    protected handlerOnChange = () => {
        this.trigger(Events.tick);
    };
}

export default SimpleTimer;
