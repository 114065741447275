import _first from 'lodash/first';
import _template from 'lodash/template';
import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';
import SimpleTimer, { Events as SimpleTimerEvents } from './SimpleTimer';
import { querySelector } from '../utils/dom';
import { numberToString } from '../utils/string';
import { TRANSPARENT_CLASS } from '../constants';

export const Events = {
    initialized: 'initialized',
    tick: 'tick',
} as const;

export type Options = {
    templateOptions?: Exclude<Parameters<typeof _template>[1], undefined>;
    template: string;
    splitterDisplaySwitch: boolean;
    hideSplitterClass: string;
    splitter: string;
    events: {
        [Events.tick]?: () => void | string;
    };
};

export const defaultOptions: Options = {
    templateOptions: { interpolate: /{{([\s\S]+?)}}/g },
    hideSplitterClass: TRANSPARENT_CLASS,
    splitter: ':',
    template: `
        <div class="simple-clock">
            <div class="simple-clock__item simple-clock__item--hours">{{ hours }}</div>
            <div class="simple-clock__item simple-clock__item--splitter {{ hideClass }}">{{ splitter }}</div>
            <div class="simple-clock__item simple-clock__item--minutes">{{ minutes }}</div>
        </div>
    `,
    splitterDisplaySwitch: true,
    events: {},
};

class SimpleClock extends Component<Options> {
    protected hideSplitter: boolean;

    protected templateObj!: ReturnType<typeof _template>;

    protected timer!: SimpleTimer;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.hideSplitter = false;

        this.templateObj = _template(
            this.options.template,
            this.options.templateOptions,
        );

        const timer: SimpleTimer | undefined = _first(
            SimpleTimer.init({
                elements: querySelector<HTMLElement>('.js-primary-clock'),
                options: {
                    interval: 1000,
                    events: {
                        [SimpleTimerEvents.tick]: (component: SimpleTimer): void => {
                            let hideClass = '';

                            if (this.options.splitterDisplaySwitch && this.hideSplitter) {
                                hideClass = TRANSPARENT_CLASS;
                            }

                            const date = SimpleClock.getDateForTimezone(12);

                            const hours = numberToString(date.getHours());
                            const minutes = numberToString(date.getMinutes());

                            component.el.innerHTML = this.templateObj({
                                splitter: this.options.splitter,
                                hours,
                                minutes,
                                hideClass,
                            });

                            this.hideSplitter = !this.hideSplitter;
                        },
                    },
                },
            }),
        );

        if (!timer) {
            throw new Error('Timer not executed.');
        }

        this.timer = timer;

        this.start();

        this.trigger(Events.initialized);
    }

    public start() {
        this.timer.start();
    }

    public stop() {
        this.timer.stop();
    }

    private static getDateForTimezone(offset: number) {
        const date = new Date();
        const utc = date.getTime() + (date.getTimezoneOffset() * 60000);

        return new Date(utc + (3600000 * offset));
    }
}

export default SimpleClock;
