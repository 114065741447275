import _defaultsDeep from 'lodash/defaultsDeep';
import _first from 'lodash/first';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Component, { ElementsType } from './Component';
import Modal, { Events as ModalEvents } from './Modal';
import {
    DESKTOP_MIN_WIDTH,
    MOBILE_MIN_WIDTH,
    TABLET_MIN_WIDTH,
    MODAL_CONTAINER,
} from '../constants';
import { querySelector } from '../utils/dom';
import { htmlInstance, LoadingResponse } from '../utils/ajax';

export const Events = {
    initialized: 'initialized',
    opening: 'opening',
    opened: 'opened',
    closing: 'closing',
    closed: 'closed',
    endLoading: 'endLoading',
} as const;
export type LazyLoadObject = {
    url: string;
};
export type Options = {
    toggleClass: string;
    modalContainer: string;
    lazyLoad: LazyLoadObject | boolean;
    events: {
        [Events.initialized]?: () => void;
        [Events.endLoading]?: () => void;
        [Events.opening]?: () => void;
        [Events.opened]?: () => void;
        [Events.closing]?: () => void;
        [Events.closed]?: () => void;
    };
};

export const defaultOptions: Options = {
    toggleClass: 'modal--opened',
    modalContainer: MODAL_CONTAINER,
    events: {},
    lazyLoad: false,
};

class ModalGallery extends Component<Options> {
    private loading: boolean;

    private loaded: boolean;

    private modalInstance!: Modal;

    private sliderInstance!: Swiper;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.loading = false;
        this.loaded = false;
    }

    public async open(id: number = 0): Promise<void> {
        await this.loadModal();

        if (!this.modalInstance) {
            return;
        }

        let index = 0;
        this.sliderInstance.slides.find((slide, slideIndex): boolean => {
            const item = querySelector<HTMLElement>(
                '.js-modal-gallery-slider-item',
                slide,
            );
            if (!item) {
                return false;
            }
            const slideId = parseInt(
                item.dataset.modalGallerySliderItemId || '0',
                10,
            );

            if (slideId === id) {
                index = slideIndex;
                return true;
            }

            return false;
        });

        this.sliderInstance.slideTo(index);
        this.modalInstance.open();
    }

    protected async loadModal(): Promise<void> {
        if (this.loaded || this.loading) {
            return;
        }

        if (this.options.lazyLoad === false) {
            const modalGalleryContainer = querySelector<HTMLElement>(
                '.js-modal-gallery',
                this.el,
            );
            if (!modalGalleryContainer) {
                return;
            }

            this.loading = false;
            this.loaded = true;

            const modalInstance: Modal | undefined = _first(
                Modal.init({
                    elements: modalGalleryContainer,
                    options: {
                        container: this.options.modalContainer,
                        events: {
                            [ModalEvents.opening]: () => {
                                this.trigger(Events.opening);
                            },
                            [ModalEvents.opened]: () => {
                                if (this.sliderInstance) {
                                    this.sliderInstance.update();
                                }
                                this.trigger(Events.opened);
                            },
                            [ModalEvents.closing]: () => {
                                this.trigger(Events.closing);
                            },
                            [ModalEvents.closed]: () => {
                                if (this.sliderInstance) {
                                    this.sliderInstance.slideTo(0);
                                }
                            },
                        },
                    },
                }),
            );

            if (!modalInstance) {
                throw new Error('Modal not executed.');
            }

            this.modalInstance = modalInstance;
            await this.initSlider();
        }

        const { url } = this.options.lazyLoad as LazyLoadObject;
        let response: LoadingResponse<string>;

        if (!url) {
            return;
        }

        try {
            response = await htmlInstance.get(url);
        } catch ({ message }) {
            console.log(message);
            this.loading = false;
            this.loaded = false;

            return;
        }

        this.loading = false;
        this.loaded = true;

        const { data } = response;
        this.el.innerHTML = data;

        const modalGalleryContainer = querySelector<HTMLElement>(
            '.js-modal-gallery',
            this.el,
        );
        if (!modalGalleryContainer) {
            return;
        }

        const modalInstance: Modal | undefined = _first(
            Modal.init({
                elements: modalGalleryContainer,
                options: {
                    container: this.options.modalContainer,
                    events: {
                        [ModalEvents.opened]: () => {
                            if (this.sliderInstance) {
                                this.sliderInstance.update();
                            }
                        },
                        [ModalEvents.closed]: () => {
                            if (this.sliderInstance) {
                                this.sliderInstance.slideTo(0);
                            }
                        },
                    },
                },
            }),
        );

        if (!modalInstance) {
            throw new Error('Modal not executed.');
        }

        this.modalInstance = modalInstance;

        await this.initSlider();

        this.trigger(Events.endLoading);
    }

    private async initSlider(index = 0): Promise<void> {
        const nextButton = querySelector<HTMLElement>(
            '.js-modal-gallery-control-next',
            this.el,
        );
        const prevButton = querySelector<HTMLElement>(
            '.js-modal-gallery-control-prev',
            this.el,
        );
        const fraction = querySelector<HTMLElement>(
            '.js-modal-gallery-fraction',
            this.el,
        );
        const sliderContainer = querySelector<HTMLElement>(
            '.js-modal-gallery-slider',
            this.el,
        );

        return new Promise((resolve) => {
            if (!sliderContainer) {
                return;
            }

            this.sliderInstance = new Swiper(sliderContainer, {
                initialSlide: index,
                slidesPerView: 'auto',
                spaceBetween: 12,
                loop: false,
                centeredSlides: false,
                modules: [Navigation, Pagination],
                height: 184,
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                    disabledClass: 'modal-gallery__control--disabled',
                },
                pagination: {
                    el: fraction,
                    type: 'fraction',
                    renderFraction(currentClass: string, totalClass: string): string {
                        return `
                            <span class="${currentClass} modal-gallery__counter-current"></span>
                            <span class="modal-gallery__counter-split">/</span>
                            <span class="${totalClass} modal-gallery__counter-total"></span>
                        `;
                    },
                },
                breakpoints: {
                    [MOBILE_MIN_WIDTH]: {
                        spaceBetween: 12,
                        height: 184,
                    },
                    [TABLET_MIN_WIDTH]: {
                        spaceBetween: 16,
                        height: 392,
                    },
                    [DESKTOP_MIN_WIDTH]: {
                        spaceBetween: 24,
                        height: 672,
                    },
                },
                on: {
                    afterInit: () => {
                        setTimeout(() => {
                            resolve();
                        }, 100);
                        this.trigger(Events.initialized);
                    },
                },
            });
        });
    }
}

export default ModalGallery;
