import _defaultsDeep from 'lodash/defaultsDeep';
import _debounce from 'lodash/debounce';
import _isArray from 'lodash/isArray';
import _isString from 'lodash/isString';
import AbstractFormField, {
    AllowedTypes,
    AllowedTypesValues,
    defaultOptions as abstractFormDefaultOptions,
    Events as AbstractFormFieldEvents,
    Options as AbstractFormFieldOptions,
} from './AbstractFormField';
import Flag, { Events as FlagEvents } from './Flag';
import { ElementsType } from '../../Component';
import { querySelectorAll } from '../../../utils/dom';

export const Events = {
    ...AbstractFormFieldEvents,
} as const;

export type Options = AbstractFormFieldOptions & {
    itemJsClass: string;
    itemInputJsClass: string;
    errorClass: string;
    errorItemClass: string;
    disabledClass: string;
    checkedClass: string;
    disabledItemClass: string;
    checkedItemClass: string;
    initialItemValue: string | string[];
    events: AbstractFormFieldOptions['events'];
};

export const defaultOptions: Options = _defaultsDeep(
    {
        itemJsClass: 'js-field-flag',
        itemInputJsClass: 'js-field-flag-input',
        errorClass: 'field-group-flag--error',
        errorItemClass: 'field-flag--error',
        disabledClass: 'field-group-flag--disabled',
        checkedClass: 'field-group-flag--checked',
        disabledItemClass: 'field-flag--disabled',
        checkedItemClass: 'field-flag--checked',
        initialItemValue: [],
        events: {},
    },
    abstractFormDefaultOptions,
);

class FlagGroup extends AbstractFormField<Options> {
    protected fields: Set<Flag>;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const flagHandlerOnChange = _debounce(() => {
            this.fields.forEach((field) => {
                field.syncCheckedState();
            });
            this.trigger(Events.change);
        }, 30, { trailing: true });

        const flagHandlerOnChangeDisabled = _debounce(() => {
            this.trigger(Events.changeDisabled);
        }, 30, { trailing: true });

        const flagHandlerOnReset = _debounce(() => {
            this.fields.forEach((field) => {
                field.syncCheckedState();
            });
            this.trigger(Events.reset);
        }, 30, { trailing: true });

        const fields = Flag.init({
            elements: querySelectorAll<HTMLElement>(
                `.${this.options.itemJsClass}`,
                this.el,
            ),
            options: {
                inputJsClass: this.options.itemInputJsClass,
                errorClass: this.options.errorItemClass,
                disabledClass: this.options.disabledItemClass,
                checkedClass: this.options.checkedItemClass,
                events: {
                    [FlagEvents.change]: flagHandlerOnChange,
                    [FlagEvents.changeDisabled]: flagHandlerOnChangeDisabled,
                    [FlagEvents.reset]: flagHandlerOnReset,
                },
            },
        }) as Flag[];

        this.fields = new Set(fields);

        this.trigger(Events.initialized);
    }

    public getName(): string {
        const fields = this.fields.values();
        const firstFieldIterator = fields.next();
        const firstField = firstFieldIterator.value;
        return firstField.getName();
    }

    public getType(): AllowedTypesValues {
        return AllowedTypes.flagGroup;
    }

    public getValue(): [string, string][] {
        const values: [string, string][] = [];
        this.fields.forEach((field) => {
            values.push([field.getName(), field.getValue()]);
        });
        return values;
    }

    public setValue(): this {
        return this;
    }

    public setChecked(values: string[]): this {
        this.fields.forEach((field) => {
            field.setChecked(values.includes(field.getValue()));
        });
        this.trigger(Events.change);
        return this;
    }

    public getChecked(): [string, string][] {
        const values: [string, string][] = [];
        this.fields.forEach((field) => {
            if (field.isChecked()) {
                values.push([field.getName(), field.getValue()]);
            }
        });
        return values;
    }

    public disabled(): this {
        this.el.classList.add(this.options.disabledClass);
        this.fields.forEach((field) => {
            field.disabled();
        });
        this.trigger(Events.changeDisabled);
        return this;
    }

    public notDisabled(): this {
        this.fields.forEach((field) => {
            field.notDisabled();
        });
        this.el.classList.remove(this.options.disabledClass);
        this.trigger(Events.changeDisabled);
        return this;
    }

    public isDisabled(): boolean {
        return Array.from(this.fields).some((field) => field.isDisabled());
    }

    public resetToInitial(initialValue?: Options['initialItemValue']): this {
        let initialValueNormalized: string[];

        if (_isString(initialValue)) {
            initialValueNormalized = [initialValue];
        } else if (_isArray(initialValue)) {
            initialValueNormalized = initialValue.map((item) => String(item));
        } else {
            const { fieldFlagGroupInitialValue: initialValueProp = '' } = this.el.dataset;

            try {
                initialValueNormalized = JSON.parse(initialValueProp);

                if (_isArray(initialValueNormalized)) {
                    initialValueNormalized = initialValueNormalized.map((item) => String(item));
                } else {
                    initialValueNormalized = [String(initialValueNormalized)];
                }
            } catch ({ message }) {
                console.log(message);
                initialValueNormalized = _isString(initialValueProp) ? [initialValueProp] : [];
            }
        }

        this.fields.forEach((field) => {
            field.setChecked(initialValueNormalized.includes(field.getValue()), true);
        });

        this.trigger(Events.reset);

        return this;
    }
}

export default FlagGroup;
