import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';

export const Events = {
    initialized: 'initialized',
} as const;

export type Options = {
    events: {
        [Events.initialized]?: () => void;
    };
};

export const defaultOptions: Options = {
    events: {},
};

class GlobalEventBus extends Component<Options> {
    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.trigger(Events.initialized);
    }
}

export default GlobalEventBus;
