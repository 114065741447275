import _first from 'lodash/first';
import _debounce from 'lodash/debounce';
import _defaultsDeep from 'lodash/defaultsDeep';
import _isFunction from 'lodash/isFunction';
import _isString from 'lodash/isString';
import { ElementsType } from '../../Component';
import AbstractFormField, {
    AllowedTypes,
    AllowedTypesValues,
    defaultOptions as abstractFormDefaultOptions,
    Events as AbstractFormFieldEvents,
    Options as AbstractFormFieldOptions,
} from './AbstractFormField';
import Search, {
    Events as SearchEvents,
} from './Search';
import ToggleClass from '../../ToggleClass';
import { querySelector, addEventListener, querySelectorAll } from '../../../utils/dom';
import { htmlInstance, LoadingResponse } from '../../../utils/ajax';
import { isEscape } from '../../../utils/keyboard';
import { HIDDEN_CLASS } from '../../../constants';

export const Events = {
    ...AbstractFormFieldEvents,
    input: 'input',
    focus: 'focus',
    blur: 'blur',
    submit: 'submit',
} as const;

export type Options = AbstractFormFieldOptions & {
    initialValue: string;
    fieldSearchJsClass: string;
    clearBtnJsClass: string;
    inputJsClass: string;
    submitBtnJsClass: string;
    hintsJsClass: string;
    showAllLinkJsClass: string;
    hintsListJsClass: string;
    hintsItemsJsClass: string;
    hintsFoundContentJsClass: string;
    hintsNotFoundContentJsClass: string;
    focusClass: string;
    hintsLoadingClass: string;
    hintsToggleClass: string;
    disabledClass: string;
    hasValueClass: string;
    showAllLinkBaseUrl: string;
    startFindLength: number;
    emitOnSubmit: boolean;
    searchUrl: string;
    propertyName: string;
    events: AbstractFormFieldOptions['events'] & {
        [Events.input]?: () => void;
        [Events.submit]?: () => void;
    };
};

export const defaultOptions: Options = _defaultsDeep(
    {
        fieldSearchJsClass: 'js-field-smart-search-search',
        inputJsClass: 'js-field-smart-search-input',
        clearBtnJsClass: 'js-field-smart-search-clear-btn',
        submitBtnJsClass: 'js-field-smart-search-submit-btn',
        hintsJsClass: 'js-field-smart-search-hints',
        showAllLinkJsClass: 'js-field-smart-search-hints-show-all-link',
        hintsListJsClass: 'js-field-smart-search-hints-list',
        hintsFoundContentJsClass: 'js-field-smart-search-hints-found',
        hintsNotFoundContentJsClass: 'js-field-smart-search-hints-not-found',
        hintsItemsJsClass: 'js-field-smart-search-hints-list-item',
        hintsToggleClass: 'dropdown--opened',
        errorClass: 'field-search--error',
        hasValueClass: 'field-search--has-value',
        disabledClass: 'field-search--disabled',
        focusClass: 'field-search--focused',
        hintsLoadingClass: 'field-smart-search--loading-hints',
        showAllLinkBaseUrl: '/search/',
        initialValue: '',
        startFindLength: 3,
        emitOnSubmit: false,
        searchUrl: '/',
        propertyName: 'query',
        smart: false,
    },
    abstractFormDefaultOptions,
);

class SmartSearch extends AbstractFormField<Options> {
    protected fieldSearch!: Search;

    protected hintsToggleClass!: ToggleClass;

    protected closeTimer: unknown | null;

    protected hintsLoadingTimer: unknown | null;

    protected needClose: boolean;

    protected requestController: AbortController;

    protected loadingHints: boolean;

    protected showAllLink?: HTMLLinkElement;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.needClose = false;
        this.loadingHints = false;
        this.closeTimer = null;
        this.requestController = new AbortController();

        const showAllLink = querySelector<HTMLLinkElement>(
            `.${this.options.showAllLinkJsClass}`,
            this.el,
        );

        if (showAllLink) {
            this.showAllLink = showAllLink;
        }

        const fieldSearch: Search | undefined = _first(
            Search.init({
                elements: querySelector<HTMLElement>(
                    `.${this.options.fieldSearchJsClass}`,
                    this.el,
                ),
                options: {
                    inputJsClass: this.options.inputJsClass,
                    clearBtnJsClass: this.options.clearBtnJsClass,
                    submitBtnJsClass: this.options.submitBtnJsClass,
                    errorClass: this.options.errorClass,
                    hasValueClass: this.options.hasValueClass,
                    disabledClass: this.options.disabledClass,
                    focusClass: this.options.focusClass,
                    events: {
                        [SearchEvents.input]: this.handlerSearchOnInput,
                        [SearchEvents.reset]: this.handlerSearchOnReset,
                        [SearchEvents.submit]: this.handlerSearchOnSubmit,
                    },
                },
            }),
        );

        if (!fieldSearch) {
            throw new Error('Search field not found.');
        }

        this.fieldSearch = fieldSearch;

        const hintsToggleClass: ToggleClass | undefined = _first(
            ToggleClass.init({
                elements: this.el,
                options: {
                    toggleClass: this.options.hintsToggleClass,
                    target: this.el,
                },
            }),
        );

        if (!hintsToggleClass) {
            throw new Error('Block with hints not found.');
        }

        this.hintsToggleClass = hintsToggleClass;
        this.rebindHintsEvents();
        this.init();
    }

    public getName(): string {
        return this.fieldSearch.getName();
    }

    public getType(): AllowedTypesValues {
        return AllowedTypes.smartSearch;
    }

    public getValue(): string {
        return this.fieldSearch.getValue();
    }

    public setValue(value: string): this {
        this.fieldSearch.setValue(value);
        this.trigger(Events.change);
        return this;
    }

    public isDisabled(): boolean {
        return this.fieldSearch.isDisabled();
    }

    public disabled(): this {
        this.fieldSearch.disabled();
        this.trigger(Events.changeDisabled);
        return this;
    }

    public notDisabled(): this {
        this.fieldSearch.notDisabled();
        this.trigger(Events.changeDisabled);
        return this;
    }

    public focusToInput(): this {
        this.fieldSearch.focusToInput();
        return this;
    }

    public resetToInitial(initialValue?: Options['initialValue']): this {
        if (_isString(initialValue)) {
            this.setValue(initialValue as string);
        } else {
            this.setValue(this.options.initialValue);
        }

        this.trigger(Events.reset);

        return this;
    }

    protected init(): void {
        addEventListener(this.el, 'click', this.handlerOnClick, true);
        addEventListener(document, 'keyup', this.handlerDocumentOnKeyup, true);
        addEventListener(document, 'click', this.handlerDocumentOnClick, true);

        this.trigger(Events.initialized);
    }

    protected syncShowAllLinkState() {
        if (!this.showAllLink) {
            return;
        }

        const url = new URL(this.options.showAllLinkBaseUrl);
        const queryGetParams = new URLSearchParams(url.search);
        queryGetParams.set(
            this.getName(),
            String(this.getValue()),
        );
        this.showAllLink.href = `${url.pathname}?${queryGetParams}`;
    }

    protected handlerSearchOnInput = (): void => {
        const currentValue = this.fieldSearch.getValue();

        if (
            (currentValue.length >= this.options.startFindLength)
            && currentValue.length > 0
        ) {
            this.loadHints(() => {
                this.openHintsAfterLoad();
                this.syncShowAllLinkState();
            });
        } else {
            this.requestController.abort();
            this.hintsToggleClass.remove();
        }
    };

    protected handlerSearchOnReset = (): void => {
        const currentValue = this.fieldSearch.getValue();

        if (
            (currentValue.length < this.options.startFindLength)
            || currentValue.length <= 0
        ) {
            this.requestController.abort();
            this.hintsToggleClass.remove();
        } else {
            this.loadHints(() => {
                this.openHintsAfterLoad();
                this.syncShowAllLinkState();
            });
        }
    };

    protected handlerSearchOnSubmit = (): void => {
        this.requestController.abort();
        this.hintsToggleClass.remove();

        if (this.options.emitOnSubmit) {
            this.trigger(Events.submit);
        }
    };

    protected handlerDocumentOnKeyup = (event: Event): void => {
        if (this.hintsToggleClass.contains()) {
            if (isEscape(event as KeyboardEvent)) {
                this.hintsToggleClass.remove();
            }
        }
    };

    protected handlerDocumentOnClick = (event: Event): void => {
        const target = <HTMLElement>event.target;

        if (target !== this.el && !this.el.contains(target)) {
            this.hintsToggleClass.remove();
        }

        this.startClosingProcess();
    };

    protected handlerOnClick = (): void => {
        this.stopClosingProcess();

        const currentValue = this.fieldSearch.getValue();

        if (
            (currentValue.length >= this.options.startFindLength)
            && currentValue.length > 0
            && !this.hintsToggleClass.contains()
        ) {
            this.loadHints(() => {
                this.hintsToggleClass.add();
                this.syncShowAllLinkState();
            });
        }
    };

    protected openHintsAfterLoad() {
        const currentValue = this.fieldSearch.getValue();

        if (
            (currentValue.length >= this.options.startFindLength)
            && currentValue.length > 0
        ) {
            this.hintsToggleClass.add();
        }
    }

    protected startClosingProcess(): void {
        if (this.closeTimer !== null) {
            clearTimeout(this.closeTimer as number);
            this.closeTimer = null;
        }

        this.needClose = true;

        this.closeTimer = <unknown>setTimeout(() => {
            this.requestController.abort();
            this.needClose = false;
            this.closeTimer = null;
            this.hintsToggleClass.remove();
        }, 50);
    }

    protected stopClosingProcess(): void {
        this.needClose = false;

        if (this.closeTimer !== null) {
            clearTimeout(this.closeTimer as number);
            this.closeTimer = null;
        }
    }

    protected rebindHintsEvents() {
        const hints = querySelectorAll<HTMLButtonElement>(
            `.${this.options.hintsItemsJsClass}`,
            this.el,
        );

        addEventListener(hints, 'click', (event: Event) => {
            event.preventDefault();

            const currentHint = event.currentTarget as HTMLButtonElement;
            const text = currentHint.dataset.smartSearchValue || '';

            this.setValue(text);
            this.loadHints(() => {
                this.syncShowAllLinkState();
            });
        });
    }

    protected loadHints = _debounce(async (onSuccess?: () => void): Promise<void> => {
        clearTimeout(this.hintsLoadingTimer as number);
        this.hintsLoadingTimer = null;
        this.loadingHints = true;
        this.hintsLoadingTimer = setTimeout(() => {
            this.hintsLoadingTimer = null;

            if (this.loadingHints) {
                return;
            }

            this.el.classList.remove(this.options.hintsLoadingClass);
        }, 200);

        this.el.classList.add(this.options.hintsLoadingClass);

        let response: LoadingResponse<string>;
        const {
            searchUrl,
            hintsListJsClass,
            hintsFoundContentJsClass,
            hintsNotFoundContentJsClass,
            propertyName,
        } = this.options;

        this.requestController.abort();
        this.requestController = new AbortController();

        try {
            response = await htmlInstance.get(searchUrl, {
                signal: this.requestController.signal,
                params: {
                    [propertyName]: this.getValue(),
                },
            });
        } catch ({ message }) {
            console.log(message);
            return;
        }

        const html = response.data;
        const hints = querySelector(
            `.${hintsListJsClass}`,
            this.el,
        );
        const hintsFoundContent = querySelector<HTMLElement>(
            `.${hintsFoundContentJsClass}`,
            this.el,
        );
        const hintsNotFoundContent = querySelector<HTMLElement>(
            `.${hintsNotFoundContentJsClass}`,
            this.el,
        );

        if (
            hints
            && hintsFoundContent
            && hintsNotFoundContent
        ) {
            hints.innerHTML = html;

            if (hints.children.length) {
                hintsFoundContent.classList.remove(HIDDEN_CLASS);
                hintsNotFoundContent.classList.add(HIDDEN_CLASS);
            } else {
                hintsFoundContent.classList.add(HIDDEN_CLASS);
                hintsNotFoundContent.classList.remove(HIDDEN_CLASS);
            }

            this.rebindHintsEvents();

            if (this.hintsLoadingTimer === null || !this.hintsToggleClass.contains()) {
                this.el.classList.remove(this.options.hintsLoadingClass);
            }

            this.loadingHints = false;

            if (_isFunction(onSuccess)) {
                (onSuccess as (() => void))();
            }
        }
    }, 300, { maxWait: 3000, trailing: true });
}

export default SmartSearch;
