import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from '../Component';
import BaseForm, {
    Events as BaseFormEvents,
    Options as BaseFormOptions,
} from '../forms/instances/BaseForm';
import Tabs from '../Tabs';
import { querySelectorAll } from '../../utils/dom';

export const Events = {
    ...BaseFormEvents,
} as const;

export type Options = BaseFormOptions & {
    events: BaseFormOptions['events'] & {
        [Events.initialized]?: () => void | string;
    };
};

export const defaultOptions: Options = {
    validation: {
        live: true,
    },
    events: {},
};

class BaseContentFilter<T extends Options = Options> extends BaseForm<Options> {
    protected tabsFields: Component[];

    constructor(el: ElementsType, options: T = defaultOptions as T) {
        const normalizedOptions: T = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.loadFields();

        this.tabsFields = Tabs.init({
            elements: querySelectorAll<HTMLElement>('.js-form-tabs'),
        });

        this.trigger(Events.initialized);
    }

    public handlerOnSubmit(event: SubmitEvent): void {
        event.preventDefault();
        this.trigger(Events.submit);
    }

    public handlerOnReset(event: Event): void {
        event.preventDefault();
        this.resetToInitial();

        this.tabsFields.forEach((tabsField) => {
            (tabsField as Tabs).goto(0);
        });

        if (this.sendOnReset) {
            this.trigger(Events.submit);
        }
    }
}

export default BaseContentFilter;
