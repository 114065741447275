import _defaultsDeep from 'lodash/defaultsDeep';
import _isString from 'lodash/isString';
import _isFunction from 'lodash/isFunction';
import _isBoolean from 'lodash/isBoolean';
import _isPlainObject from 'lodash/isPlainObject';
import _throttle from 'lodash/throttle';
import Component, { ElementsType } from '../../Component';
import {
    querySelectorAll,
    addEventListener,
} from '../../../utils/dom';
import {
    jsonInstance,
    htmlInstance,
    LoadingResponse as BaseLoadingResponse,
    LoadingError as BaseLoadingError,
} from '../../../utils/ajax';
import Text from '../fields/Text';
import Search from '../fields/Search';
import Textarea from '../fields/Textarea';
import Datepicker from '../fields/Datepicker';
import SmartSearch, {
    Events as SmartSearchEvents,
} from '../fields/SmartSearch';
import FlagGroup from '../fields/FlagGroup';
import LimitFlagGroup from '../fields/LimitFlagGroup';
import Select from '../fields/Select';
import { schemas, validatorsMap } from '../../../forms-validation-config';

export const Events = {
    initialized: 'initialized',
    change: 'change',
    submit: 'submit',
    validate: 'validate',
    reset: 'reset',
    send: 'send',
    loadFields: 'loadFields',
    endSend: 'endSend',
} as const;

export const AllowedResponseTypes = {
    json: 'json',
    html: 'html',
} as const;

export const AllowedMethods = {
    get: 'get',
    post: 'post',
} as const;

export type LoadingResponse<T> = BaseLoadingResponse<T>;
export type LoadingError = BaseLoadingError;

type AllowedMethodsKeys = keyof typeof AllowedMethods;
type AllowedMethodsValues = typeof AllowedMethods[AllowedMethodsKeys];

type AllowedResponseTypesKeys = keyof typeof AllowedResponseTypes;
type AllowedResponseTypesValues = typeof AllowedResponseTypes[AllowedResponseTypesKeys];

type Fields = Textarea | Text | SmartSearch | Search | FlagGroup | Datepicker | Select;

export type Options = {
    action?: string;
    method?: AllowedMethodsValues;
    responseType?: AllowedResponseTypesValues;
    sendViaAjax?: boolean;
    sendLockTime?: number;
    validation: {
        live: boolean;
    };
    events: {
        [Events.initialized]?: () => void;
        [Events.change]?: () => void;
        [Events.submit]?: () => void;
        [Events.validate]?: () => void;
        [Events.reset]?: () => void;
        [Events.send]?: () => void;
        [Events.loadFields]?: () => void;
    };
};

export const defaultOptions: Options = {
    validation: {
        live: false,
    },
    sendLockTime: 300,
    responseType: AllowedResponseTypes.json,
    events: {},
};

class BaseForm<
    T extends Options = Options,
    L extends LoadingResponse<any> = LoadingResponse<any>,
> extends Component<T> {
    protected statuses: {
        sending: boolean;
        sent: boolean;
    };

    protected requestController: AbortController;

    protected fields: Map<string, Fields>;

    protected sendViaAjaxCallback: (() => void) | null;

    protected resetOnSuccess: boolean;

    protected sendOnReset: boolean;

    constructor(el: ElementsType, options: T = defaultOptions as T) {
        const normalizedOptions: T = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.statuses = {
            sending: false,
            sent: false,
        };
        this.fields = new Map<string, Fields>();
        this.requestController = new AbortController();
        this.sendViaAjaxCallback = null;

        this.resetOnSuccess = this.el.dataset.formResetOnSuccess === 'true';
        this.sendOnReset = this.el.dataset.formSendOnResets === 'true';

        this.el.setAttribute('novalidate', 'novalidate');

        addEventListener(this.el, 'submit', (event: Event) => {
            this.handlerOnSubmit(event as SubmitEvent);
        });

        const resetButtons = querySelectorAll<HTMLElement>('.js-form-reset-btn', this.el);

        addEventListener(resetButtons, 'click', (event: Event) => {
            this.handlerOnReset(event as SubmitEvent);
        });
    }

    public getMethod(): Options['method'] {
        const values: string[] = [
            AllowedMethods.get,
            AllowedMethods.post,
        ];

        const { method } = this.options;
        if (
            _isString(method)
            && values.includes((method as string).toLowerCase())
        ) {
            return (method as string)
                .toLowerCase() as AllowedMethodsValues;
        }

        const methodFromForm = (this.el as HTMLFormElement).method
            .toLowerCase();
        if (values.includes(methodFromForm)) {
            return methodFromForm as AllowedMethodsValues;
        }

        throw new Error('Unsupported http method.');
    }

    public getResponseType(): Options['responseType'] {
        const values: string[] = [
            AllowedResponseTypes.json,
            AllowedResponseTypes.html,
        ];

        const { responseType } = this.options;
        if (
            _isString(responseType)
            && values.includes((responseType as string).toLowerCase())
        ) {
            return (responseType as string)
                .toLowerCase() as AllowedResponseTypesValues;
        }

        const responseTypeFromForm = (this.el.dataset.formResponseType || '')
            .toLowerCase();
        if (values.includes(responseTypeFromForm)) {
            return responseTypeFromForm as AllowedResponseTypesValues;
        }

        return AllowedResponseTypes.json;
    }

    public getAction(): Required<Options['action']> {
        const { action } = this.options;
        if (_isString(action)) {
            return action as string;
        }

        return (this.el as HTMLFormElement).action;
    }

    public getSendViaAjax(): Required<Options['sendViaAjax']> {
        const { sendViaAjax } = this.options;
        if (_isBoolean(sendViaAjax)) {
            return !!sendViaAjax;
        }

        const sendViaAjaFromForm = (this.el.dataset.formSendViaAjax || 'true')
            .toLowerCase();
        if (['true', 'false'].includes(sendViaAjaFromForm)) {
            return sendViaAjaFromForm === 'true';
        }

        return true;
    }

    public handlerOnReset(event: Event): void {
        event.preventDefault();
        this.resetToInitial();

        if (this.sendOnReset) {
            this.send();
        }
    }

    public handlerOnSubmit(event: SubmitEvent): void {
        if (!this.validate()) {
            event.preventDefault();
            return;
        }

        if (this.getSendViaAjax()) {
            event.preventDefault();
        }

        this.send();
    }

    public handlerOnSuccessSend(response: L): void {
        if (this.resetOnSuccess) {
            this.resetToInitial();
        }

        this.trigger(Events.endSend, { ...response });
    }

    public handlerOnErrorSend(err: LoadingError): void {
        this.trigger(Events.endSend, { error: err });
    }

    public send(): void {
        if (this.getSendViaAjax()) {
            this.sendViaAjax();
        } else {
            this.sendViaReload();
        }
    }

    public async sendViaAjax(): Promise<void> {
        if (this.sendViaAjaxCallback === null) {
            this.sendViaAjaxCallback = _throttle(async () => {
                let instance;
                let error;
                let response: L | undefined;

                this.requestController.abort();
                this.requestController = new AbortController();

                this.statuses.sending = true;
                this.statuses.sent = false;

                switch (this.getResponseType()) {
                    case AllowedResponseTypes.html: {
                        instance = htmlInstance;
                        break;
                    }

                    case AllowedResponseTypes.json:
                    default: {
                        instance = jsonInstance;
                    }
                }

                try {
                    switch (this.getMethod()) {
                        case AllowedMethods.post: {
                            response = await instance.post(
                                this.getAction() as string,
                                this.el,
                                {
                                    signal: this.requestController.signal,
                                },
                            );
                            break;
                        }

                        case AllowedMethods.get: {
                            const formData = new FormData(this.el as HTMLFormElement);
                            const queryGetParams = new URLSearchParams(formData as any);
                            response = await instance.get(
                                this.getAction() as string,
                                {
                                    params: queryGetParams,
                                    signal: this.requestController.signal,
                                },
                            );
                            break;
                        }

                        default: {
                            throw new Error('Unsupported form method.');
                        }
                    }
                } catch (err) {
                    console.log(err.message);
                    error = err;
                }

                this.statuses.sending = false;
                this.statuses.sent = true;

                if (error) {
                    this.handlerOnErrorSend(error);
                } else if (response) {
                    this.handlerOnSuccessSend(response);
                }
            }, this.options.sendLockTime, { trailing: true });
        }

        if (this.sendViaAjaxCallback) {
            await this.sendViaAjaxCallback();
        }
    }

    public sendViaReload(): void {
        (this.el as HTMLFormElement).submit();
    }

    public resetToInitial(): this {
        this.fields.forEach((field) => {
            const { resetToInitial } = field as { resetToInitial: (...args: any[]) => any };

            if (_isFunction(resetToInitial)) {
                resetToInitial.call(field);
            }
        });

        this.trigger(Events.reset);

        return this;
    }

    public validate(): boolean {
        const schema = this.getValidationSchema();
        let hasError = false;

        Object.keys(schema).forEach((fieldId) => {
            const result = this.validateField(fieldId);

            if (!hasError && !result) {
                hasError = true;
            }
        });

        this.trigger(Events.validate, { result: !hasError });
        return !hasError;
    }

    public getFields(): BaseForm['fields'] {
        return this.fields;
    }

    public getValidationSchema(): any {
        const defaultSchemaName = 'main';
        const validationSchemaName = this.el.dataset.formValidationSchema
            || defaultSchemaName;
        return schemas.has(validationSchemaName)
            ? schemas.get(validationSchemaName)
            : schemas.get(defaultSchemaName);
    }

    public validateField(fieldId: string): boolean {
        const schema = this.getValidationSchema();
        const field = this.fields.get(fieldId);
        if (!field) {
            return true;
        }

        const rules = schema[fieldId] || {};
        const fieldType = field.getType();
        let error: string | boolean = false;

        Object.keys(rules).some((rule) => {
            const validatorRule = rules[rule];

            if (
                !validatorRule
                || (_isBoolean(validatorRule) && !validatorRule)
                || (_isPlainObject(validatorRule) && !validatorRule.enable)
            ) {
                return false;
            }

            const ValidatorClass = validatorsMap.get(rule);
            if (!ValidatorClass) {
                return false;
            }

            let validatorError: string | true = true;
            const validator = new ValidatorClass();

            if (validatorRule.message) {
                validator.setMessage(validatorRule.message);
            }

            switch (fieldType) {
                case 'switch': {
                    break;
                }

                case 'search':
                case 'smartSearch':
                case 'text':
                case 'textarea': {
                    const value = field.getValue();
                    validatorError = validator.validate(value);
                    break;
                }

                case 'select': {
                    const values = field.getValue();
                    validatorError = validator.validate(values);
                    break;
                }

                case 'flagGroup': {
                    const values = (field as FlagGroup).getChecked();
                    validatorError = validator.validate(values);
                    break;
                }

                case 'checkbox':
                case 'radio': {
                    break;
                }

                case 'date': {
                    const values = field.getValue();
                    validatorError = validator.validate(values);
                    break;
                }

                default: {
                    throw new Error('Unknown field type.');
                }
            }

            if (_isString(validatorError)) {
                error = validatorError;
                return true;
            }

            return false;
        });

        if (_isString(error)) {
            field.setError(error as string);
            return false;
        }

        field.setError();
        return true;
    }

    public getValues(useNameAttribute = false): Map<string, string | string[]> {
        const result = new Map();
        this.fields.forEach((field, id) => {
            if (!useNameAttribute) {
                result.set(id, field.getValue());
            }
        });
        return result;
    }

    public getFormData(): FormData {
        return new FormData(this.el as HTMLFormElement);
    }

    protected loadFields(): void {
        const fields: Fields[] = [
            ...(Datepicker.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-field-datepicker',
                    this.el,
                ),
                optionsModifier: (el, options) => ({
                    options: { ...options, form: this.el },
                    element: el,
                }),
            }) as Datepicker[]),
            ...(Text.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-field-text',
                    this.el,
                ),
                optionsModifier: (el, options) => ({
                    options: { ...options, form: this.el },
                    element: el,
                }),
            }) as Text[]),
            ...(Textarea.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-field-textarea',
                    this.el,
                ),
                optionsModifier: (el, options) => ({
                    options: { ...options, form: this.el },
                    element: el,
                }),
            }) as Textarea[]),
            ...(Search.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-field-search',
                    this.el,
                ),
                optionsModifier: (el, options) => ({
                    options: { ...options, form: this.el },
                    element: el,
                }),
            }) as Search[]),
            ...(FlagGroup.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-field-flag-group',
                    this.el,
                ),
                optionsModifier: (el, options) => ({
                    options: { ...options, form: this.el },
                    element: el,
                }),
            }) as FlagGroup[]),
            ...(LimitFlagGroup.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-field-flag-with-limit',
                    this.el,
                ),
                optionsModifier: (el, options) => ({
                    options: { ...options, form: this.el },
                    element: el,
                }),
            }) as LimitFlagGroup[]),
            ...(SmartSearch.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-field-smart-search',
                    this.el,
                ),
                options: {
                    searchUrl: '/api/smart-search/list/',
                    events: {
                        [SmartSearchEvents.submit]: () => {
                            (this.el as HTMLFormElement).submit();
                        },
                    },
                },
                optionsModifier: (el, options) => {
                    const {
                        fieldSmartSearchUrl: searchUrl = '/api/smart-search/list/',
                        fieldSmartSearchPropertyName: propertyName = 'query',
                        fieldSmartSearchEmitOnSubmit: emitOnSubmit = 'false',
                    } = el.dataset;
                    return {
                        options: {
                            ...options,
                            searchUrl,
                            showAllLinkBaseUrl: this.getAction(),
                            propertyName,
                            emitOnSubmit: emitOnSubmit === 'true',
                            form: this.el,
                        },
                        element: el,
                    };
                },
            }) as SmartSearch[]),
            ...(Select.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-field-select-simple',
                    this.el,
                ),
                optionsModifier: (el, options) => ({
                    options: { ...options, form: this.el },
                    element: el,
                }),
            }) as Select[]),
            ...(Select.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-field-select-search',
                    this.el,
                ),
                options: {
                    searchEnabled: true,
                    selectParams: {
                        searchEnabled: true,
                    },
                },
                optionsModifier: (el, options) => ({
                    options: { ...options, form: this.el },
                    element: el,
                }),
            }) as Select[]),
            ...(Select.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-field-select-multiple',
                    this.el,
                ),
                options: {
                    searchEnabled: true,
                    multipleSelect: true,
                    selectParams: {
                        searchEnabled: true,
                    },
                },
                optionsModifier: (el, options) => ({
                    options: { ...options, form: this.el },
                    element: el,
                }),
            }) as Select[]),
        ];

        this.fields = new Map();

        fields.forEach((field) => {
            this.fields.set(field.getId(), field);

            field.on('change', () => {
                this.validateField(field.getId());
            });

            field.on('input', () => {
                this.validateField(field.getId());
            });

            field.on('reset', () => {
                this.validateField(field.getId());
            });
        });

        this.trigger(Events.loadFields);
    }
}

export default BaseForm;
