import _defaultsDeep from 'lodash/defaultsDeep';
import _isString from 'lodash/isString';
import _isBoolean from 'lodash/isBoolean';
import AbstractFormField, {
    Options as AbstractFormFieldOptions,
    AllowedTypes,
    AllowedTypesValues,
    Events as AbstractFormFieldEvents,
    defaultOptions as abstractFormDefaultOptions,
} from './AbstractFormField';
import { ElementsType } from '../../Component';
import {
    querySelector,
    addEventListener,
    addEventListenerLoadedPage,
} from '../../../utils/dom';

export const Events = {
    ...AbstractFormFieldEvents,
} as const;

export type Options = AbstractFormFieldOptions & {
    inputJsClass: string;
    disabledClass: string;
    checkedClass: string;
    initialValue: boolean;
    events: AbstractFormFieldOptions['events'];
};

export const defaultOptions: Options = _defaultsDeep(
    {
        inputJsClass: 'js-field-flag-input',
        errorClass: 'field-flag--error',
        disabledClass: 'field-flag--disabled',
        checkedClass: 'field-flag--checked',
        initialValue: false,
        events: {},
    },
    abstractFormDefaultOptions,
);

class Flag extends AbstractFormField<Options> {
    protected input: HTMLInputElement;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const input = querySelector<HTMLInputElement>(
            `.${this.options.inputJsClass}`,
            this.el,
        );
        if (!input) {
            throw new Error('Block "input" not found.');
        }
        this.input = input;

        this.init();
    }

    public getName(): string {
        return this.input.name;
    }

    public getType(): AllowedTypesValues {
        const type = this.el.dataset.fieldFlagInputType || AllowedTypes.checkbox;
        return type as AllowedTypesValues;
    }

    public getValue(): string {
        return this.input.value;
    }

    public setValue(value: string): this {
        if (_isString(value)) {
            this.input.value = `${value}`;
        } else {
            throw new Error('Unsupported type of value.');
        }

        return this;
    }

    public setChecked(val: boolean, noEvents = false): this {
        this.input.checked = !!val;
        this.syncCheckedState();
        if (!noEvents) {
            this.trigger(Events.change);
        }
        return this;
    }

    public isChecked(): boolean {
        return this.input.checked;
    }

    public disabled(): this {
        this.el.classList.add(this.options.disabledClass);
        this.input.disabled = true;
        this.trigger(Events.changeDisabled);
        return this;
    }

    public notDisabled(): this {
        this.input.disabled = false;
        this.el.classList.remove(this.options.disabledClass);
        this.trigger(Events.changeDisabled);
        return this;
    }

    public isDisabled(): boolean {
        return this.input.disabled;
    }

    public resetToInitial(initialValue?: Options['initialValue']): this {
        if (_isBoolean(initialValue)) {
            this.setChecked(initialValue as boolean);
        } else {
            this.setChecked(this.options.initialValue);
        }

        this.trigger(Events.reset);

        return this;
    }

    public syncCheckedState() {
        if (this.isDisabled()) {
            return;
        }

        const checkedClass = (this.el.dataset.formFieldCheckedClass
            || this.options.checkedClass).split(' ').filter((item) => !!item);

        if (this.input.checked) {
            this.el.classList.add(...checkedClass);
        } else {
            this.el.classList.remove(...checkedClass);
        }
    }

    protected init(): void {
        addEventListener(this.input, 'input', this.handlerOnChange);

        if (this.isDisabled()) {
            this.disabled();
        } else {
            this.notDisabled();
        }

        addEventListenerLoadedPage(() => {
            setTimeout(() => {
                this.syncCheckedState();
            }, 300);
        });

        this.trigger(Events.initialized);
    }

    protected handlerOnChange = () => {
        if (this.isDisabled()) {
            return;
        }

        this.syncCheckedState();
        this.trigger(Events.change);
    };
}

export default Flag;
