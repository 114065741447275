import _throttle from 'lodash/throttle';
import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';
import { querySelectorAll, addEventListener, querySelector } from '../utils/dom';
import { isDesktop } from '../utils/screen';

export type Options = {
    defaultList: string;
    parentContainerClass: string;
    parentContainerModalClass: string;
    resetBtnClass: string;
    controlClass: string;
    levelClass: string;
    currentListClass: string;
};

export const defaultOptions: Options = {
    defaultList: 'root',
    parentContainerClass: 'js-modal-menu-section',
    parentContainerModalClass: 'modal-menu--section-desktop',
    resetBtnClass: 'js-modal-close-btn',
    levelClass: 'js-desktop-modal-menu-section-level',
    controlClass: 'js-desktop-modal-menu-section-nav-go-to-list-btn',
    currentListClass: 'desktop-modal-menu-section__level--current',
};

class DesktopMultilevelMenu extends Component<Options> {
    protected reseted: boolean;

    protected levels: HTMLElement[] | NodeListOf<HTMLElement>;

    protected parentContainer: HTMLElement | null;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.reseted = false;

        this.levels = querySelectorAll<HTMLElement>(`.${this.options.levelClass}`, el);

        this.parentContainer = querySelector<HTMLElement>(`.${this.options.parentContainerClass}`);

        if (this.parentContainer) {
            this.parentContainer.classList.add(this.options.parentContainerModalClass);
        }

        const controlButtons = querySelectorAll<HTMLElement>(`.${this.options.controlClass}`, el);

        addEventListener(controlButtons, 'click', (event: Event): void => {
            event.preventDefault();

            const targetEl = event.currentTarget as HTMLElement;
            const listTargetId = targetEl.dataset.desktopModalMenuSectionListIdTarget || '';

            this.goto(listTargetId);
        });

        const resetButtons = querySelectorAll<HTMLElement>(`.${this.options.resetBtnClass}`, this.parentContainer || el);

        addEventListener(resetButtons, 'click', (event: Event): void => {
            event.preventDefault();

            this.reset();
        });

        addEventListener(window, 'resize', _throttle(() => {
            if (!isDesktop() && !this.reseted) {
                if (!this.reseted) {
                    this.reset();
                    this.reseted = true;
                }

                if (this.parentContainer) {
                    this.parentContainer.classList.remove(this.options.parentContainerModalClass);
                }
            } else if (isDesktop() && this.reseted) {
                this.reseted = false;

                if (this.parentContainer) {
                    this.parentContainer.classList.add(this.options.parentContainerModalClass);
                }
            }
        }, 300));
    }

    public goto(id: string): void {
        const list = querySelector<HTMLElement>(
            `.${this.options.levelClass}[data-desktop-modal-menu-section-list-id="${id}"]`,
            this.el,
        );

        if (!list) {
            return;
        }

        const listId = list.dataset.desktopModalMenuSectionListId;

        this.levels.forEach((level) => {
            const levelListId = level.dataset.desktopModalMenuSectionListId;

            if (levelListId !== 'root' && levelListId !== listId) {
                level.classList.remove(this.options.currentListClass);
            } else {
                level.classList.add(this.options.currentListClass);
            }
        });
    }

    public reset(): void {
        this.goto(this.options.defaultList);
    }
}

export default DesktopMultilevelMenu;
