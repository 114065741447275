import 'mediaelement';
import 'mediaelement/build/lang/ru';

import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';

export type Options = {
    videoParams: object;
};

export const defaultOptions: Options = {
    videoParams: {
        iconSprite: '/assets/images/icons/mejs-controls.svg',
        stretching: 'fill',
        alwaysShowControls: true,
    },
};

class Video extends Component<Options> {
    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.init();
    }

    protected init(): void {
        const { mejs, MediaElement, MediaElementPlayer } = window;

        if (mejs) {
            mejs.i18n.language('ru');
        }

        const videoType = this.el.getAttribute('data-video-type');

        if (videoType === 'youtube') {
            new MediaElement(this.el, {
                ...this.options.videoParams,
                youtube: {
                    nocookie: false,
                    imageQuality: 'maxresdefault',
                },
            });
        } else {
            new MediaElementPlayer(this.el, this.options.videoParams);
        }
    }
}

export default Video;
