import { ElementsType } from '../../Component';
import FlagGroup, { Options as FlagGroupOptions } from './FlagGroup';

// группа чекбоксов/радио-кнопок с ограничением числа выбранных элементов

type LimitFlagGroupOptions = FlagGroupOptions & {
    limit: number;
};

class LimitFlagGroup extends FlagGroup {
    private limit = Infinity;

    static limitDataAttrName = 'data-field-flag-group-limit';

    constructor(el: ElementsType, options: LimitFlagGroupOptions) {
        super(el, options);
        this.limit = options.limit || Number(this.el.getAttribute(LimitFlagGroup.limitDataAttrName));
        this.el.addEventListener('change', this.onChange);
    }

    public destroy(): void {
        this.el.removeEventListener('change', this.onChange);
    }

    onChange = (event: Event) => {
        const { target } = event;

        if (!(target instanceof HTMLInputElement)) {
            return;
        }

        const checkedCount = this.getChecked().length;
        this.setDisabling(checkedCount === this.limit);
    };

    setDisabling(flag: boolean) {
        this.fields.forEach((field) => {
            if (!field.isChecked()) {
                if (flag) {
                    field.disabled();
                } else {
                    field.notDisabled();
                }
            }
        });
    }
}

export default LimitFlagGroup;
