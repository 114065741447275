import type { ElementsType } from 'components/Component';
import type { PollFormBlockOptions } from './PollFormBlock';
import PollFormBlock from './PollFormBlock';

class OrganizationsBlock extends PollFormBlock {
    constructor(element: ElementsType, options: PollFormBlockOptions = {} as PollFormBlockOptions) {
        super(element, options);
    }

    public setRegion(regionId: string) {
        this.el.querySelectorAll<HTMLFieldSetElement>('fieldset[data-region]')
            .forEach((element) => {
                element.disabled = regionId !== element.dataset.region;
            });
    }
}

export default OrganizationsBlock;
