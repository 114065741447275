export const baseUrl = () => window.location.origin;

export const setLocalStorageItem = (key: string, value: string) => {
    if (!window.localStorage) {
        return;
    }

    window.localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key: string): string | null => {
    if (!window.localStorage) {
        return null;
    }

    return window.localStorage.getItem(key);
};

export const removeLocalStorageItem = (key: string) => {
    if (!window.localStorage) {
        return;
    }

    window.localStorage.removeItem(key);
};
