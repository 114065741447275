import _defaultsDeep from 'lodash/defaultsDeep';
import _first from 'lodash/first';
import Component, { ElementsType } from './Component';
import ToggleClass, {
    Options as ToggleClassOptions,
    Events as ToggleClassEvents,
} from './ToggleClass';
import { addEventListener, querySelector } from '../utils/dom';
import { isEscape } from '../utils/keyboard';

export const Events = {
    ...ToggleClassEvents,
} as const;

export type Options = Omit<ToggleClassOptions, 'target' | 'auto'>;

export const defaultOptions: Options = {
    toggleClass: 'dropdown--opened',
    events: {},
};

class Dropdown extends Component<Options> {
    protected instance: ToggleClass;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep({}, options, defaultOptions);

        super(el, normalizedOptions);

        const instance: ToggleClass | undefined = _first(
            ToggleClass.init({
                elements: querySelector<HTMLElement>(
                    '.js-dropdown-toggle-btn',
                    this.el,
                ),
                optionsModifier: (currentEl) => ({
                    options: {
                        toggleClass: this.options.toggleClass,
                        target: this.el,
                        auto: true,
                    },
                    element: currentEl,
                }),
            }),
        );

        if (!instance) {
            throw new Error('Toggle button not found.');
        }

        this.instance = instance;

        addEventListener(document, 'click', (event: Event): void => {
            const target = <HTMLElement>event.target;

            if (target !== this.el && !this.el.contains(target)) {
                this.instance.remove();
            }
        }, true);

        addEventListener(document, 'keyup', (event: Event): void => {
            if (isEscape(event as KeyboardEvent)) {
                this.instance.remove();
            }
        });
    }
}

export default Dropdown;
