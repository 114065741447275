import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';
import { querySelectorAll, addEventListener, querySelector } from '../utils/dom';
import { isEscape } from '../utils/keyboard';
import { MODAL_CONTAINER, OVERFLOW_HIDDEN_CLASS } from '../constants';

export const Events = {
    initialized: 'initialized',
    opening: 'opening',
    opened: 'opened',
    closing: 'closing',
    closed: 'closed',
} as const;

export type Options = {
    toggleClass: string;
    toggleBodyClass: string | string[];
    container: string;
    selectors: {
        openButton: string;
        closeButton: string;
    };
    events: {
        [Events.initialized]?: () => void;
        [Events.opening]?: () => void;
        [Events.opened]?: () => void;
        [Events.closing]?: () => void;
        [Events.closed]?: () => void;
    };
};

export const defaultOptions: Options = {
    toggleBodyClass: OVERFLOW_HIDDEN_CLASS,
    toggleClass: 'modal--opened',
    container: MODAL_CONTAINER,
    events: {},
    selectors: {
        openButton: '.js-modal-open-btn',
        closeButton: '.js-modal-close-btn',
    },
};

class Modal extends Component<Options> {
    private modalIsOpened: boolean;

    private parentContainer: HTMLElement;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.parentContainer = this.el.parentNode as HTMLElement;
        this.modalIsOpened = false;

        this.initGlobalHandles();
        this.initGlobalOpenButtons();
        this.initClosedButtons();

        this.trigger(Events.initialized);
    }

    public open(): void {
        const container = querySelector<HTMLElement>(this.options.container);
        if (!container || this.modalIsOpened) {
            return;
        }
        container.append(this.el);
        this.modalIsOpened = true;
        setTimeout(() => {
            window.App.GlobalEventBus
                .trigger(`modal-${Events.opening}`, undefined, this);
            this.trigger(Events.opening);
            this.el.classList.add(this.options.toggleClass);

            setTimeout(() => {
                if (Array.isArray(this.options.toggleBodyClass)) {
                    document.body.classList.add(...this.options.toggleBodyClass);
                } else {
                    document.body.classList.add(this.options.toggleBodyClass);
                }
                this.trigger(Events.opened);
            }, 200);
        }, 10);
    }

    public close(): void {
        const container = querySelector<HTMLElement>(this.options.container);
        if (!container || !this.modalIsOpened) {
            return;
        }
        this.parentContainer.append(this.el);
        setTimeout(() => {
            this.modalIsOpened = false;
            window.App.GlobalEventBus
                .trigger(`modal-${Events.closing}`, undefined, this);
            this.trigger(Events.closing);
            this.el.classList.remove(this.options.toggleClass);
            if (Array.isArray(this.options.toggleBodyClass)) {
                document.body.classList.remove(...this.options.toggleBodyClass);
            } else {
                document.body.classList.remove(this.options.toggleBodyClass);
            }

            setTimeout(() => {
                this.trigger(Events.closed);
            }, 200);
        }, 10);
    }

    public isOpened(): boolean {
        return this.modalIsOpened;
    }

    protected initGlobalHandles() {
        window.App.GlobalEventBus.on(`modal-${Events.opening}`, (component) => {
            if (component.el === this.el) {
                return;
            }

            this.close();
        });
    }

    protected initGlobalOpenButtons() {
        const globalOpenButtons = querySelectorAll(
            this.options.selectors.openButton,
        );

        addEventListener(globalOpenButtons, 'click', (event: Event): void => {
            event.preventDefault();
            const button = <HTMLElement>event.currentTarget;
            const targetSelector = button.dataset.modalTarget as string;
            const target = querySelector<HTMLElement>(targetSelector);

            if (!target) {
                return;
            }

            if (target === this.el) {
                this.open();
            }
        });
    }

    protected initClosedButtons() {
        const closeButtons = querySelectorAll(
            this.options.selectors.closeButton,
            this.el,
        );

        addEventListener(closeButtons, 'click', (event: Event): void => {
            event.preventDefault();

            this.close();
        });

        addEventListener(document, 'keyup', (event: Event): void => {
            if (isEscape(event as KeyboardEvent)) {
                this.close();
            }
        });
    }
}

export default Modal;
