import _defaultsDeep from 'lodash/defaultsDeep';
import _first from 'lodash/first';
import _throttle from 'lodash/throttle';
import Component, { ElementsType } from './Component';
import Modal from './Modal';
import SimpleForm, { Events as SimpleFormEvents } from './forms/instances/SimpleForm';
import { addEventListener, querySelector, querySelectorAll } from '../utils/dom';
import { isEnter } from '../utils/keyboard';
import { isDesktop } from '../utils/screen';
import { HIDDEN_CLASS } from '../constants';

export type Options = {
    errorNotifyFormClass: string;
    errorNotifyUrlFieldClass: string;
    errorNotifyTextFieldClass: string;
    errorNotifyContainerClass: string;
    errorNotifyTooltipClass: string;
    errorNotifyTooltipText: string;
};

export const defaultOptions: Options = {
    errorNotifyFormClass: '.js-modal-error-notify-form',
    errorNotifyUrlFieldClass: '.js-modal-error-notify-url-field .js-field-text-input',
    errorNotifyTextFieldClass: '.js-modal-error-notify-text-field .js-field-textarea-input',
    errorNotifyContainerClass: '.js-modal-error-notify-text',
    errorNotifyTooltipClass: 'modal-error-notify__tooltip',
    errorNotifyTooltipText: 'Опечатка',
};

class ModalErrorNotify extends Component<Options> {
    protected modalInstance!: Modal;

    protected errorNotifyContainer: HTMLElement | null;

    protected errorNotifyUrlField: HTMLInputElement | null;

    protected errorNotifyTextField: HTMLTextAreaElement | null;

    protected errorNotifyTooltip: HTMLElement;

    protected errorNotifySelection: Selection | null;

    protected errorNotifySelectionText: string;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const tooltip = document.createElement('div');
        tooltip.classList.add(this.options.errorNotifyTooltipClass);
        tooltip.innerText = this.options.errorNotifyTooltipText;
        tooltip.classList.add(HIDDEN_CLASS);
        document.body.appendChild(tooltip);

        this.errorNotifyTooltip = tooltip;
        this.errorNotifySelection = null;
        this.errorNotifySelectionText = '';
        this.errorNotifyContainer = querySelector<HTMLElement>(this.options.errorNotifyContainerClass, this.el);
        this.errorNotifyUrlField = querySelector<HTMLInputElement>(this.options.errorNotifyUrlFieldClass, this.el);
        this.errorNotifyTextField = querySelector<HTMLTextAreaElement>(this.options.errorNotifyTextFieldClass, this.el);

        this.init();
    }

    protected init(): void {
        _first(
            SimpleForm.init({
                elements: querySelectorAll<HTMLElement>(this.options.errorNotifyFormClass, this.el),
                options: {
                    events: {
                        [SimpleFormEvents.reset]: () => {
                            if (this.errorNotifyContainer) {
                                this.errorNotifyContainer.innerText = '';
                            }

                            if (this.modalInstance) {
                                this.modalInstance.close();
                            }
                        },
                    },
                },
            }),
        );

        addEventListener(document, 'selectionchange', this.handlerOnSelectionChangeDocument);
        addEventListener(document, 'keydown', this.handlerOnKeyDownDocument);

        addEventListener(document, 'touchend', this.handlerOnTouchEndDocument);
        addEventListener(this.errorNotifyTooltip, 'touchend', () => this.openErrorNotifyForm());

        addEventListener(window, 'resize', _throttle(() => {
            this.setTooltip();
        }, 300));
    }

    protected handlerOnSelectionChangeDocument = (): void => {
        const selection = window.getSelection();

        if (selection && selection.isCollapsed) {
            this.errorNotifySelectionText = '';
        }

        if (selection && !selection.isCollapsed) {
            this.errorNotifySelection = selection;
            this.errorNotifySelectionText = selection.toString();
        }

        setTimeout(() => {
            this.errorNotifyTooltip.classList.add(HIDDEN_CLASS);
        }, 200);
    };

    protected handlerOnTouchEndDocument = (): void => {
        if (isDesktop() || !this.errorNotifySelection || this.errorNotifySelection.isCollapsed) {
            return;
        }

        this.errorNotifyTooltip.classList.remove(HIDDEN_CLASS);
        this.setTooltip();
    };

    protected handlerOnKeyDownDocument = (event: Event): void => {
        if (!isEnter(event as KeyboardEvent) || !(event as KeyboardEvent).ctrlKey) {
            return;
        }

        this.openErrorNotifyForm();
    };

    protected setTooltip(): void {
        if (isDesktop() || !this.errorNotifySelection || this.errorNotifySelection.isCollapsed) {
            this.errorNotifyTooltip.classList.add(HIDDEN_CLASS);
            return;
        }

        const selectionRange = this.errorNotifySelection.getRangeAt(0);
        const selectionRangeRect = selectionRange.getBoundingClientRect();

        const errorNotifyTooltipMargin = 8;

        this.errorNotifyTooltip.style.top = `${selectionRangeRect.bottom + window.scrollY + errorNotifyTooltipMargin}px`;
        this.errorNotifyTooltip.style.left = `${selectionRangeRect.left}px`;
    }

    protected openErrorNotifyForm(): void {
        if (!this.errorNotifySelectionText) {
            return;
        }

        const selectedText = '«... ' + this.errorNotifySelectionText + ' ...»';

        if (this.errorNotifyContainer) {
            this.errorNotifyContainer.innerText = selectedText;
        }

        if (this.errorNotifyUrlField) {
            this.errorNotifyUrlField.value = window.location.href;
        }

        if (this.errorNotifyTextField) {
            this.errorNotifyTextField.value = selectedText;
        }

        this.loadModal();
    }

    protected loadModal(): void {
        const modalInstance: Modal | undefined = _first(
            Modal.init({
                elements: this.el,
            }),
        );

        if (!modalInstance) {
            throw new Error('Modal does not exist.');
        }

        this.modalInstance = modalInstance;

        if (window.getSelection) {
            const selection = window.getSelection();
            if (selection) {
                selection.removeAllRanges();
            }
        // @ts-ignore
        } else if (document.selection) {
            // @ts-ignore
            document.selection.empty();
        }

        modalInstance.open();
    }
}

export default ModalErrorNotify;
