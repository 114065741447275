import Component, { ElementsType, Options } from './Component';
import {
    addEventListener,
} from '../utils/dom';

class CopyManager extends Component {
    protected copyContainer: HTMLElement;

    constructor(el: ElementsType, options: Options = {} as Options) {
        super(el, options);

        const div = document.createElement('div');
        div.id = 'copyDiv';
        div.style.position = 'absolute';
        div.style.left = '-99999px';
        div.style.top = '-99999px';
        document.body.appendChild(div);

        this.copyContainer = div;

        this.init();
    }

    protected init(): void {
        addEventListener(document, 'copy', () => this.handlerOnCopy(), false);
    }

    protected handlerOnCopy() {
        if (typeof window.getSelection() === 'undefined') {
            return;
        }

        if (window.location.pathname.indexOf('/news') === -1) {
            return;
        }

        const selection = document.getSelection();
        if (!selection || selection.isCollapsed) {
            return;
        }

        const contents = selection.getRangeAt(0).cloneContents();
        this.copyContainer.innerHTML = '';
        this.copyContainer.appendChild(contents);

        if ((this.copyContainer.textContent || '').trim().length === 0) {
            return;
        }

        if ((selection + '').trim().length > 0) {
            const pagelink = '<br /><br /> - Все подробности: ' + document.location.href;
            this.copyContainer.innerHTML += pagelink;
        }

        const firstRange = selection.getRangeAt(0);
        const range = document.createRange();
        range.selectNodeContents(this.copyContainer);

        selection.removeAllRanges();
        selection.addRange(range);

        setTimeout(() => {
            selection.removeAllRanges();
            selection.addRange(firstRange);
        }, 100);
    }
}

export default CopyManager;
