import _last from 'lodash/last';
import _isArray from 'lodash/isArray';
import _isString from 'lodash/isString';
import _first from 'lodash/first';
import _defaultsDeep from 'lodash/defaultsDeep';
import Component from './Component';
import MyLocalStorage from '../utils/app-local-storage';
import { querySelectorAll, addEventListener } from '../utils/dom';

const STORE_KEY = 'App.special-version.';

type ControlsGroup = {
    controls: HTMLElement[];
};

export const Events = {
    change: 'change',
    enable: 'enable',
    disable: 'disable',
} as const;

export type Options = {
    events: {
        [Events.change]?: () => void;
        [Events.enable]?: () => void;
        [Events.disable]?: () => void;
    };
};

export const defaultOptions: Options = {
    events: {},
};

class SpecialVersion extends Component {
    protected openButtons: HTMLElement[];

    protected closeButtons: HTMLElement[];

    protected body: HTMLElement;

    protected html: HTMLElement;

    protected controls: Map<string, ControlsGroup>;

    protected currentState: Map<string, string> = new Map();

    protected storage: MyLocalStorage;

    protected enabled = '0';

    protected enableClass: string;

    constructor(el: HTMLElement, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.storage = new MyLocalStorage(STORE_KEY);
        this.body = document.body;
        this.html = document.documentElement;
        this.openButtons = querySelectorAll<HTMLElement>(
            '.js-spec-v-open-btn',
        ) as HTMLElement[];
        this.closeButtons = querySelectorAll<HTMLElement>(
            '.js-spec-v-close-btn',
        ) as HTMLElement[];

        const { specVSettingsEnableClass = 'spec-v' } = this.el.dataset;

        this.enableClass = specVSettingsEnableClass;

        const controls = querySelectorAll<HTMLElement>(
            '.js-spec-v-settings-control-btn',
            this.el,
        ) as HTMLElement[];

        this.controls = new Map();
        controls.forEach((control) => {
            const {
                specVOption: optionName = '',
                specVValue: optionValue = '',
            } = control.dataset;

            if (optionName === '' || optionValue === '') {
                console.error(control);
                throw new Error(
                    'Attribute "data-spec-v-option" or "data-spec-v-value" is empty.',
                );
            }

            if (!this.controls.has(optionName)) {
                this.controls.set(optionName, { controls: [] });
            }

            const data = this.controls.get(optionName) as ControlsGroup;
            const { controls: itemControls } = data;
            if (itemControls.indexOf(control) === -1) {
                itemControls.push(control);
            }

            this.controls.set(optionName, { ...data, controls: itemControls });
        });

        this.initState();
        this.initHandlers();
    }

    initHandlers(): void {
        addEventListener(this.el, 'click', (event: Event) => {
            event.preventDefault();
        });

        addEventListener(this.openButtons, 'click', (event: Event) => {
            event.preventDefault();
            this.enable();
        });

        addEventListener(this.closeButtons, 'click', (event: Event) => {
            event.preventDefault();
            this.disable();
        });

        this.controls.forEach((item, key) => {
            addEventListener(item.controls, 'click', (event: Event) => {
                this.handlerOnClickControl(event, key);
            });
        });

        addEventListener(window, 'storage', (event: Event): void => {
            const { key: eventKey, newValue: eventNewValue } = event as StorageEvent;
            const keyParts = (eventKey || '').split('.');
            const allowKeys = Array.from(this.controls.keys());
            const fieldKey = <string>_last(keyParts);
            const newValue = <string>eventNewValue;

            if (['enabled'].includes(fieldKey)) {
                if (newValue === '1') {
                    this.html.classList.add(this.enableClass);
                    this.enabled = '1';
                    this.controls.forEach((_, key) => {
                        const currentValue = this.currentState.get(key);
                        if (currentValue) {
                            this.addHtmlPageState(key, currentValue);
                        }
                    });
                } else if (newValue === '0') {
                    this.html.classList.remove(this.enableClass);
                    this.enabled = '0';

                    this.controls.forEach((_, key) => {
                        const currentValue = <string>(this.currentState.get(key));
                        this.removeHtmlPageState(key, currentValue);
                    });
                }
                return;
            }

            if (!allowKeys.includes(fieldKey)) {
                return;
            }

            if (this.controls.has(fieldKey)) {
                const currentGroup = <ControlsGroup>(this.controls.get(fieldKey));
                const { controls } = currentGroup;
                const activeControl = controls.find(
                    (control) => (control.dataset.specVValue || '') === newValue,
                );

                if (activeControl) {
                    const activeControlActiveClass = this.getActiveClassByControl(activeControl);

                    controls.forEach((control) => {
                        const controlActiveClass = this.getActiveClassByControl(control);
                        control.classList.remove(controlActiveClass);
                    });

                    activeControl.classList.add(activeControlActiveClass);
                }
            }

            if (!['enabled'].includes(fieldKey)) {
                const oldValue = <string>(this.currentState.get(fieldKey));
                this.currentState.set(fieldKey, newValue);
                this.removeHtmlPageState(fieldKey, oldValue);
                this.addHtmlPageState(fieldKey, newValue);
            }
        });
    }

    initState() {
        const controlsAllValue = new Map();

        this.controls.forEach((item, name) => {
            const itemAllValues = new Set();
            const { controls } = item;

            if (!controls.length) {
                return;
            }

            controls.forEach((control) => {
                itemAllValues.add(control.dataset.specVValue || '');
            });

            const defaultValue = (_first(controls) as HTMLElement).dataset.specVValue || '';

            if (!this.storage.has(name)) {
                this.storage.set(name, defaultValue);
            } else {
                const storageValue = this.storage.get(name);
                if (!itemAllValues.has(storageValue)) {
                    this.storage.set(name, defaultValue);
                }
            }

            const storageValue = <string>(this.storage.get(name, defaultValue));
            this.currentState.set(name, storageValue);

            const activeControl = controls.find(
                (control) => (control.dataset.specVValue || '') === storageValue,
            );

            if (activeControl) {
                controls.forEach((control) => {
                    const controlActiveClass = this.getActiveClassByControl(control);
                    control.classList.remove(controlActiveClass);
                });

                const activeControlActiveClass = this.getActiveClassByControl(activeControl);
                activeControl.classList.add(activeControlActiveClass);
            }

            controlsAllValue.set(name, itemAllValues);
        });

        if (this.storage.get('enabled', '0') === '1') {
            this.enable();
        }
    }

    enable(): void {
        this.html.classList.add(this.enableClass);
        this.storage.set('enabled', '1');
        this.enabled = '1';

        this.controls.forEach((_, key) => {
            const currentValue = this.currentState.get(key);
            if (currentValue) {
                this.addHtmlPageState(key, currentValue);
            }
        });

        this.trigger(Events.enable);
    }

    isEnabled(): boolean {
        return this.enabled === '1';
    }

    disable(): void {
        this.html.classList.remove(this.enableClass);
        this.storage.set('enabled', '0');
        this.enabled = '0';

        this.controls.forEach((_, key) => {
            const currentValue = <string>(this.currentState.get(key));
            this.removeHtmlPageState(key, currentValue);
        });

        this.trigger(Events.disable);
    }

    handlerOnClickControl(event: Event, fieldKey: string): void {
        event.preventDefault();

        if (!this.isEnabled()) {
            return;
        }

        const control = event.currentTarget as HTMLElement;
        const newValue = control.dataset.specVValue || '';
        const currentValue = <string>(this.currentState.get(fieldKey));

        this.storage.set(fieldKey, newValue);
        this.currentState.set(fieldKey, newValue);

        if (this.controls.has(fieldKey)) {
            const currentGroup = <ControlsGroup>(this.controls.get(fieldKey));
            currentGroup.controls.forEach((groupControl) => {
                const groupControlActiveClass = this.getActiveClassByControl(groupControl);
                groupControl.classList.remove(groupControlActiveClass);
            });

            const controlActiveClass = this.getActiveClassByControl(control);
            control.classList.add(controlActiveClass);
        }

        this.removeHtmlPageState(fieldKey, currentValue);
        this.addHtmlPageState(fieldKey, newValue);
        this.updateOtherVersions(fieldKey, newValue);
        this.trigger(Events.change);
    }

    updateOtherVersions(fieldKey: string, value: string) {
        if (_isArray(window.App.SpecialVersion)) {
            (window.App.SpecialVersion as SpecialVersion[]).forEach((instance: SpecialVersion) => {
                if (instance !== this && instance.controls.has(fieldKey)) {
                    instance.currentState.set(fieldKey, value);

                    const { controls } = <ControlsGroup>(instance.controls.get(fieldKey));
                    const activeControl = controls.find(
                        (control) => (control.dataset.specVValue || '') === value,
                    );

                    if (activeControl) {
                        controls.forEach((control) => {
                            const controlActiveClass = this.getActiveClassByControl(control);
                            control.classList.remove(controlActiveClass);
                        });

                        const activeControlActiveClass = this.getActiveClassByControl(activeControl);
                        activeControl.classList.add(activeControlActiveClass);
                    }
                }
            });
        }
    }

    reset(): void {
        this.controls.forEach((item, key) => {
            const { controls } = item;
            const currentValue = <string>(this.storage.get(key));
            const firstControl = _first(controls) as HTMLElement;
            const newValue = firstControl.dataset.specVValue || '';

            this.removeHtmlPageState(key, currentValue);

            this.storage.set(key, newValue);
            this.currentState.set(key, newValue);

            if (firstControl) {
                controls.forEach((control) => {
                    const controlActiveClass = this.getActiveClassByControl(control);
                    control.classList.remove(controlActiveClass);
                });
                const firstControlActiveClass = this.getActiveClassByControl(firstControl);
                firstControl.classList.add(firstControlActiveClass);
            }
        });
    }

    addHtmlPageState(key: string, value: string | false) {
        this.html.classList.add(`${this.enableClass}--${key}-${value}`);
    }

    removeHtmlPageState(key: string, value: string | false) {
        this.html.classList.remove(`${this.enableClass}--${key}-${value}`);
    }

    getActiveClassByControl(control: HTMLElement) {
        const {
            specVControlActiveClass: controlActiveClass = '',
        } = control.dataset;
        if (!_isString(controlActiveClass) || controlActiveClass === '') {
            console.error(control);
            throw new TypeError(
                'Attribute "data-spec-v-control-active-class" is not set.',
            );
        }

        return controlActiveClass;
    }
}

export default SpecialVersion;
