import '../../sass/app/app.scss';
import _isPlainObject from 'lodash/isPlainObject';
import _first from 'lodash/first';
import _throttle from 'lodash/throttle';
import { addEventListener, querySelector, querySelectorAll } from './utils/dom';
import { HIDDEN_CLASS, MODAL_CONTAINER, SPEC_V_BOX_SHOWN_CLASS } from './constants';
import { baseUrl } from './utils/helpers';
import { Options } from './components/Component';
import TableResponsive from './components/TableResponsive';
import EmbedSvgFile from './components/EmbedSvgFile';
import Modal, { Events as ModalEvents } from './components/Modal';
import HorizontalGallery, {
    Events as HorizontalGalleryEvents,
} from './components/HorizontalGallery';
import ModalGallery from './components/ModalGallery';
import Share from './components/Share';
import Tabs, { Events as TabsEvents } from './components/Tabs';
import Banners from './components/Banners';
import MainNews from './components/MainNews';
import MainBigNews from './components/MainBigNews';
import News from './components/News';
import Cookie from './components/Cookie';
import ToggleClass from './components/ToggleClass';
import SimpleClock from './components/SimpleClock';
import SimpleForm from './components/forms/instances/SimpleForm';
import SmartSearch from './components/forms/fields/SmartSearch';
import GlobalEventBus from './components/GlobalEventBus';
import Video from './components/Video';
import ShowMore from './components/ShowMore';
import Notification from './components/Notification';
import MobileMultilevelMenu from './components/MobileMultilevelMenu';
import DesktopMultilevelMenu from './components/DesktopMultilevelMenu';
import { Events as PaginationEvents } from './components/pagination/Pagination';
import LoadByScrollPagination from './components/pagination/LoadByScrollPagination';
import LoadByButtonPagination from './components/pagination/LoadByButtonPagination';
import LoadByNavbarPagination from './components/pagination/LoadByNavbarPagination';
import CardGallery, {
    Events as CardGalleryEvents,
} from './components/CardGallery';
import Dropdown from './components/Dropdown';
import ContentWithFilter from './components/filters/ContentWithFilter';
import SearchResultContentWithFilter from './components/filters/SearchResultContentWithFilter';
import CopyManager from './components/CopyManager';
import SpecialVersion, { Events as SpecialVersionEvents } from './components/SpecialVersion';
import ScrollUp from './components/ScrollUp';
import ModalErrorNotify from './components/ModalErrorNotify';
import KamchatkaMap from './components/maps/Kamchatka';
import PollForm from './components/polls/PollForm';

if (!_isPlainObject(window.App)) {
    window.App = {
        TableResponsive: [],
        EmbedSvgFile: [],
        Modal: [],
        HorizontalGallery: [],
        CardGallery: [],
        Tabs: [],
        TabsWithContent: [],
        Banners: [],
        MainNews: [],
        MainBigNews: [],
        News: [],
        Cookie: [],
        Share: [],
        ToggleClass: [],
        Dropdown: [],
        PrimaryClock: [],
        SimpleForm: [],
        ModalSearch: [],
        KamchatkaMap: [],
        GlobalEventBus: (function () {
            return <GlobalEventBus>_first(
                GlobalEventBus.init<Options, GlobalEventBus>({
                    elements: querySelector<HTMLBodyElement>('body'),
                }),
            );
        }()),
        Video: [],
        ShowMore: [],
        Notification: [],
        ModalMenuPrimary: [],
        ModalMenuSectionMobile: [],
        ModalMenuSectionDesktop: [],
        ModalMenu: [],
        PaginationOfDetailContent: [],
        SimplePaginationOfListByScroll: [],
        SimplePaginationOfListByNavbar: [],
        SimplePaginationByButton: [],
        SimpleContentWithFilter: [],
        SearchResultContentWithFilter: [],
        CopyManager: [],
        SpecialVersion: [],
        ScrollUp: [],
        ModalErrorNotify: [],
        PollForm: [],
    };
}

window.App.TableResponsive = TableResponsive.init({
    elements: querySelectorAll<HTMLTableElement>('table:not(.no-style)'),
});

window.App.EmbedSvgFile = EmbedSvgFile.init({
    elements: querySelectorAll<HTMLElement>('.js-embed-svg'),
});

window.App.Modal = Modal.init({
    elements: querySelectorAll<HTMLElement>('.js-plain-modal'),
});

window.App.Share = Share.init({
    elements: querySelectorAll<HTMLElement>('.js-share-btn'),
});

window.App.Cookie = Cookie.init({
    elements: querySelector<HTMLElement>('.js-cookie'),
});

window.App.Banners = Banners.init({
    elements: querySelectorAll<HTMLElement>('.js-banners'),
});

window.App.MainNews = MainNews.init({
    elements: querySelectorAll<HTMLElement>('.js-main-news'),
});

window.App.MainBigNews = MainBigNews.init({
    elements: querySelectorAll<HTMLElement>('.js-main-big-news'),
});

window.App.News = News.init({
    elements: querySelectorAll<HTMLElement>('.js-news'),
});

window.App.SimplePaginationByButton = LoadByButtonPagination.init({
    elements: querySelectorAll<HTMLElement>('.js-simple-pagination-by-button'),
});

(function () {
    let newsInstance: News;

    window.App.Tabs = Tabs.init({
        elements: querySelectorAll<HTMLElement>('.js-tabs'),
        options: {
            events: {
                [TabsEvents.clickItem]: (_, data) => {
                    const { id } = data;

                    const instance: News | undefined = _first(
                        News.init({
                            elements: querySelectorAll<HTMLElement>('.js-news'),
                        }),
                    );

                    if (!instance) {
                        return;
                    }

                    newsInstance = instance;
                    newsInstance.makeNewsBtnUrl(id);
                },
            },
        },
    });

    window.App.TabsWithContent = Tabs.init({
        elements: querySelectorAll<HTMLElement>('.js-tabs-with-content'),
        options: {
            events: {
                [TabsEvents.clickItem]: (component, data) => {
                    const { id } = data;
                    const tabsContentItems = querySelectorAll<HTMLElement>(
                        '.js-tabs-content-item',
                        component.el.closest('.js-tabs-content'),
                    );

                    if (!tabsContentItems.length) {
                        return;
                    }

                    tabsContentItems.forEach((item) => {
                        if (item.dataset.tabsContentId === id) {
                            item.classList.remove(HIDDEN_CLASS);
                        } else {
                            item.classList.add(HIDDEN_CLASS);
                        }
                    });
                },
            },
        },
    });
}());

function modalGallery(rootEl: HTMLElement | Document = document) {
    let modalGalleryInstance: ModalGallery;

    window.App.HorizontalGallery = HorizontalGallery.init({
        elements: querySelectorAll<HTMLElement>('.js-horizontal-gallery', rootEl),
        options: {
            events: {
                [HorizontalGalleryEvents.initialized]: (component) => {
                    const url = component.el.dataset.horizontalGalleryModalGalleryUrl;
                    const modalContainer = querySelector<HTMLElement>(MODAL_CONTAINER);
                    const instance: ModalGallery | undefined = _first(
                        ModalGallery.init({
                            elements: modalContainer,
                            options: {
                                lazyLoad: { url },
                            },
                        }),
                    );

                    if (!instance) {
                        return;
                    }

                    modalGalleryInstance = instance;
                    const zoomButton = querySelector<HTMLButtonElement>(
                        '.js-horizontal-gallery-zoom-btn',
                        component.el,
                    );
                    addEventListener(zoomButton, 'click', (event: Event) => {
                        event.preventDefault();

                        const currentSlide = component.getCurrentSlide();
                        const item = querySelector<HTMLElement>(
                            '.js-horizontal-gallery-slider-item',
                            currentSlide,
                        );
                        if (!item) {
                            return;
                        }

                        const id = parseInt(
                            item.dataset.horizontalGalleryModalGalleryItemId || '0',
                            10,
                        );
                        modalGalleryInstance.open(id);
                    });
                },
                [HorizontalGalleryEvents.clickItem]: (_, data) => {
                    const { el } = data;
                    const id = parseInt(
                        el.dataset.horizontalGalleryModalGalleryItemId || '0',
                        10,
                    );
                    if (modalGalleryInstance) {
                        modalGalleryInstance.open(id);
                    }
                },
            },
        },
    });
}
modalGallery();

function modalCardGallery(rootEl: HTMLElement | Document = document) {
    let modalGalleryInstance: ModalGallery;

    window.App.CardGallery = CardGallery.init({
        elements: querySelectorAll<HTMLElement>('.js-card-gallery', rootEl),
        options: {
            events: {
                [CardGalleryEvents.initialized]: (component) => {
                    const url = component.el.dataset.cardGalleryModalGalleryUrl;
                    const modalContainer = querySelector<HTMLElement>(MODAL_CONTAINER);
                    const instance: ModalGallery | undefined = _first(
                        ModalGallery.init({
                            elements: modalContainer,
                            options: {
                                lazyLoad: { url },
                            },
                        }),
                    );

                    if (!instance) {
                        return;
                    }

                    modalGalleryInstance = instance;
                },
                [CardGalleryEvents.clickItem]: (_, data) => {
                    const { el } = data;
                    const id = parseInt(
                        el.dataset.cardGalleryModalGalleryItemId || '0',
                        10,
                    );
                    if (modalGalleryInstance) {
                        modalGalleryInstance.open(id);
                    }
                },
            },
        },
    });
}

modalCardGallery();

window.App.ToggleClass = ToggleClass.init({
    elements: querySelectorAll<HTMLElement>('.js-toggle-class'),
    optionsModifier: (el) => {
        const {
            toggleTarget: targetSelector = 'body',
            toggleToggleClass: toggleClass,
        } = el.dataset;

        if (!toggleClass) {
            throw new Error('Empty data-toggle-toggle-class.');
        }

        const target = querySelector<HTMLElement>(targetSelector);

        return {
            options: { toggleClass, target, auto: true },
            element: el,
        };
    },
});

window.App.PrimaryClock = SimpleClock.init({
    elements: querySelector<HTMLElement>('.js-primary-clock'),
});

window.App.SimpleForm = SimpleForm.init({
    elements: querySelectorAll<HTMLElement>('.js-simple-form'),
});

(function () {
    const modalSearchForm: SimpleForm | undefined = _first(
        SimpleForm.init({
            elements: querySelectorAll<HTMLElement>('.js-modal-search-form'),
        }),
    );

    if (!modalSearchForm) {
        return;
    }

    const searchFieldName = 'query';

    window.App.ModalSearch = Modal.init({
        elements: querySelectorAll<HTMLElement>('.js-modal-search'),
        options: {
            selectors: {
                openButton: '.js-modal-search-open-btn',
            },
            events: {
                [ModalEvents.opened]: () => {
                    const fields = modalSearchForm.getFields();
                    if (!fields.has(searchFieldName)) {
                        return;
                    }

                    const searchField = fields.get(searchFieldName) as SmartSearch;
                    if (searchField) {
                        searchField.focusToInput();
                    }
                },
                [ModalEvents.closed]: () => {
                    const fields = modalSearchForm.getFields();
                    if (!fields.has(searchFieldName)) {
                        return;
                    }

                    const searchField = fields.get(searchFieldName) as SmartSearch;
                    if (searchField) {
                        searchField.resetToInitial();
                    }
                },
            },
        },
    });
}());

window.App.Video = Video.init({
    elements: querySelectorAll<HTMLElement>('.js-video'),
});

window.App.ShowMore = ShowMore.init({
    elements: querySelectorAll<HTMLElement>('.js-show-more'),
});

window.App.Notification = Notification.init({
    elements: querySelectorAll<HTMLElement>('.js-notification'),
});

window.App.ModalMenuPrimary = MobileMultilevelMenu.init({
    elements: querySelector<HTMLElement>('#modal-menu-primary'),
});

window.App.ModalMenuSectionMobile = MobileMultilevelMenu.init({
    elements: querySelector<HTMLElement>('#modal-menu-section .js-mobile-modal-menu-section'),
});

window.App.ModalMenuSectionDesktop = DesktopMultilevelMenu.init({
    elements: querySelector<HTMLElement>('#modal-menu-section .js-desktop-modal-menu-section'),
});

window.App.ModalMenu = Modal.init({
    elements: querySelectorAll<HTMLElement>('.js-modal-menu'),
    options: {
        events: {
            [ModalEvents.closing]: () => {
                document.body.classList.remove(SPEC_V_BOX_SHOWN_CLASS);
            },
        },
    },
});

function initComponentsForLoadedItem(item: HTMLElement) {
    ShowMore.init({
        elements: item,
        options: {
            itemClass: 'news-detail',
            itemHiddenClass: 'news-detail--collapsed',
            showMoreBtnClass: 'news-detail__btn',
            showLessBtnClass: '',
        },
    });

    Share.init({
        elements: querySelectorAll<HTMLElement>('.js-share-btn', item),
    });

    HorizontalGallery.init({
        elements: querySelectorAll<HTMLElement>('.js-horizontal-gallery', item),
    });

    Video.init({
        elements: querySelectorAll<HTMLElement>('.js-video', item),
    });

    ShowMore.init({
        elements: querySelectorAll<HTMLElement>('.js-show-more', item),
    });

    modalGallery(item);
}

(function () {
    function getItemUrl(item: HTMLElement): string {
        const { loadByScrollPaginationItemUrl: itemUrl = '' } = item.dataset;
        return itemUrl;
    }

    const initialUrl = new URL(window.location.href);
    let visibleInViewportElements: Map<number, HTMLElement> = new Map();
    let activeElementInViewport: HTMLElement | null = null;

    function updatePageUrl(url: string): void {
        const initialQueryGetParams = new URLSearchParams(initialUrl.search);
        const newUrl = new URL(url, baseUrl());
        const queryGetParamsFromUrl = new URLSearchParams(newUrl.search);

        initialQueryGetParams.forEach((value, name) => {
            if (!queryGetParamsFromUrl.has(name)) {
                queryGetParamsFromUrl.set(name, value);
            }
        });

        newUrl.hash = initialUrl.hash;
        newUrl.search = queryGetParamsFromUrl.toString();

        window.history.replaceState({}, '', newUrl.toString());
    }

    let observer: IntersectionObserver;

    const handlerWindowOnScrollAndResize = _throttle(() => {
        const windowHeight = window.innerHeight;
        const windowHeightBreakpointsCount = 3;
        const windowHeightTopPoint = Math.floor(
            windowHeight / windowHeightBreakpointsCount,
        );

        if (visibleInViewportElements.size === 0 && activeElementInViewport) {
            activeElementInViewport = null;
            updatePageUrl(initialUrl.toString());
        } else {
            Array.from(visibleInViewportElements).some(([, item]) => {
                const position = item.getBoundingClientRect();

                if (position.bottom >= windowHeightTopPoint) {
                    if (activeElementInViewport !== item) {
                        activeElementInViewport = item;
                        const url = getItemUrl(item);
                        updatePageUrl(url);
                    }

                    return true;
                }

                return false;
            });
        }
    }, 200, { trailing: true, leading: true });

    window.App.PaginationOfDetailContent = LoadByScrollPagination.init({
        elements: querySelectorAll<HTMLElement>('.js-pagination-detail-content'),
        options: {
            events: {
                [PaginationEvents.initialized]: (component) => {
                    const initialItemSelector = '.js-detail-content-initial';
                    const initialItem = querySelector<HTMLElement>(
                        initialItemSelector,
                        component.el,
                    );

                    if (!initialItem) {
                        return;
                    }

                    observer = new IntersectionObserver(
                        (entries) => {
                            entries.forEach((entry) => {
                                const { target } = entry;
                                const index = Array.prototype.slice.call(
                                    component.targetContainer.children,
                                ).indexOf(target);

                                if (entry.isIntersecting) {
                                    visibleInViewportElements.set(index, target as HTMLElement);
                                } else {
                                    visibleInViewportElements.delete(index);
                                }
                            });

                            visibleInViewportElements = new Map(
                                [...visibleInViewportElements.entries()].sort(),
                            );
                            handlerWindowOnScrollAndResize();
                        },
                        { threshold: [0, 1] },
                    );

                    observer.observe(initialItem);
                    addEventListener(window, 'resize', handlerWindowOnScrollAndResize);
                    addEventListener(window, 'scroll', handlerWindowOnScrollAndResize);
                    component.scrollLoad();
                },
                [PaginationEvents.afterLoad]: (component) => {
                    component.lock();

                    const loadedItemsClass = 'js-detail-content-loaded';
                    const loadedItemsLoaded = querySelectorAll<HTMLElement>(
                        `.${loadedItemsClass}`,
                        component.el,
                    );

                    loadedItemsLoaded.forEach((item) => {
                        initComponentsForLoadedItem(item);
                        item.classList.remove(loadedItemsClass);
                        observer.observe(item);
                    });

                    component.unlock();
                },
            },
        },
    });
}());

(function () {
    window.App.SimplePaginationOfListByScroll = LoadByScrollPagination.init({
        elements: querySelectorAll<HTMLElement>(
            '.js-simple-pagination-of-list-by-scroll',
        ),
        options: {
            events: {
                [PaginationEvents.initialized]: (component) => {
                    component.scrollLoad();
                },
                [PaginationEvents.afterLoad]: (component) => {
                    component.lock();

                    const loadedItemsClass = 'js-pagination-list-item-loaded';
                    const loadedItemsLoaded = querySelectorAll<HTMLElement>(
                        `.${loadedItemsClass}`,
                        component.el,
                    );

                    loadedItemsLoaded.forEach((item) => {
                        item.classList.remove(loadedItemsClass);
                    });

                    component.unlock();
                },
            },
        },
    });
}());

(function () {
    function addPageNumToUrl(pageName: string, pageNum: string): void {
        if (!pageNum) {
            return;
        }

        const initialUrl = new URL(window.location.href);
        const queryGetParams = new URLSearchParams(initialUrl.search);

        queryGetParams.set(pageName, pageNum);
        initialUrl.search = queryGetParams.toString();

        window.history.replaceState({}, '', initialUrl.toString());
    }

    window.App.SimplePaginationOfListByNavbar = LoadByNavbarPagination.init({
        elements: querySelectorAll<HTMLElement>(
            '.js-simple-pagination-of-list-by-navbar',
        ),
        options: {
            events: {
                [PaginationEvents.afterLoad]: (component) => {
                    component.lock();

                    const pageName = component.getPageName();

                    addPageNumToUrl(pageName, component.page);

                    component.unlock();
                },
            },
        },
    });
}());

(function () {
    window.App.SimpleContentWithFilter = ContentWithFilter.init({
        elements: querySelectorAll<HTMLElement>(
            '.js-simple-content-with-filter',
        ),
    });
}());

(function () {
    window.App.SearchResultContentWithFilter = SearchResultContentWithFilter.init({
        elements: querySelectorAll<HTMLElement>(
            '.js-search-result-content-with-filter',
        ),
    });
}());

window.App.Dropdown = Dropdown.init({
    elements: querySelectorAll<HTMLElement>('.js-dropdown'),
});

window.App.SpecialVersion = SpecialVersion.init({
    elements: querySelectorAll<HTMLElement>('.js-special-version'),
    options: {
        events: {
            [SpecialVersionEvents.change]: () => {
                if (window.App.MainBigNews) {
                    window.App.MainBigNews.forEach((mainBigNewsComponent) => {
                        (mainBigNewsComponent as MainBigNews).update();
                    });
                }
            },
        },
    },
});

window.App.CopyManager = CopyManager.init({
    elements: [document.createElement('div')],
});

window.App.ScrollUp = ScrollUp.init({
    elements: querySelector<HTMLElement>('.js-scroll-up'),
});

window.App.ModalErrorNotify = ModalErrorNotify.init({
    elements: querySelectorAll<HTMLElement>('.js-modal-error-notify'),
});

window.App.KamchatkaMap = KamchatkaMap.init({
    elements: querySelector<HTMLElement>('.kamchatka-svg'),
});

window.App.PollForm = PollForm.init({
    elements: querySelectorAll<HTMLElement>('.poll-form'),
});
