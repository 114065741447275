import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';
import {
    querySelectorAll,
    addEventListener,
} from '../utils/dom';

export enum Events {
    'initialized' = 'initialized',
    'clickItem' = 'clickItem',
}
export type Options = {
    events: {
        [Events.initialized]?: () => void;
        [Events.clickItem]?: () => void;
    };
};

export const defaultOptions: Options = {
    events: {},
};

class CardGallery extends Component<Options> {
    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.init();
    }

    protected init(): void {
        this.trigger(Events.initialized);

        const items = querySelectorAll<HTMLElement>(
            '.js-card-gallery-item',
            this.el,
        );

        addEventListener(items, 'click', (event: Event): void => {
            event.preventDefault();
            this.trigger(Events.clickItem, { el: event.currentTarget });
        });
    }
}

export default CardGallery;
