import { addEventListener } from '../utils/dom';
import Component, { ElementsType } from './Component';

class ShareButton extends Component {
    constructor(el: ElementsType) {
        super(el);

        this.initHandlers();
    }

    protected initHandlers(): void {
        if (!navigator.share) {
            addEventListener(this.el, 'click', (event: Event): void => {
                event.preventDefault();

                this.execute();
            });
        }
    }

    protected execute(): void {
        const url = this.el.getAttribute('href');
        if (!url) {
            return;
        }

        const left = (window.innerWidth - 570) / 2;
        const top = (window.innerHeight - 570) / 2;
        const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;

        window.open(url, 'NewWindow', params);
    }
}

export default ShareButton;
