import _defaultsDeep from 'lodash/defaultsDeep';
import _isString from 'lodash/isString';
import AbstractFormField, {
    Options as AbstractFormFieldOptions,
    AllowedTypes,
    AllowedTypesValues,
    Events as AbstractFormFieldEvents,
    defaultOptions as abstractFormDefaultOptions,
} from './AbstractFormField';
import { ElementsType } from '../../Component';
import {
    querySelector,
    addEventListener,
    addEventListenerLoadedPage,
} from '../../../utils/dom';

export const Events = {
    ...AbstractFormFieldEvents,
    input: 'input',
    focus: 'focus',
    blur: 'blur',
} as const;

export type Options = AbstractFormFieldOptions & {
    initialValue: string;
    clearBtnJsClass: string;
    inputJsClass: string;
    hasValueClass: string;
    focusClass: string;
    disabledClass: string;
    events: AbstractFormFieldOptions['events'] & {
        [Events.input]?: () => void;
    };
};

export const defaultOptions: Options = _defaultsDeep(
    {
        inputJsClass: 'js-field-textarea-input',
        clearBtnJsClass: 'js-field-textarea-clear-btn',
        errorClass: 'field-textarea--error',
        hasValueClass: 'field-textarea--has-value',
        focusClass: 'field-textarea--focused',
        disabledClass: 'field-textarea--disabled',
        initialValue: '',
        events: {},
    },
    abstractFormDefaultOptions,
);

class Textarea extends AbstractFormField<Options> {
    protected input: HTMLInputElement;

    protected clearBtn?: HTMLElement;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const input = querySelector<HTMLInputElement>(
            `.${this.options.inputJsClass}`,
            this.el,
        );
        if (!input) {
            throw new Error('Block "input" not found.');
        }
        this.input = input;

        const clearBtn = querySelector<HTMLInputElement>(
            `.${this.options.clearBtnJsClass}`,
            this.el,
        );
        if (clearBtn) {
            this.clearBtn = clearBtn;
        }

        this.init();
    }

    public getName(): string {
        return this.input.name;
    }

    public getType(): AllowedTypesValues {
        return AllowedTypes.textarea;
    }

    public getValue(): string {
        return this.input.value;
    }

    public setValue(value: string): this {
        if (_isString(value)) {
            this.input.value = `${value}`;
        } else {
            throw new Error('Unsupported type of value.');
        }

        this.syncHasValueState();
        this.trigger(Events.change);
        return this;
    }

    public disabled(): this {
        this.el.classList.add(this.options.disabledClass);
        this.input.disabled = true;
        this.trigger(Events.changeDisabled);
        return this;
    }

    public notDisabled(): this {
        this.input.disabled = false;
        this.el.classList.remove(this.options.disabledClass);
        this.trigger(Events.changeDisabled);
        return this;
    }

    public isDisabled(): boolean {
        return this.input.disabled;
    }

    public focusToInput(): this {
        this.input.focus();
        return this;
    }

    public resetToInitial(initialValue?: Options['initialValue']): this {
        if (_isString(initialValue)) {
            this.setValue(initialValue as string);
        } else {
            this.setValue(this.options.initialValue);
        }

        this.trigger(Events.reset);

        return this;
    }

    protected init(): void {
        addEventListener(this.input, 'input', this.handlerOnInput);
        addEventListener(this.input, 'change', this.handlerOnChange);
        addEventListener(this.input, 'focusin', this.handlerOnFocusIn);
        addEventListener(this.input, 'focusout', this.handlerOnFocusOut);
        addEventListener(this.clearBtn, 'click', this.handlerClearBtnOnClick);

        if (this.isDisabled()) {
            this.disabled();
        } else {
            this.notDisabled();
        }

        addEventListenerLoadedPage(() => {
            setTimeout(() => {
                this.syncFocusState();
                this.syncHasValueState();
            }, 300);
        });

        this.trigger(Events.initialized);
    }

    protected syncHasValueState() {
        if (this.isDisabled()) {
            return;
        }

        if (this.input.value.length) {
            this.el.classList.add(this.options.hasValueClass);
        } else {
            this.el.classList.remove(this.options.hasValueClass);
        }
    }

    protected syncFocusState() {
        if (this.isDisabled()) {
            return;
        }

        if (document.activeElement === this.input) {
            this.el.classList.add(this.options.focusClass);
        } else {
            this.el.classList.remove(this.options.focusClass);
        }
    }

    protected handlerOnInput = () => {
        if (this.isDisabled()) {
            return;
        }

        this.syncHasValueState();
        this.trigger(Events.input);
    };

    protected handlerOnChange = () => {
        if (this.isDisabled()) {
            return;
        }

        this.syncHasValueState();
        this.trigger(Events.change);
    };

    protected handlerOnFocusIn = () => {
        if (this.isDisabled()) {
            return;
        }

        this.syncFocusState();
        this.trigger(Events.focus);
    };

    protected handlerOnFocusOut = () => {
        if (this.isDisabled()) {
            return;
        }

        this.syncFocusState();
        this.trigger(Events.blur);
    };

    protected handlerClearBtnOnClick = (event: Event) => {
        if (this.isDisabled()) {
            return;
        }

        event.preventDefault();
        this.focusToInput();
        this.resetToInitial();
        this.syncHasValueState();
    };
}

export default Textarea;
