import _throttle from 'lodash/throttle';
import Pagination, { Options, Events } from './Pagination';
import { ElementsType } from '../Component';
import { addEventListener, querySelector } from '../../utils/dom';

class LoadByScrollPagination extends Pagination {
    protected scrollMarker: HTMLElement;

    constructor(el: ElementsType, options: Options = {} as Options) {
        super(el, options);

        const {
            paginationScrollMarker: scrollMarkerSelector = '',
        } = el.dataset;

        const scrollMarker = querySelector<HTMLElement>(scrollMarkerSelector, el);

        if (!scrollMarker) {
            throw new Error('Scroll marker is not found.');
        }

        this.scrollMarker = scrollMarker;

        addEventListener(window, 'scroll', _throttle(() => {
            this.scrollLoad();
        }, 300));

        addEventListener(window, 'resize', _throttle(() => {
            this.scrollLoad();
        }, 300));

        this.trigger(Events.initialized);
    }

    public scrollLoad(): void {
        if (this.isHardLocked() || !this.getUrl()) {
            return;
        }

        const {
            scrollTop,
            clientHeight,
        } = document.documentElement;

        const offsetTopScrollMarker = this.scrollMarker.offsetTop;
        const positionScrollMarker = this.scrollMarker.getBoundingClientRect().top;

        if ((scrollTop + clientHeight >= offsetTopScrollMarker) && positionScrollMarker > 0) {
            this.incrementPage();
            this.load().catch(() => {
                this.decrementPage();
            });
        }
    }
}

export default LoadByScrollPagination;
