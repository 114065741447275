import type { ElementsType } from 'components/Component';
import type { PollFormBlockOptions } from './PollFormBlock';
import PollFormBlock from './PollFormBlock';

class NegativeReasonsBlock extends PollFormBlock {
    textarea: HTMLTextAreaElement | null = null;

    flagForTextArea: HTMLInputElement | null = null;

    constructor(element: ElementsType, options: PollFormBlockOptions = {} as PollFormBlockOptions) {
        super(element, options);

        // включаем/отключаем textarea, если выбран чекбокс/радиобаттон "другое"
        this.textarea = this.el.querySelector('textarea');
        this.flagForTextArea = this.el.querySelector('input[value=""]');

        const { textarea, flagForTextArea } = this;
        if (textarea && flagForTextArea) {
            textarea.disabled = true;
            flagForTextArea.addEventListener('change', () => {
                textarea.disabled = !flagForTextArea.checked;
            });
        }
    }
}

export default NegativeReasonsBlock;
