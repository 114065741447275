import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import AbstractValidator from './AbstractValidator';

class RequiredValidator extends AbstractValidator {
    protected message = 'Поле обязательно для заполнения.';

    public validate(value?: unknown): string | true {
        if (
            (_isArray(value) && !value.length)
            || (value === undefined || value === null)
            || (_isObject(value) && !Object.keys(value).length)
            || (_isString(value) && !value.trim())
        ) {
            return this.getMessage();
        }

        return true;
    }
}

export default RequiredValidator;
