import _defaultsDeep from 'lodash/defaultsDeep';
import _isString from 'lodash/isString';
import { ElementsType } from '../../Component';
import {
    addEventListener,
    addEventListenerLoadedPage,
    querySelector,
} from '../../../utils/dom';
import AbstractFormField, {
    Options as AbstractFormFieldOptions,
    AllowedTypes,
    Events as AbstractFormFieldEvents,
    defaultOptions as abstractFormDefaultOptions,
    AllowedTypesValues,
} from './AbstractFormField';
import { isEnter } from '../../../utils/keyboard';

export const Events = {
    ...AbstractFormFieldEvents,
    input: 'input',
    focus: 'focus',
    blur: 'blur',
    submit: 'submit',
} as const;

export type Options = AbstractFormFieldOptions & {
    initialValue: string;
    clearBtnJsClass: string;
    inputJsClass: string;
    submitBtnJsClass: string;
    focusClass: string;
    disabledClass: string;
    hasValueClass: string;
    events: AbstractFormFieldOptions['events'] & {
        [Events.input]?: () => void;
        [Events.submit]?: () => void;
    };
};

export const defaultOptions: Options = _defaultsDeep(
    {
        inputJsClass: 'js-field-search-input',
        clearBtnJsClass: 'js-field-search-clear-btn',
        submitBtnJsClass: 'js-field-search-submit-btn',
        errorClass: 'field-search--error',
        hasValueClass: 'field-search--has-value',
        disabledClass: 'field-search--disabled',
        focusClass: 'field-search--focused',
        initialValue: '',
    },
    abstractFormDefaultOptions,
);

class Search extends AbstractFormField<Options> {
    protected input: HTMLInputElement;

    protected clearBtn?: HTMLElement;

    protected submitBtn?: HTMLElement;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const input = querySelector<HTMLInputElement>(
            `.${this.options.inputJsClass}`,
            this.el,
        );
        if (!input) {
            throw new Error('Block "input" not found.');
        }
        this.input = input;

        const clearBtn = querySelector<HTMLInputElement>(
            `.${this.options.clearBtnJsClass}`,
            this.el,
        );
        if (clearBtn) {
            this.clearBtn = clearBtn;
        }

        const submitBtn = querySelector<HTMLInputElement>(
            `.${this.options.submitBtnJsClass}`,
            this.el,
        );
        if (submitBtn) {
            this.submitBtn = submitBtn;
        }

        this.init();
    }

    public getName(): string {
        return this.input.name;
    }

    public getType(): AllowedTypesValues {
        return AllowedTypes.search;
    }

    public getValue(): string {
        return this.input.value;
    }

    public setValue(value: string): this {
        if (_isString(value)) {
            this.input.value = `${value}`;
        } else {
            throw new Error('Unsupported type of value.');
        }

        this.syncHasValueState();
        this.trigger(Events.change);
        return this;
    }

    public disabled(): this {
        this.el.classList.add(this.options.disabledClass);
        this.input.disabled = true;
        this.trigger(Events.changeDisabled);
        return this;
    }

    public notDisabled(): this {
        this.input.disabled = false;
        this.el.classList.remove(this.options.disabledClass);
        this.trigger(Events.changeDisabled);
        return this;
    }

    public isDisabled(): boolean {
        return this.input.disabled;
    }

    public focusToInput(): this {
        this.input.focus();
        return this;
    }

    public resetToInitial(initialValue?: Options['initialValue']): this {
        if (_isString(initialValue)) {
            this.setValue(initialValue as string);
        } else {
            this.setValue(this.options.initialValue);
        }

        this.trigger(Events.reset);

        return this;
    }

    protected init(): void {
        addEventListener(this.input, 'input', this.handlerOnInput);
        addEventListener(this.input, 'change', this.handlerOnChange);
        addEventListener(this.input, 'focusin', this.handlerOnFocusIn);
        addEventListener(this.input, 'focusout', this.handlerOnFocusOut);
        addEventListener(this.clearBtn, 'click', this.handlerOnClearBtnClick);
        addEventListener(this.submitBtn, 'click', this.handlerOnSubmitBtnClick);
        addEventListener(document, 'keyup', this.handlerOnKeyUpDocument, true);

        if (this.isDisabled()) {
            this.disabled();
        } else {
            this.notDisabled();
        }

        addEventListenerLoadedPage(() => {
            setTimeout(() => {
                this.syncFocusState();
                this.syncHasValueState();
            }, 300);
        });

        this.trigger(Events.initialized);
    }

    protected syncHasValueState(): void {
        if (this.isDisabled()) {
            return;
        }

        if (this.input.value.length) {
            this.el.classList.add(this.options.hasValueClass);
        } else {
            this.el.classList.remove(this.options.hasValueClass);
        }
    }

    protected syncFocusState(): void {
        if (this.isDisabled()) {
            return;
        }

        if (document.activeElement === this.input) {
            this.el.classList.add(this.options.focusClass);
        } else {
            this.el.classList.remove(this.options.focusClass);
        }
    }

    protected handlerOnInput = (): void => {
        if (this.isDisabled()) {
            return;
        }

        this.syncHasValueState();
        this.trigger(Events.input);
    };

    protected handlerOnChange = (): void => {
        if (this.isDisabled()) {
            return;
        }

        this.syncHasValueState();
        this.syncFocusState();
        this.trigger(Events.change);
    };

    protected handlerOnFocusIn = (): void => {
        if (this.isDisabled()) {
            return;
        }

        if (document.activeElement === this.input) {
            this.syncFocusState();
            this.syncHasValueState();
            this.trigger(Events.focus);
        }
    };

    protected handlerOnFocusOut = (): void => {
        if (this.isDisabled()) {
            return;
        }

        if (document.activeElement !== this.input) {
            this.syncFocusState();
            this.syncHasValueState();
            this.trigger(Events.blur);
        }
    };

    protected handlerOnClearBtnClick = (event: Event): void => {
        if (this.isDisabled()) {
            return;
        }

        event.preventDefault();
        this.focusToInput();
        this.resetToInitial();
        this.syncHasValueState();
    };

    protected handlerOnKeyUpDocument = (event: Event): void => {
        if (this.isDisabled()) {
            return;
        }

        if (document.activeElement === this.input) {
            event.preventDefault();

            if (isEnter(event as KeyboardEvent)) {
                this.trigger(Events.submit);
            }
        }
    };

    protected handlerOnSubmitBtnClick = (event: Event): void => {
        if (this.isDisabled()) {
            return;
        }

        event.preventDefault();
        this.trigger(Events.submit);
    };
}

export default Search;
