import _first from 'lodash/first';
import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';
import ToggleClass from './ToggleClass';
import {
    querySelector,
    querySelectorAll,
    addEventListener,
} from '../utils/dom';
import { HIDDEN_CLASS } from '../constants';

export type Options = {
    toggleClass: string;
    itemClass: string;
    itemHiddenClass: string;
    showMoreBtnClass: string;
    showLessBtnClass: string;
};

export const defaultOptions: Options = {
    toggleClass: 'show-more--opened',
    itemClass: 'js-item-hidden',
    itemHiddenClass: HIDDEN_CLASS,
    showMoreBtnClass: 'js-show-more-btn',
    showLessBtnClass: 'js-show-less-btn',
};

class ShowMore extends Component<Options> {
    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.init();
    }

    protected init(): void {
        const items = querySelectorAll<HTMLElement>(
            `.${this.options.itemClass}`,
            this.el,
        );

        if (!items.length) {
            return;
        }

        const toggleClass: ToggleClass | undefined = _first(
            ToggleClass.init({
                elements: this.el,
                options: {
                    toggleClass: this.options.toggleClass,
                    target: this.el,
                },
            }),
        );

        const showMoreBtn = this.options.showMoreBtnClass
            ? querySelector<HTMLElement>(
                `.${this.options.showMoreBtnClass}`,
                this.el,
            ) : null;

        const showLessBtn = this.options.showLessBtnClass
            ? querySelector<HTMLElement>(
                `.${this.options.showLessBtnClass}`,
                this.el,
            ) : null;

        addEventListener(showMoreBtn, 'click', (event: Event): void => {
            const targetBtn = <HTMLElement>event.currentTarget;

            targetBtn.classList.add(HIDDEN_CLASS);
            items.forEach((item) => item.classList.remove(this.options.itemHiddenClass));

            if (showLessBtn) {
                showLessBtn.classList.remove(HIDDEN_CLASS);
            }

            if (toggleClass && !toggleClass.contains()) {
                toggleClass.add();
            }
        });

        addEventListener(showLessBtn, 'click', (event: Event): void => {
            const targetBtn = <HTMLElement>event.currentTarget;

            targetBtn.classList.add(HIDDEN_CLASS);
            items.forEach((item) => item.classList.add(this.options.itemHiddenClass));

            if (showMoreBtn) {
                showMoreBtn.classList.remove(HIDDEN_CLASS);
            }

            if (toggleClass && toggleClass.contains()) {
                toggleClass.remove();
            }
        });
    }
}

export default ShowMore;
