import _defaultsDeep from 'lodash/defaultsDeep';
import _isNumber from 'lodash/isNumber';
import Pagination, {
    Options as PaginationOptions,
    defaultOptions as paginationDefaultOptions,
    Events,
} from './Pagination';
import { ElementsType } from '../Component';
import {
    addEventListener,
    querySelector,
    querySelectorAll,
} from '../../utils/dom';

export type Options = PaginationOptions & {
    beginButtonClass: string;
    nextPageButtonClass: string;
    pageButtonClass: string;
};

export const defaultOptions: Options = _defaultsDeep(
    {
        beginButtonClass: '.js-pagination-btn-begin',
        nextPageButtonClass: '.js-pagination-btn-page-next',
        pageButtonClass: '.js-pagination-btn-page',
    },
    paginationDefaultOptions,
);
class LoadByNavbarPagination extends Pagination<Options> {
    protected navbarContainer: HTMLElement | null;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const {
            paginationNavbarContainer: navbarContainerSelector = '.js-pagination-navbar-container',
        } = el.dataset;

        this.navbarContainer = querySelector<HTMLElement>(navbarContainerSelector, el);

        this.initHandlers();

        this.trigger(Events.initialized);
    }

    protected initHandlers(): void {
        const beginButton = querySelector<HTMLButtonElement>(
            this.options.beginButtonClass,
            this.el,
        );
        const nextPageButton = querySelector<HTMLButtonElement>(
            this.options.nextPageButtonClass,
            this.el,
        );
        const pageButtons = querySelectorAll<HTMLButtonElement>(
            this.options.pageButtonClass,
            this.el,
        );

        addEventListener(beginButton, 'click', this.handlerBeginBtnOnClick);
        addEventListener(nextPageButton, 'click', this.handlerNextPageBtnOnClick);
        addEventListener(pageButtons, 'click', this.handlerPageBtnOnClick);
    }

    protected handlerBeginBtnOnClick = (event: Event) => {
        event.preventDefault();

        const oldPage = this.getPage();

        if (oldPage <= 1 || this.isLocked() || this.isLoading() || !this.getUrl()) {
            return;
        }

        const oldIsLastPage = this.isLastPage();

        this.setPage(1);
        this.setNoLastPage();
        this.unlock();

        this.load(true)
            .then(() => {
                this.updateNavbar();
            })
            .catch(() => {
                this.setPage(oldPage);

                if (oldIsLastPage) {
                    this.setLastPage();
                }
            });
    };

    protected handlerNextPageBtnOnClick = (event: Event) => {
        event.preventDefault();

        if (this.isHardLocked() || !this.getUrl()) {
            return;
        }

        const oldPage = this.getPage();

        this.incrementPage();

        this.load(true)
            .then(() => {
                this.updateNavbar();
            })
            .catch(() => {
                this.setPage(oldPage);
            });
    };

    protected handlerPageBtnOnClick = (event: Event) => {
        event.preventDefault();

        if (this.isLocked() || this.isLoading() || !this.getUrl()) {
            return;
        }

        const oldPage = this.getPage();
        const pageButton = <HTMLButtonElement>event.target;
        const pageFromBtn = pageButton.dataset.paginationPageNumber || '';

        if (pageFromBtn === '') {
            return;
        }

        const pageFromBtnAsNumber = parseInt(pageFromBtn, 10);

        if (!_isNumber(pageFromBtnAsNumber) || oldPage === pageFromBtnAsNumber) {
            return;
        }

        this.setPage(pageFromBtnAsNumber);
        const oldIsLastPage = this.isLastPage();

        this.load(true)
            .then(() => {
                this.updateNavbar();
            })
            .catch(() => {
                this.setPage(oldPage);

                if (oldIsLastPage) {
                    this.setLastPage();
                } else {
                    this.setNoLastPage();
                }
            });
    };

    public updateNavbar(): void {
        if (!this.navbarContainer || !this.lastResponseData) {
            return;
        }

        const { pagination: { html: paginationHtml = '' } } = this.lastResponseData;

        this.navbarContainer.innerHTML = paginationHtml;

        this.initHandlers();
    }
}

export default LoadByNavbarPagination;
