import _defaultsDeep from 'lodash/defaultsDeep';
import _isArray from 'lodash/isArray';
import flatpickr from 'flatpickr';
import { Russian as RussianLocale } from 'flatpickr/dist/l10n/ru';
import {
    parse as _dateFnsParse,
    isValid as _dateFnsIsValid,
    compareAsc as _dateFnsCompareAsc,
    format as _dateFnsFormat,
} from 'date-fns';
import _dateFnsRuLocale from 'date-fns/locale/ru';
import AbstractFormField, {
    Options as AbstractFormFieldOptions,
    AllowedTypes,
    AllowedTypesValues,
    Events as AbstractFormFieldEvents,
    defaultOptions as abstractFormDefaultOptions,
} from './AbstractFormField';
import { ElementsType } from '../../Component';
import {
    querySelector,
    addEventListener,
    querySelectorAll,
    addEventListenerLoadedPage,
} from '../../../utils/dom';

export const Events = {
    ...AbstractFormFieldEvents,
} as const;

export type Options = AbstractFormFieldOptions & {
    initialValue: string[];
    errorClass: string;
    hasValueClass: string;
    showClass: string;
    disabledClass: string;
    changeBoxJsClass: string;
    selectedDatesJsClass: string;
    inputsJsClass: string;
    controlNoValueJsClass: string;
    controlHasValueJsClass: string;
    resultJsClass: string;
    showButtonsJsClass: string;
    clearButtonsJsClass: string;
    confirmButtonsJsClass: string;
    cancelButtonsJsClass: string;
};

export const defaultOptions: Options = _defaultsDeep(
    {
        initialValue: [],
        errorClass: 'field-datepicker--error',
        hasValueClass: 'field-datepicker--has-value',
        showClass: 'field-datepicker--opened',
        disabledClass: 'field-datepicker--disabled',
        changeBoxJsClass: 'js-field-datepicker-change-box',
        selectedDatesJsClass: 'js-field-datepicker-selected-dates',
        inputsJsClass: 'js-field-datepicker-input',
        controlNoValueJsClass: 'js-field-datepicker-show-control-no-value',
        controlHasValueJsClass: 'js-field-datepicker-show-control-has-value',
        resultJsClass: 'js-field-datepicker-result',
        showButtonsJsClass: 'js-field-datepicker-show-control',
        clearButtonsJsClass: 'js-field-datepicker-clear-btn',
        confirmButtonsJsClass: 'js-field-datepicker-confirm-btn',
        cancelButtonsJsClass: 'js-field-datepicker-cancel-btn',
        events: {},
    },
    abstractFormDefaultOptions,
);

class Datepicker extends AbstractFormField<Options> {
    protected instance!: flatpickr.Instance;

    protected changeBox: HTMLElement;

    protected libraryInput: HTMLInputElement;

    protected inputs: HTMLInputElement[];

    protected maxDate: Date | null = null;

    protected minDate: Date | null = null;

    protected isRange: boolean;

    protected sendFormat: string;

    protected resultFormat: string;

    protected confirmedDates: Date[] = [];

    protected controlNoValue: HTMLElement;

    protected controlHasValue: HTMLElement;

    protected result: HTMLElement[];

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const {
            fieldDatepickerMinDate: minDateStr,
            fieldDatepickerMaxDate: maxDateStr,
            fieldDatepickerSendFormat: sendFormat = 'yyyy-MM-dd',
            fieldDatepickerIsRange: isRange = 'false',
            fieldDatepickerResultFormat: resultFormat = 'dd.MM.yyyy',
        } = this.el.dataset;

        this.isRange = isRange === 'true';
        this.sendFormat = sendFormat;
        this.resultFormat = resultFormat;

        if (minDateStr) {
            const minDate = _dateFnsParse(minDateStr, sendFormat, new Date());

            if (_dateFnsIsValid(minDate)) {
                this.minDate = minDate;
            }
        }

        if (maxDateStr) {
            const maxDate = _dateFnsParse(maxDateStr, sendFormat, new Date());

            if (_dateFnsIsValid(maxDate)) {
                this.maxDate = maxDate;
            }
        }

        this.changeBox = querySelector<HTMLElement>(
            `.${this.options.changeBoxJsClass}`,
            this.el,
        ) as HTMLElement;

        const selectedDates = querySelector<HTMLElement>(
            `.${this.options.selectedDatesJsClass}`,
            this.el,
        ) as HTMLElement;

        this.inputs = querySelectorAll<HTMLInputElement>(
            `.${this.options.inputsJsClass}`,
            selectedDates,
        ) as HTMLInputElement[];
        this.inputs = this.inputs.slice(0, this.isRange ? 2 : 1);

        const libraryInput = document.createElement('input');
        libraryInput.type = 'text';
        libraryInput.value = '';

        this.libraryInput = libraryInput;

        this.controlNoValue = querySelector<HTMLElement>(
            `.${this.options.controlNoValueJsClass}`,
            this.el,
        ) as HTMLElement;

        this.controlHasValue = querySelector<HTMLElement>(
            `.${this.options.controlHasValueJsClass}`,
            this.el,
        ) as HTMLElement;

        this.result = querySelectorAll<HTMLElement>(
            `.${this.options.resultJsClass}`,
            this.el,
        ) as HTMLElement[];

        this.init();
    }

    public getName(): string {
        return this.inputs.map((input) => input.name).join('|');
    }

    public getType(): AllowedTypesValues {
        return AllowedTypes.text;
    }

    public getValue(withEmpty = false): [string, string][] {
        let result: [string, string][] = this.inputs
            .map((input) => [input.name, input.value]);

        if (!withEmpty) {
            result = result.filter((value) => value[1] !== '');
        }

        return result;
    }

    public setValue(value: Options['initialValue']): this {
        if (_isArray(value)) {
            this.confirmedDates = [];
            value.forEach((valueItem) => {
                const parsedDate = _dateFnsParse(valueItem, this.sendFormat, new Date());
                if (_dateFnsIsValid(parsedDate)) {
                    this.confirmedDates.push(parsedDate);
                }
            });
        } else {
            throw new Error('Wrong type of value.');
        }

        this.confirmedDates.sort(_dateFnsCompareAsc);
        this.instance.setDate([...this.confirmedDates], true);

        this.syncStates();
        this.trigger(Events.change);
        return this;
    }

    public disabled(): this {
        this.el.classList.add(this.options.disabledClass);
        this.inputs.forEach((input) => {
            input.disabled = true;
        });
        this.trigger(Events.changeDisabled);
        return this;
    }

    public notDisabled(): this {
        this.inputs.forEach((input) => {
            input.disabled = false;
        });
        this.el.classList.remove(this.options.disabledClass);
        this.trigger(Events.changeDisabled);
        return this;
    }

    public isDisabled(): boolean {
        return (
            this.inputs.some((input) => input.disabled)
            || this.el.classList.contains(this.options.disabledClass)
        );
    }

    public resetToInitial(initialValue?: Options['initialValue']): this {
        if (_isArray(initialValue)) {
            this.setValue(initialValue as string[]);
        } else {
            this.setValue(this.options.initialValue);
        }

        this.syncStates();
        this.trigger(Events.reset);
        return this;
    }

    public showBox(): this {
        this.el.classList.add(this.options.showClass);
        return this;
    }

    public hideBox(): this {
        this.el.classList.remove(this.options.showClass);
        return this;
    }

    public isShown(): boolean {
        return this.el.classList.contains(this.options.showClass);
    }

    public getInputs(): Datepicker['inputs'] {
        return [...this.inputs];
    }

    protected init(): void {
        let defaultDate: Date[] = [];
        defaultDate = this.inputs
            .map((input) => {
                const { value } = input;
                const parsedDate = _dateFnsParse(value, this.sendFormat, new Date());
                return _dateFnsIsValid(parsedDate) ? parsedDate : null;
            })
            .filter((date) => !!date) as Date[];

        const libraryContainer = document.createElement('div');
        this.changeBox.prepend(libraryContainer);

        defaultDate.sort(_dateFnsCompareAsc);

        this.confirmedDates = defaultDate;

        // @ts-ignore
        this.instance = flatpickr(this.libraryInput, {
            dateFormat: this.resultFormat,
            locale: RussianLocale,
            disableMobile: true,
            appendTo: libraryContainer,
            inline: true,
            defaultDate,
            mode: this.isRange ? 'range' : 'single',
            prevArrow: `
                <svg
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="transform-rotate-z-180"
                >
                    <use xlink:href="#svg-symbol-icon-chevron-right-M"></use>
                </svg>
            `,
            nextArrow: `
                <svg
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class=""
                >
                    <use xlink:href="#svg-symbol-icon-chevron-right-M"></use>
                </svg>
            `,
            onDayCreate: (
                selected: Date[],
                currentValue: string,
                instance: flatpickr.Instance,
                dayEl: HTMLElement,
            ) => {
                const text = dayEl.innerHTML;
                dayEl.innerHTML = `<span class="field-datepicker__day-text">${text}</span>`;
            },
            minDate: this.minDate,
            maxDate: this.maxDate,
        });

        this.instance.setDate([...defaultDate], true);

        if (this.isDisabled()) {
            this.disabled();
        } else {
            this.notDisabled();
        }

        const showButtons = querySelectorAll<HTMLElement>(
            `.${this.options.showButtonsJsClass}`,
            this.el,
        );

        const clearButtons = querySelectorAll<HTMLElement>(
            `.${this.options.clearButtonsJsClass}`,
            this.el,
        );

        const confirmButtons = querySelectorAll<HTMLElement>(
            `.${this.options.confirmButtonsJsClass}`,
            this.el,
        );

        const cancelButtons = querySelectorAll<HTMLElement>(
            `.${this.options.cancelButtonsJsClass}`,
            this.el,
        );

        addEventListener(showButtons, 'click', (event: Event) => {
            event.preventDefault();

            if (this.isDisabled()) {
                return;
            }

            if (this.isShown()) {
                this.instance.setDate([...this.confirmedDates]);
                this.syncStates();
                this.hideBox();
            } else {
                this.showBox();
            }
        });

        addEventListener(clearButtons, 'click', (event: Event) => {
            event.preventDefault();

            if (this.isDisabled()) {
                return;
            }

            this.resetToInitial();
        });

        addEventListener(confirmButtons, 'click', (event: Event) => {
            event.preventDefault();

            if (!this.isShown() || this.isDisabled()) {
                return;
            }

            this.confirmedDates = [...this.instance.selectedDates];
            this.syncStates();
            this.hideBox();
            this.trigger(Events.change);
        });

        addEventListener(cancelButtons, 'click', (event: Event) => {
            event.preventDefault();

            if (!this.isShown() || this.isDisabled()) {
                return;
            }

            this.instance.setDate([...this.confirmedDates]);
            this.syncStates();
            this.hideBox();
        });

        addEventListener(document, 'click', (event: Event): void => {
            const target = <HTMLElement>event.target;

            if (target === this.el || !this.el.contains(target)) {
                this.instance.setDate([...this.confirmedDates]);
                this.syncStates();
                this.hideBox();
            }
        }, true);

        addEventListenerLoadedPage(() => {
            setTimeout(() => {
                this.syncStates();
            }, 300);
        });

        this.on(Events.changeDisabled, () => {
            if (this.isDisabled()) {
                this.hideBox();
            }
        });

        this.trigger(Events.initialized);
    }

    protected syncStates(): void {
        this.syncResult();
        this.syncInputsStates();
        this.syncRootState();
    }

    protected syncInputsStates(): void {
        const { confirmedDates } = this;
        this.inputs.forEach((input, index) => {
            if (confirmedDates[index] instanceof Date) {
                input.value = _dateFnsFormat(
                    confirmedDates[index],
                    this.sendFormat,
                );
            } else {
                input.value = '';
            }
        });
    }

    protected syncRootState(): void {
        const { confirmedDates } = this;

        if (confirmedDates.length > 0) {
            this.el.classList.add(this.options.hasValueClass);
        } else {
            this.el.classList.remove(this.options.hasValueClass);
        }
    }

    protected syncResult(): void {
        const { confirmedDates } = this;
        const resultStrArr: string[] = [];

        confirmedDates.forEach((date) => {
            resultStrArr.push(
                _dateFnsFormat(
                    date,
                    this.resultFormat,
                    { locale: _dateFnsRuLocale },
                ),
            );
        });

        const resultStr = resultStrArr.join(' &mdash; ');

        this.result.forEach((resultItem) => {
            resultItem.innerHTML = resultStr;
        });
    }
}

export default Datepicker;
