import axios, { AxiosError, AxiosResponse } from 'axios';

export type LoadingResponse<T = any> = AxiosResponse<T>;
export type LoadingError = Error | AxiosError;

const commonHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
};

const htmlHeaders = {
    'Content-Type': 'multipart/form-data',
    Accept: 'text/html',
};

export const jsonInstance = axios.create({
    baseURL: '/',
    headers: commonHeaders,
} as any);

export const htmlInstance = axios.create({
    baseURL: '/',
    headers: htmlHeaders,
} as any);
