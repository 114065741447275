import _defaultsDeep from 'lodash/defaultsDeep';
import _first from 'lodash/first';
import Component, { ElementsType } from '../Component';
import BaseContentFilter, { Events as BaseContentFilterEvents } from './BaseContentFilter';
import Pagination, { Events as PaginationEvents } from '../pagination/Pagination';
import LoadByButtonPagination from '../pagination/LoadByButtonPagination';
import LoadByScrollPagination from '../pagination/LoadByScrollPagination';
import LoadByNavbarPagination from '../pagination/LoadByNavbarPagination';
import { querySelector, querySelectorAll } from '../../utils/dom';
import { baseUrl } from '../../utils/helpers';

export const Events = {
    initialized: 'initialized',
} as const;

export type Options = {
    pagination?: Pagination,
    contentFilter?: BaseContentFilter,
    locationUrl: string;
    events: {
        [Events.initialized]?: () => void | string;
    };
};

export const ChangeUrl = {
    none: 'none',
    'only-filter': 'only-filter',
    all: 'all',
} as const;

export const defaultOptions: Options = {
    locationUrl: '',
    events: {},
};

class SearchResultContentWithFilter<T extends Options = Options> extends Component<T> {
    protected contentFilter: Options['contentFilter'];

    protected pagination: Options['pagination'];

    protected resultContainer: HTMLElement;

    protected changeUrl: keyof typeof ChangeUrl;

    protected initialUrl: URL;

    protected locationUrl: URL;

    constructor(el: ElementsType, options: T = defaultOptions as T) {
        const normalizedOptions: T = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const {
            contentWithFilterResultContainer: resultContainerSelector = '.js-result-container',
            contentWithFilterChangeUrl: changeUrl = ChangeUrl.none,
            contentWithFilterLocationUrl: filterLocationUrl = '',
        } = this.el.dataset as {
            contentWithFilterResultContainer: string;
            contentWithFilterChangeUrl: keyof typeof ChangeUrl;
            contentWithFilterLocationUrl: string;
        };

        const resultContainer = querySelector<HTMLElement>(
            resultContainerSelector,
            el,
        );

        if (!resultContainer) {
            throw new Error('Result container not found.');
        }

        this.resultContainer = resultContainer;
        this.pagination = this.options.pagination || this.makeDefaultPagination();
        this.contentFilter = this.options.contentFilter || this.makeDefaultContentFilter();
        this.changeUrl = changeUrl;
        this.initialUrl = new URL(window.location.pathname, window.location.origin);

        const filterUrl = this.options.locationUrl || filterLocationUrl || window.location.href;
        this.locationUrl = new URL(filterUrl, baseUrl());

        if (this.contentFilter) {
            this.contentFilter.on(BaseContentFilterEvents.submit, () => {
                const newLocationUrl = this.generateLocationUrl();
                window.location.href = newLocationUrl.href;
            });

            this.contentFilter.on(BaseContentFilterEvents.reset, () => {
                window.location.href = this.locationUrl.href;
            });
        }

        if (this.pagination) {
            this.pagination.on(PaginationEvents.afterLoad, (pagination: Pagination) => {
                this.updatePageUrl();

                if (pagination instanceof LoadByNavbarPagination) {
                    pagination.updateNavbar();
                }
            });
        }

        this.trigger(Events.initialized);
    }

    protected makeDefaultPagination(): Options['pagination'] {
        let pagination: Pagination | undefined = _first(
            LoadByScrollPagination.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-content-search-filter-pagination-of-list-by-scroll',
                    this.el,
                ),
            }),
        );

        if (!pagination) {
            pagination = _first(
                LoadByButtonPagination.init({
                    elements: querySelectorAll<HTMLElement>(
                        '.js-content-search-filter-pagination-of-list-by-button',
                        this.el,
                    ),
                }),
            );
        }

        if (!pagination) {
            pagination = _first(
                LoadByNavbarPagination.init({
                    elements: querySelectorAll<HTMLElement>(
                        '.js-content-search-filter-pagination-of-list-by-navbar',
                        this.el,
                    ),
                }),
            );
        }

        return pagination;
    }

    protected makeDefaultContentFilter(): Options['contentFilter'] {
        return _first(
            BaseContentFilter.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-content-filter',
                    this.el,
                ),
            }),
        );
    }

    protected updatePageUrl(): void {
        if (this.changeUrl === ChangeUrl.none) {
            return;
        }

        const newUrl = new URL(this.initialUrl);
        const queryGetParamsFromNewUrl = new URLSearchParams(newUrl.search);

        if ([ChangeUrl.all, ChangeUrl['only-filter']].includes(this.changeUrl)) {
            if ((this.changeUrl === ChangeUrl.all) && this.pagination) {
                const page = this.pagination.getPage();
                const pageName = this.pagination.getPageName();
                queryGetParamsFromNewUrl.set(pageName, String(page));
            }

            if (this.contentFilter) {
                const formData = this.contentFilter.getFormData();
                const contentFilterGetParams = new URLSearchParams(formData as any);

                const allKeys = new Set(contentFilterGetParams.keys());

                contentFilterGetParams.forEach((value, name) => {
                    if (allKeys.has(name)) {
                        allKeys.delete(name);
                        queryGetParamsFromNewUrl.delete(name);
                    }
                    if (value !== '') {
                        queryGetParamsFromNewUrl.append(name, value);
                    }
                });
            }
        }

        newUrl.hash = this.initialUrl.hash;
        newUrl.search = queryGetParamsFromNewUrl.toString();

        window.history.replaceState({}, '', newUrl.toString());
    }

    protected generateLocationUrl(): URL {
        const newUrl = new URL(this.locationUrl);
        const queryGetParamsFromNewUrl = new URLSearchParams(newUrl.search);

        if (this.contentFilter) {
            const formData = this.contentFilter.getFormData();
            const contentFilterGetParams = new URLSearchParams(formData as any);

            const allKeys = new Set(queryGetParamsFromNewUrl.keys());

            contentFilterGetParams.forEach((value, name) => {
                if (allKeys.has(name) && value !== '') {
                    queryGetParamsFromNewUrl.set(name, value);
                }

                if (!allKeys.has(name) && value !== '') {
                    queryGetParamsFromNewUrl.append(name, value);
                }
            });
        }

        newUrl.hash = this.locationUrl.hash;
        newUrl.search = queryGetParamsFromNewUrl.toString();

        return newUrl;
    }
}

export default SearchResultContentWithFilter;
