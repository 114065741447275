import _defaultsDeep from 'lodash/defaultsDeep';
import { ElementsType } from '../../Component';
import BaseForm, {
    Events as BaseFormEvents,
    Options as BaseFormOptions,
} from './BaseForm';

export const Events = {
    ...BaseFormEvents,
} as const;

export type Options = BaseFormOptions;

export const defaultOptions: Options = {
    validation: {
        live: true,
    },
    events: {},
};

class SimpleForm extends BaseForm<Options> {
    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.loadFields();

        this.trigger(Events.initialized);
    }
}

export default SimpleForm;
