import _template from 'lodash/template';
import _camelCase from 'lodash/camelCase';

export const toCamelCase = (str: string) => _camelCase(str);

export const template = (
    str: string,
    data: { [key in string]: any },
): string => {
    const compiledTemplate = _template(str, {
        interpolate: /{{([\s\S]+?)}}/g,
    });
    return compiledTemplate(data) as string;
};

export const numberToString = (
    num: number,
    withZero = true,
): string => {
    if (!withZero) {
        return `${num}`;
    }

    return num < 10 ? `0${num}` : `${num}`;
};

export const declensionOfNumbers = (
    num: number,
    textForms: [string, string, string],
) => {
    const last2 = Math.abs(num) % 100;
    const last = last2 % 10;

    if (last2 > 10 && last2 < 20) {
        return textForms[2];
    }

    if (last > 1 && last < 5) {
        return textForms[1];
    }

    if (last === 1) {
        return textForms[0];
    }

    return textForms[2];
};
