import {
    MOBILE_MAX_WIDTH,
    TABLET_MIN_WIDTH,
    TABLET_MAX_WIDTH,
    DESKTOP_MIN_WIDTH,
} from '../constants';

const matchMedia = (query: string) => window.matchMedia(query).matches;
const isMobile = () => matchMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`);
const isTablet = () => matchMedia(`(min-width: ${TABLET_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH}px)`);
const isDesktop = () => matchMedia(`(min-width: ${DESKTOP_MIN_WIDTH}px)`);

export {
    isMobile,
    isTablet,
    isDesktop,
    matchMedia,
};
