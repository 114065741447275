import _first from 'lodash/first';
import Swiper from 'swiper';
import { EffectFade } from 'swiper/modules';
import Component, { ElementsType, Options } from './Component';
import { querySelector, querySelectorAll, addEventListener } from '../utils/dom';
import { DESKTOP_MIN_WIDTH, TABLET_MIN_WIDTH } from '../constants';
import SimpleTimer, { Events as SimpleTimerEvents } from './SimpleTimer';

const newsListItemActiveClass = 'card-horizontal--active';

class MainBigNews extends Component<Options> {
    private selectedNewsSlider: {
        instance: Swiper;
        timer: SimpleTimer;
        destroy: () => void;
    } | null;

    private newsListItems: HTMLElement[] | NodeListOf<HTMLElement>;

    private activeSlideIndex: number;

    private sliderContainer: HTMLElement;

    private sliderDelay: number;

    constructor(el: ElementsType) {
        super(el);

        this.selectedNewsSlider = null;
        this.activeSlideIndex = 0;
        this.newsListItems = querySelectorAll<HTMLElement>(
            '.js-main-big-news-news-list-item',
            this.el,
        );

        const sliderContainer = querySelector<HTMLElement>(
            '.js-main-big-news-selected-news-slider',
            this.el,
        );

        if (!sliderContainer) {
            throw new Error('Slider container not found.');
        }

        this.sliderContainer = sliderContainer;
        this.sliderDelay = parseInt(sliderContainer.dataset.mainBigNewsDelay || '5000', 10);

        this.init();
    }

    public initSelectedNewsSlider(): void {
        let runningTick = false;
        const instance = new Swiper(this.sliderContainer, {
            slidesPerView: 1,
            init: false,
            spaceBetween: 12,
            loop: true,
            centeredSlides: false,
            modules: [EffectFade],
            allowTouchMove: true,
            autoHeight: true,
            speed: 400,
            breakpoints: {
                [TABLET_MIN_WIDTH]: {
                    spaceBetween: 20,
                    centeredSlides: false,
                    allowTouchMove: true,
                },
                [DESKTOP_MIN_WIDTH]: {
                    spaceBetween: 0,
                    effect: 'fade',
                    speed: 250,
                    fadeEffect: {
                        crossFade: true,
                    },
                    centeredSlides: false,
                    allowTouchMove: false,
                },
            },
        });

        const timer: SimpleTimer | undefined = _first(
            SimpleTimer.init({
                elements: document.createElement('div'),
                options: {
                    interval: this.sliderDelay,
                },
            }),
        );

        if (!timer) {
            throw new Error('Timer not executed.');
        }

        timer.on(SimpleTimerEvents.tick, (): void => {
            runningTick = true;
            if (this.activeSlideIndex < (instance.slides.length - 1)) {
                instance.slideNext();
            } else {
                instance.slideTo(0);
            }
        });

        this.selectedNewsSlider = {
            instance,
            timer,
            destroy() {
                if (this.instance) {
                    this.instance.destroy();
                }
            },
        };

        instance.on('init', () => {
            this.changeActiveNewsListItem(0);
        });

        instance.on('slideChange', (slider) => {
            this.changeActiveNewsListItem(slider.activeIndex);

            if (!runningTick) {
                timer.start();
            }

            runningTick = false;
        });

        instance.init();
        timer.start();
    }

    private init(): void {
        this.initSelectedNewsSlider();

        this.newsListItems.forEach((newsListItem, index) => {
            const card = querySelector(
                '.js-main-big-news-news-list-item-card',
                newsListItem,
            );

            if (!card) {
                return;
            }

            addEventListener(card, 'click', (event) => {
                event.preventDefault();

                if (this.selectedNewsSlider) {
                    this.selectedNewsSlider.timer.stop();
                    this.selectedNewsSlider.timer.start();
                }
                this.changeActiveNewsListItem(index);
                if (this.selectedNewsSlider) {
                    this.selectedNewsSlider.instance.slideTo(this.activeSlideIndex);
                }
            });
        });
    }

    private changeActiveNewsListItem(newIndex: number) {
        const card = querySelector(
            '.js-main-big-news-news-list-item-card',
            this.newsListItems[newIndex],
        );

        if (!card || !this.selectedNewsSlider) {
            return;
        }

        this.newsListItems.forEach((newsListItem) => {
            const oldCard = querySelector(
                '.js-main-big-news-news-list-item-card',
                newsListItem,
            );

            if (!oldCard) {
                return;
            }

            oldCard.classList.remove(newsListItemActiveClass);
        });

        this.activeSlideIndex = newIndex;
        card.classList.add(newsListItemActiveClass);
    }

    public update(): void {
        if (this.selectedNewsSlider) {
            const { instance } = this.selectedNewsSlider;
            if (instance) {
                instance.update();
            }
        }
    }
}

export default MainBigNews;
