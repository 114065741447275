import Pagination, { Options, Events } from './Pagination';
import { ElementsType } from '../Component';
import { addEventListener, querySelector } from '../../utils/dom';
import { HIDDEN_CLASS } from '../../constants';

class LoadByButtonPagination extends Pagination {
    protected actionButton: HTMLButtonElement;

    protected actionButtonWrap: HTMLElement;

    constructor(el: ElementsType, options: Options = {} as Options) {
        super(el, options);

        const actionButton = querySelector<HTMLButtonElement>('.js-pagination-btn', el);

        if (!actionButton) {
            throw new Error('Action button is not found.');
        }

        this.actionButton = actionButton;
        this.actionButtonWrap = querySelector<HTMLElement>('.js-pagination-btn-wrap', el) || actionButton;

        addEventListener(this.actionButton, 'click', (event: Event): void => {
            event.preventDefault();

            if (this.isHardLocked() || !this.getUrl()) {
                return;
            }

            this.incrementPage();
            this.load().catch(() => {
                this.decrementPage();
            });
        });

        this.trigger(Events.initialized);
    }

    public reset(): this {
        super.reset();
        this.showActionButton();
        return this;
    }

    public setLastPage(): this {
        super.setLastPage();
        this.hideActionButton();
        return this;
    }

    public setNoLastPage(): this {
        super.setNoLastPage();
        this.showActionButton();
        return this;
    }

    protected showActionButton(): this {
        this.actionButtonWrap.classList.remove(HIDDEN_CLASS);
        return this;
    }

    protected hideActionButton(): this {
        this.actionButtonWrap.classList.add(HIDDEN_CLASS);
        return this;
    }
}

export default LoadByButtonPagination;
