import type { ElementsType } from 'components/Component';
import type { PollFormBlockOptions } from './PollFormBlock';
import PollFormBlock from './PollFormBlock';
import { MunicipalRegions } from './constants';

const municipalRegions = new Set(Object.keys(MunicipalRegions));

export type ChangeEventPayload = {
    isMunicipal: boolean;
    value: string;
};

export const Events = {
    change: 'change',
} as const;

class LiveDistrictBlock extends PollFormBlock {
    constructor(element: ElementsType, options: PollFormBlockOptions = {} as PollFormBlockOptions) {
        super(element, options);

        this.el.addEventListener('change', (event: Event) => {
            const { target } = event;
            if (!(target instanceof HTMLInputElement)) {
                return;
            }

            const { value } = target;
            const isMunicipal = municipalRegions.has(value);
            this.trigger(Events.change, {
                isMunicipal,
                value,
            });
        });
    }
}

export default LiveDistrictBlock;
