import Swiper from 'swiper';
import { FreeMode, Scrollbar } from 'swiper/modules';
import { querySelector } from '../utils/dom';
import Component, { ElementsType } from './Component';

class TableResponsive extends Component {
    private instance!: Swiper;

    constructor(el: ElementsType) {
        super(el);

        this.initSlider();
    }

    protected initSlider(): void {
        /*
        Варианты расположения:
        - табы
        - основной контент
        */
        const tableParent = this.el.parentNode as HTMLElement;
        if (
            tableParent.classList.contains('table-responsive')
            || this.el.classList.contains('js-table-responsive-wrapped')
        ) {
            return;
        }

        const root = document.createElement('div');
        root.classList.add('table-responsive');
        root.innerHTML = `
            <div class="table-responsive__slider swiper js-table-responsive-slider">
                <div class="swiper-wrapper">
                    <div class="swiper-slide table-responsive__slide js-slide">
                    </div>
                </div>
                <div class="swiper-scrollbar"></div>
            </div>
        `;
        const slider = querySelector<HTMLElement>('.js-table-responsive-slider', root);
        if (!slider) {
            throw new Error('Error: element ".js-table-responsive-slider" not found.');
        }

        const slide = querySelector<HTMLElement>('.js-slide', root);
        if (!slide) {
            throw new Error('Unknown error.');
        }

        const scrollbar = querySelector<HTMLElement>('.swiper-scrollbar', this.el);
        tableParent.insertBefore(root, this.el);
        slide.append(this.el);
        this.el.classList.add('js-table-responsive-wrapped');

        this.instance = new Swiper(slider, {
            slidesPerView: 'auto',
            spaceBetween: 0,
            mousewheel: true,
            loop: false,
            centeredSlides: false,
            modules: [Scrollbar, FreeMode],
            scrollbar: {
                el: scrollbar,
            },
            freeMode: {
                sticky: false,
                momentumRatio: 0.2,
            },
        });
    }
}

export default TableResponsive;
