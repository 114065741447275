import _throttle from 'lodash/throttle';
import Swiper from 'swiper';
import Component, { ElementsType, Options } from './Component';
import { querySelector, addEventListener } from '../utils/dom';
import { isMobile, isTablet } from '../utils/screen';
import { TABLET_MIN_WIDTH } from '../constants';

class MainNews extends Component<Options> {
    private sliderInstance!: Swiper | null;

    constructor(el: ElementsType) {
        super(el);

        this.sliderInstance = null;

        this.init();
    }

    public initSlider(sliderContainer: HTMLElement): void {
        this.sliderInstance = new Swiper(sliderContainer, {
            slidesPerView: 'auto',
            spaceBetween: 8,
            loop: false,
            centeredSlides: false,
            breakpoints: {
                [TABLET_MIN_WIDTH]: {
                    spaceBetween: 16,
                },
            },
        });
    }

    private init(): void {
        const sliderContainer = querySelector<HTMLElement>(
            '.js-main-news-slider',
            this.el,
        );

        if (!sliderContainer) {
            return;
        }

        addEventListener(window, 'resize', _throttle(() => {
            if (isMobile() || isTablet()) {
                if (this.sliderInstance === null) {
                    this.initSlider(sliderContainer);
                }
            } else if (this.sliderInstance !== null) {
                this.sliderInstance.destroy(true, true);
                this.sliderInstance = null;
            }
        }, 300));

        if (isMobile() || isTablet()) {
            this.initSlider(sliderContainer);
        }
    }
}

export default MainNews;
