import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';
import {
    querySelector,
    querySelectorAll,
    addEventListener,
} from '../utils/dom';
import {
    DESKTOP_MAX_WIDTH,
    DESKTOP_MIN_WIDTH,
    MOBILE_MIN_WIDTH,
    TABLET_MIN_WIDTH,
} from '../constants';

export enum Events {
    'initialized' = 'initialized',
    'clickItem' = 'clickItem',
}
export type Options = {
    events: {
        [Events.initialized]?: () => void;
        [Events.clickItem]?: () => void;
    };
};

export const defaultOptions: Options = {
    events: {},
};

class HorizontalGallery extends Component<Options> {
    private sliderInstance!: Swiper;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.initThumbSlider();
    }

    public getCurrentSlide(): HTMLElement {
        return this.sliderInstance.slides[this.sliderInstance.activeIndex];
    }

    protected initThumbSlider(): void {
        const items = querySelectorAll<HTMLElement>(
            '.js-horizontal-gallery-slider-item',
            this.el,
        );
        const nextButton = querySelector<HTMLElement>(
            '.js-horizontal-gallery-control-next',
            this.el,
        );
        const prevButton = querySelector<HTMLElement>(
            '.js-horizontal-gallery-control-prev',
            this.el,
        );
        const fraction = querySelector<HTMLElement>(
            '.js-horizontal-gallery-fraction',
            this.el,
        );
        const sliderContainer = querySelector<HTMLElement>(
            '.js-horizontal-gallery-slider',
            this.el,
        );
        if (!sliderContainer) {
            return;
        }

        addEventListener(items, 'click', (event: Event): void => {
            event.preventDefault();

            this.trigger(Events.clickItem, { el: event.currentTarget });
        });

        this.sliderInstance = new Swiper(sliderContainer, {
            slidesPerView: 'auto',
            spaceBetween: 12,
            loop: false,
            centeredSlides: false,
            modules: [Navigation, Pagination],
            height: 184,
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
                disabledClass: 'horizontal-gallery__control--disabled',
            },
            pagination: {
                el: fraction,
                type: 'fraction',
                renderFraction(currentClass: string, totalClass: string): string {
                    return `
                        <span class="${currentClass} horizontal-gallery__counter-current"></span>
                        <span class="horizontal-gallery__counter-split">/</span>
                        <span class="${totalClass} horizontal-gallery__counter-total"></span>
                    `;
                },
            },
            breakpoints: {
                [MOBILE_MIN_WIDTH]: {
                    spaceBetween: 12,
                    height: 184,
                },
                [TABLET_MIN_WIDTH]: {
                    spaceBetween: 16,
                    height: 396,
                },
                [DESKTOP_MIN_WIDTH]: {
                    spaceBetween: 16,
                    height: 476,
                },
                [DESKTOP_MAX_WIDTH]: {
                    spaceBetween: 24,
                    height: 496,
                },
            },
            on: {
                init: () => {
                    this.trigger(Events.initialized);
                },
            },
        });
    }
}

export default HorizontalGallery;
