import type { ElementsType } from 'components/Component';
import type { PollFormBlockOptions } from './PollFormBlock';
import PollFormBlock from './PollFormBlock';

export type ChangeEventPayload = {
    isNegative: boolean;
};

export const Events = {
    change: 'change',
} as const;

/**
    1 - вполне удовлетворен
    2 - скорее удовлетворен
    3 - скорее не удовлетворен
    4 - совершенно не удовлетворен
    5 - затрудняюсь ответить
 */

const negativeAnswers = new Set(['3', '4']);

class SatisfactionBlock extends PollFormBlock {
    constructor(element: ElementsType, options: PollFormBlockOptions = {} as PollFormBlockOptions) {
        super(element, options);

        this.el.addEventListener('change', (event: Event) => {
            const { target } = event;
            if (!(target instanceof HTMLInputElement)) {
                return;
            }

            const { value } = target;
            const isNegative = negativeAnswers.has(value);
            this.trigger(Events.change, {
                isNegative,
            });
        });
    }
}

export default SatisfactionBlock;
