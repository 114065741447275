import Component, { ElementsType } from './Component';
import { htmlInstance, LoadingResponse } from '../utils/ajax';

class EmbedSvgFile extends Component {
    private url: string;

    constructor(el: ElementsType) {
        super(el);

        const url = el.getAttribute('data-svg-url');

        if (!url) {
            throw new Error('Empty attribute "data-svg-url".');
        }

        this.url = url;

        this.init();
    }

    protected init(): void {
        htmlInstance.get(this.url)
            .then((response: LoadingResponse<string>) => {
                this.el.innerHTML += response.data;
            });
    }
}

export default EmbedSvgFile;
