import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';
import {
    querySelector,
    addEventListener,
    addEventListenerLoadedPage,
} from '../utils/dom';
import {
    setLocalStorageItem,
    getLocalStorageItem,
} from '../utils/helpers';

type Options = {
    hiddenCookieClass: string;
};

export const defaultOptions: Options = {
    hiddenCookieClass: 'cookie--hidden',
};

class Cookie extends Component<Options> {
    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.init();
    }

    public show(): void {
        this.el.classList.remove(this.options.hiddenCookieClass);
    }

    public hide(): void {
        this.el.classList.add(this.options.hiddenCookieClass);
    }

    protected init(): void {
        addEventListenerLoadedPage(() => {
            const cookieValue = getLocalStorageItem('cookie');
            const currentDate = Date.now();

            if (cookieValue && currentDate < Date.parse(cookieValue)) {
                return;
            }

            this.show();

            const cookieBtn = querySelector<HTMLElement>(
                '.js-cookie-btn',
                this.el,
            );

            addEventListener(cookieBtn, 'click', (event: Event): void => {
                event.preventDefault();

                const cookieExpirationDate = new Date(Date.now() + 86400 * 14e3);

                setLocalStorageItem('cookie', cookieExpirationDate.toUTCString());
                this.hide();
            });
        });
    }
}

export default Cookie;
