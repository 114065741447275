import _defaultsDeep from 'lodash/defaultsDeep';
import _throttle from 'lodash/throttle';
import Component, { ElementsType } from './Component';
import { addEventListener } from '../utils/dom';
import { HIDDEN_CLASS } from '../constants';

export type Options = {
    btnHiddenClass: string;
};

export const defaultOptions: Options = {
    btnHiddenClass: HIDDEN_CLASS,
};

class ScrollUp extends Component<Options> {
    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const scrollBtn = this.el;

        addEventListener(window, 'scroll', _throttle(() => {
            if (document.body.scrollTop > 220 || document.documentElement.scrollTop > 220) {
                scrollBtn.classList.remove(options.btnHiddenClass);
            } else {
                scrollBtn.classList.add(options.btnHiddenClass);
            }
        }, 300));

        addEventListener(scrollBtn, 'click', (event: Event): void => {
            event.preventDefault();

            const root = document.documentElement;

            if (!root.scrollIntoView) {
                root.scrollTop = 0;
                return;
            }

            try {
                root.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            } catch (error) {
                root.scrollIntoView(true);
            }
        });
    }
}

export default ScrollUp;
