import _defaultsDeep from 'lodash/defaultsDeep';
import Swiper from 'swiper';
import Component, { ElementsType } from './Component';
import {
    querySelector,
    querySelectorAll,
    addEventListener,
} from '../utils/dom';

export const Events = {
    clickItem: 'clickItem',
} as const;

export type Options = {
    activeTabClass: string;
    events: {
        [Events.clickItem]?: () => void;
    };
};

export const defaultOptions: Options = {
    activeTabClass: 'tab--active',
    events: {},
};

class Tabs extends Component<Options> {
    private sliderInstance: Swiper;

    private items: HTMLElement[];

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const sliderContainer = querySelector<HTMLElement>(
            '.js-tabs-slider',
            this.el,
        );
        if (!sliderContainer) {
            throw new Error('Container of tabs not found.');
        }

        this.items = querySelectorAll<HTMLElement>(
            '.js-tabs-slider-item',
            this.el,
        ) as HTMLElement[];

        this.sliderInstance = new Swiper(sliderContainer, {
            slidesPerView: 'auto',
            spaceBetween: 8,
            loop: false,
            centeredSlides: false,
            focusableElements: undefined,
        });

        this.init();
    }

    protected init(): void {
        addEventListener(this.items, 'click', (event: Event): void => {
            const currentItem = <HTMLElement>event.currentTarget;
            const itemId = currentItem.getAttribute('data-tabs-id');
            const itemActionPrevent = currentItem.getAttribute('data-tabs-no-prevent') !== 'true';
            const noAction = currentItem.getAttribute('data-tabs-no-action') === 'true';

            if (itemActionPrevent) {
                event.preventDefault();
            }

            if (currentItem.classList.contains(this.options.activeTabClass)) {
                return;
            }

            if (!noAction) {
                this.items.forEach((item) => item.classList.remove(this.options.activeTabClass));
                currentItem.classList.add(this.options.activeTabClass);

                this.trigger(Events.clickItem, { item: currentItem, id: itemId });
            }
        });
    }

    public goto(index: number): this {
        if (this.items[index]) {
            this.items[index].dispatchEvent(new Event('click'));
        }

        return this;
    }

    public destroySlider(): this {
        if (this.sliderInstance) {
            this.sliderInstance.destroy(true);
        }

        return this;
    }
}

export default Tabs;
