import Component, { Options } from '../Component';
import { querySelector, querySelectorAll } from '../../utils/dom';

import PollFormBlock from './PollFormBlock';
import type { ChangeEventPayload as LiveDistrictChangeEventPayload } from './LiveDistrictBlock';
import LiveDistrictBlock, { Events as LiveDistrictBlockEvents } from './LiveDistrictBlock';
import type { ChangeEventPayload as SatisfactionChangeEventPayload } from './SatisfactionBlock';
import SatisfactionBlock, { Events as SatisfactionBlockEvents } from './SatisfactionBlock';
import NegativeReasonsBlock from './NegativeReasonsBlock';
import OrganizationsBlock from './OrganizationsBlock';

class PollForm extends Component {
    private prevButton: HTMLButtonElement | null = null;

    private nextButton: HTMLButtonElement | null = null;

    private errorResetButton: HTMLButtonElement | null = null;

    private currentStep = 0;

    private blocks: PollFormBlock[] = [];

    constructor(element: HTMLFormElement, options: Options = {} as Options) {
        super(element, options);
        this.init();
    }

    protected init() {
        const elements = querySelectorAll('.poll-form-block', this.el);
        if (elements.length === 0) {
            throw new Error('Form doesn\'t include blocks');
        }

        this.blocks = elements.map((element, index) => {
            switch (index) {
                case 0:
                    return new LiveDistrictBlock(element, { index });
                case 5:
                case 7:
                case 9:
                case 11:
                case 13:
                case 15:
                case 17:
                    return new SatisfactionBlock(element, { index });
                case 6:
                case 8:
                case 10:
                case 12:
                case 14:
                case 16:
                case 18:
                case 27:
                    return new NegativeReasonsBlock(element, { index });
                case 22:
                    return new OrganizationsBlock(element, { index });
                default:
                    return new PollFormBlock(element, { index });
            }
        });

        this.prevButton = querySelector('.poll-form__button--previous', this.el);
        this.nextButton = querySelector('.poll-form__button--next', this.el);
        this.errorResetButton = querySelector('.poll-form__button--error-reset', this.el);

        if (!this.prevButton && !this.nextButton) {
            throw new Error('Form doesn\'t include navigation buttons');
        }

        this.prevButton?.addEventListener('click', this.goToPreviousStep);
        this.nextButton?.addEventListener('click', this.goToNextStep);

        this.el.addEventListener('reset', this.resetSuccessState);
        this.errorResetButton?.addEventListener('click', this.resetErrorState);

        // связь логики блоков
        const [
            // блок для выбора района проживания
            liveDistrictBlock,
            // блок для выбора населённого пункта
            livePointBlock,
        ] = this.blocks;
        const {
            17: gasSupplyBlock,
            18: negativeReasonsGasSupplyBlock,
            22: organizationsBlock,
        } = this.blocks;
        liveDistrictBlock.on(LiveDistrictBlockEvents.change, (...args) => {
            const payload: LiveDistrictChangeEventPayload = args[1];
            livePointBlock.toggle(payload.isMunicipal);
            // выбран Соболевский муниципальный район
            const isSobolevskyRegion = payload.value === '10';
            gasSupplyBlock.toggle(isSobolevskyRegion);
            negativeReasonsGasSupplyBlock.toggle(isSobolevskyRegion);
            organizationsBlock.toggle(!isSobolevskyRegion);
            if (organizationsBlock instanceof OrganizationsBlock) {
                organizationsBlock.setRegion(payload.value);
            }
        });

        // при выборе отрицательных ответов в первом блоке меняем видимость второго блока
        [
            [5, 6],
            [7, 8],
            [9, 10],
            [11, 12],
            [13, 14],
            [15, 16],
            [17, 18],
        ].forEach((item) => {
            const b1 = this.blocks[item[0]];
            const b2 = this.blocks[item[1]];
            b1.on(SatisfactionBlockEvents.change, (...args) => {
                const payload: SatisfactionChangeEventPayload = args[1];
                b2.toggle(payload.isNegative);
            });
        });

        this.updateView();
    }

    public destroy(): void {
        this.prevButton?.removeEventListener('click', this.goToPreviousStep);
        this.nextButton?.removeEventListener('click', this.goToNextStep);
    }

    public goToPreviousStep = () => {
        let nextBlock: PollFormBlock | undefined;
        do {
            nextBlock = this.blocks[--this.currentStep];
        } while (nextBlock.isDisabled());

        this.updateView();
        this.scrollToTopOfForm();
    };

    public goToNextStep = () => {
        const currentBlock = this.blocks[this.currentStep];

        const isBlockValid = currentBlock.isValid();
        if (!isBlockValid) {
            currentBlock.showError();
            this.scrollToTopOfForm();
            return;
        }

        if (this.isLastStep) {
            this.submit();
            return;
        }

        let nextBlock: PollFormBlock | undefined;
        do {
            nextBlock = this.blocks[++this.currentStep];
        } while (nextBlock.isDisabled());

        this.updateView();
        this.scrollToTopOfForm();
    };

    scrollToTopOfForm() {
        this.el.scrollIntoView?.({
            block: 'start',
        });
    }

    async submit() {
        const isAllBlocksFilled = this.blocks
            .filter((block) => block.isEnabled())
            .every((block) => block.isValid());

        if (!isAllBlocksFilled) {
            return;
        }

        const form = this.el as HTMLFormElement;
        try {
            const response = await fetch(form.action, {
                method: form.method || 'post',
                body: new FormData(form),
            });
            if (!response.ok) {
                throw new Error('Form sending error');
            }
            form.classList.add('poll-form--success');
        } catch (error) {
            form.classList.add('poll-form--error');
        }
    }

    resetSuccessState = () => {
        this.resetResultState();
        this.currentStep = 0;
        this.updateView();
        (this.el as HTMLFormElement).reset();
    };

    resetErrorState = () => {
        this.el.classList.remove('poll-form--error');
    };

    resetResultState() {
        this.el.classList.remove('poll-form--success', 'poll-form--error');
    }

    get isFirstStep() {
        return this.currentStep === 0;
    }

    get isLastStep() {
        return this.currentStep === this.blocks.length - 1;
    }

    updateView() {
        this.prevButton?.classList.toggle('poll-form__button--hidden', this.isFirstStep);
        this.nextButton?.classList.toggle('poll-form__button--is-last-step', this.isLastStep);

        this.blocks.forEach((block, index) => {
            block.toggleVisibility(index === this.currentStep);
        });
    }
}

export default PollForm;
