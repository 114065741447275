// городские округа
export const CityDistricts = {
    1: 'Петропавловск-Камчатский',
    2: 'Вилючинск (жилые районы Приморский, Рыбачий)',
    3: 'Палана',
} as const;

// муниципальные районы
export const MunicipalRegions = {
    4: 'Алеутский',
    5: 'Быстринский',
    6: 'Елизовский',
    7: 'Карагинский',
    8: 'Мильковский',
    9: 'Олюторский',
    10: 'Соболевский',
    11: 'Пенжинский',
    12: 'Тигильский',
    13: 'Усть-Камчатский',
    14: 'Усть-Больщерецкий',
} as const;
