import _isFunction from 'lodash/isFunction';
import _isElement from 'lodash/isElement';
import _isArrayLike from 'lodash/isArrayLike';
import _isPlainObject from 'lodash/isPlainObject';
import _defaultsDeep from 'lodash/defaultsDeep';

export type EventType = (...args: any[]) => any;
export type Events = Record<string, EventType>;
export type Options<T = Record<string | number, unknown>> = T & {
    events?: Events;
};
export type ElementsType = HTMLElementTagNameMap[keyof HTMLElementTagNameMap];
export type InitOptions<T = Options> = {
    elements?: ElementsType | ElementsType[] | NodeListOf<ElementsType> | null;
    options?: T;
    optionsModifier?: (el: ElementsType, options: T) => {
        options: T;
        element: ElementsType;
    };
};

export const defaultOptions: Options = {
    events: {},
};

class Component<T extends Options = Options> {
    protected events: Map<string, Set<EventType>>;

    public el: ElementsType;

    public options: T;

    constructor(el: ElementsType, options: T = defaultOptions as T) {
        this.el = el;
        this.options = _defaultsDeep(options, defaultOptions);
        this.events = new Map();

        const { events } = this.options;

        if (_isPlainObject(events)) {
            Object.keys(events as Events).forEach((name) => {
                this.on(name, (events as Events)[name]);
            });
        }
    }

    public on(name: string, fn: EventType): this {
        if (!this.events.has(name)) {
            this.events.set(name, new Set());
        }

        const eventsByName = <Set<EventType>>(this.events.get(name));
        eventsByName.add(fn);

        return this;
    }

    public off(name: string, fn?: EventType): this {
        if (!this.events.has(name)) {
            return this;
        }

        if (!_isFunction(fn)) {
            this.events.delete(name);
            return this;
        }

        const eventsByName = <Set<EventType>>(this.events.get(name));
        eventsByName.delete(fn as EventType);

        return this;
    }

    public trigger(name: string, data?: unknown, context: Component = this): void {
        if (!this.events.has(name)) {
            return;
        }

        const eventsByName = <Set<EventType>>(this.events.get(name));
        eventsByName.forEach((fn) => {
            if (!_isFunction(fn)) {
                return;
            }

            fn(context, data);
        });
    }

    public destroy() {
        throw new TypeError('Method "destroy" does not exist.');
    }

    static init<T = Options, R = Component>(options: InitOptions<T>): R[] {
        const { elements, options: initOptions = ({} as T), optionsModifier } = options;
        let instances = [];

        if (_isElement(elements)) {
            const res = optionsModifier && _isFunction(optionsModifier)
                ? optionsModifier(elements as ElementsType, initOptions)
                : { element: elements, options: initOptions };
            instances.push(new this(res.element as ElementsType, res.options || {}));
        }

        if (_isArrayLike(elements)) {
            Array.prototype.forEach.call(elements, (el) => {
                const res = optionsModifier && _isFunction(optionsModifier)
                    ? optionsModifier(el as ElementsType, initOptions)
                    : { element: el, options: initOptions };
                instances.push(new this(res.element as ElementsType, res.options || {}));
            });
        }

        return instances as R[];
    }
}

export default Component;
