import _isElement from 'lodash/isElement';
import _isArrayLike from 'lodash/isArrayLike';

type EventListenerList = keyof HTMLElementEventMap
| keyof WindowEventMap
| keyof DocumentEventMap;

function removeEventListener<K extends EventListenerList>(
    data: Document
    | Window
    | NodeListOf<HTMLElementTagNameMap[keyof HTMLElementTagNameMap]>
    | HTMLElementTagNameMap[keyof HTMLElementTagNameMap]
    | HTMLElementTagNameMap[keyof HTMLElementTagNameMap][]
    | null
    | undefined,
    type: K,
    fn: (event: Event | KeyboardEvent) => void | boolean,
    options?: any,
): void {
    if (data === document) {
        data.removeEventListener(type, fn, options);
    } else if (data === window) {
        data.removeEventListener(type, fn, options);
    } else if (_isArrayLike(data) && !_isElement(data)) {
        (data as HTMLElementTagNameMap[keyof HTMLElementTagNameMap][])
            .forEach((el: HTMLElement): void => {
                if (!_isElement(el)) {
                    throw new TypeError('el is not element');
                }

                el.removeEventListener(type, fn, options);
            });
    } else if (_isElement(data)) {
        (data as HTMLElementTagNameMap[keyof HTMLElementTagNameMap])
            .removeEventListener(type, fn, options);
    }
}

function addEventListener<K extends EventListenerList>(
    data: Document
    | Window
    | NodeListOf<HTMLElementTagNameMap[keyof HTMLElementTagNameMap]>
    | HTMLElementTagNameMap[keyof HTMLElementTagNameMap]
    | HTMLElementTagNameMap[keyof HTMLElementTagNameMap][]
    | null
    | undefined,
    type: K,
    fn: (event: Event | KeyboardEvent | SubmitEvent | MouseEvent | StorageEvent) => void | boolean,
    options?: any,
): void {
    if (data === document) {
        data.addEventListener(type, fn, options);
    } else if (data === window) {
        data.addEventListener(type, fn, options);
    } else if (_isArrayLike(data) && !_isElement(data)) {
        (data as HTMLElementTagNameMap[keyof HTMLElementTagNameMap][])
            .forEach((el: HTMLElement): void => {
                if (!_isElement(el)) {
                    throw new TypeError('el is not element');
                }

                el.addEventListener(type, fn, options);
            });
    } else if (_isElement(data)) {
        (data as HTMLElementTagNameMap[keyof HTMLElementTagNameMap])
            .addEventListener(type, fn, options);
    }
}

function querySelectorAll<
    T extends HTMLElementTagNameMap[keyof HTMLElementTagNameMap] = HTMLElement,
>(
    selector: string,
    rootEl?: Document | HTMLElementTagNameMap[keyof HTMLElementTagNameMap]
): T[];

function querySelectorAll<
    T extends HTMLElementTagNameMap[keyof HTMLElementTagNameMap] = HTMLElement,
>(
    selector: string,
    rootEl?: Document | HTMLElementTagNameMap[keyof HTMLElementTagNameMap],
    asArray?: true
): T[];

function querySelectorAll<
    T extends HTMLElementTagNameMap[keyof HTMLElementTagNameMap] = HTMLElement,
>(
    selector: string,
    rootEl?: Document | HTMLElementTagNameMap[keyof HTMLElementTagNameMap],
    asArray?: false
): NodeListOf<T>[];

function querySelectorAll<
    T extends HTMLElementTagNameMap[keyof HTMLElementTagNameMap] = HTMLElement,
>(
    selector: string,
    rootEl: Document | HTMLElementTagNameMap[keyof HTMLElementTagNameMap] = document,
    asArray = true,
): T[] | NodeListOf<T> {
    const elementsElList = rootEl.querySelectorAll<T>(selector);
    return asArray ? [].slice.apply(elementsElList) : elementsElList;
}

function querySelector<
    T extends HTMLElementTagNameMap[keyof HTMLElementTagNameMap] = HTMLElement,
>(
    selector: string,
    rootEl: Document | HTMLElementTagNameMap[keyof HTMLElementTagNameMap] = document,
): T | null {
    return rootEl.querySelector(selector);
}

function closest<
    T extends HTMLElementTagNameMap[keyof HTMLElementTagNameMap] = HTMLElement,
>(
    selector: string,
    childEl: HTMLElementTagNameMap[keyof HTMLElementTagNameMap],
): T | null {
    return childEl.closest(selector);
}

function insertAfter(
    referenceNode: HTMLElementTagNameMap[keyof HTMLElementTagNameMap],
    newNode: HTMLElementTagNameMap[keyof HTMLElementTagNameMap],
): void {
    if (!referenceNode.parentNode) {
        return;
    }

    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function addEventListenerLoadedPage(fn: () => void | boolean): void {
    addEventListener(document, 'DOMContentLoaded', fn);
}

function horizontalScrollTo(
    targetEl: Document | HTMLElement,
    behavior: ScrollBehavior = 'auto',
) {
    const parentEl = <HTMLElement>targetEl.parentNode;
    if (parentEl && ('offsetLeft' in targetEl)) {
        parentEl.scrollBy({
            left: targetEl.offsetLeft - parentEl.offsetLeft + parentEl.scrollLeft,
            behavior,
        });
    }
}

const strToHtml = (str: string): HTMLCollection => {
    const wrap = document.createElement('div');
    wrap.innerHTML = str;
    return wrap.children;
};

export {
    addEventListener,
    removeEventListener,
    querySelectorAll,
    querySelector,
    closest,
    insertAfter,
    addEventListenerLoadedPage,
    horizontalScrollTo,
    strToHtml,
};
