import _isElement from 'lodash/isElement';
import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';
import { addEventListener, querySelector } from '../utils/dom';

export const Events = {
    change: 'change',
    beforeChange: 'beforeChange',
} as const;

export type Options = {
    toggleClass: string | string[];
    target: string | HTMLElement;
    auto: boolean;
    events: {
        [Events.change]?: () => void;
    };
};

export const defaultOptions: Options = {
    toggleClass: 'toggled',
    target: 'body',
    auto: false,
    events: {},
};

class ToggleClass extends Component<Options> {
    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.init();
    }

    public toggle(): this {
        const { toggleClass } = this.options;
        const target = this.getTarget();

        if (!target) {
            return this;
        }

        this.trigger(Events.beforeChange, {
            contains: this.contains(),
        });

        if (Array.isArray(toggleClass)) {
            toggleClass.forEach((toggleClassItem) => {
                target.classList.toggle(toggleClassItem);
            });
        } else {
            target.classList.toggle(toggleClass);
        }

        this.trigger(Events.change, {
            contains: this.contains(),
        });

        return this;
    }

    public add(): this {
        const { toggleClass } = this.options;
        const target = this.getTarget();

        if (!target) {
            return this;
        }

        this.trigger(Events.beforeChange, {
            contains: this.contains(),
        });

        if (Array.isArray(toggleClass)) {
            target.classList.add(...toggleClass);
        } else {
            target.classList.add(toggleClass);
        }

        this.trigger(Events.change, {
            contains: this.contains(),
        });

        return this;
    }

    public remove(): this {
        const { toggleClass } = this.options;
        const target = this.getTarget();

        if (!target) {
            return this;
        }

        this.trigger(Events.beforeChange, {
            contains: this.contains(),
        });

        if (Array.isArray(toggleClass)) {
            target.classList.remove(...toggleClass);
        } else {
            target.classList.remove(toggleClass);
        }

        this.trigger(Events.change, {
            contains: this.contains(),
        });

        return this;
    }

    public contains(): boolean {
        const { toggleClass } = this.options;
        const target = this.getTarget();

        if (!target) {
            return false;
        }

        if (Array.isArray(toggleClass)) {
            return toggleClass.every(
                (toggleClassItem) => target.classList
                    .contains(toggleClassItem),
            );
        }

        return target.classList.contains(toggleClass);
    }

    protected init(): void {
        if (this.options.auto) {
            addEventListener(this.el, 'click', (event: Event): void => {
                event.preventDefault();

                this.toggle();
            });
        }
    }

    protected getTarget(): HTMLElement | null {
        const { target: targetSelector } = this.options;
        if (_isElement(targetSelector)) {
            return targetSelector as HTMLElement;
        }

        if (typeof targetSelector === 'string') {
            return querySelector<HTMLElement>(targetSelector);
        }

        return null;
    }
}

export default ToggleClass;
