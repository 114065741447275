export const MOBILE_MIN_WIDTH = 300;
export const MOBILE_MAX_WIDTH = 767;
export const TABLET_MIN_WIDTH = 768;
export const TABLET_MAX_WIDTH = 1365;
export const DESKTOP_MIN_WIDTH = 1366;
export const DESKTOP_MAX_WIDTH = 1920;
export const LAYOUT_WIDTH = 1856;
export const HIDDEN_CLASS = 'd-none';
export const TRANSPARENT_CLASS = 'd-transparent';
export const OVERFLOW_HIDDEN_CLASS = 'overflow-hidden';
export const MODAL_CONTAINER = '.modal-container';
export const SPEC_V_BOX_SHOWN_CLASS = 'spec-v-box-shown';
