abstract class AbstractValidator {
    protected message = 'Ошибка.';

    public setMessage(message: string): this {
        this.message = message;

        return this;
    }

    public getMessage(): AbstractValidator['message'] {
        return this.message;
    }

    public abstract validate(value?: unknown): string | true;
}

export default AbstractValidator;
