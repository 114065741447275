class MyLocalStorage {
    protected _instance;

    protected readonly _alias;

    constructor(alias = '') {
        this._instance = window.localStorage;
        this._alias = alias;
    }

    set(name: string, value: string): this {
        window.localStorage.setItem(`${this._alias}${name}`, value);
        return this;
    }

    get(name: string, defaultValue: string | null = null): string | null {
        if (!this.has(name)) {
            return defaultValue;
        }
        return window.localStorage.getItem(`${this._alias}${name}`);
    }

    has(name: string): boolean {
        return window.localStorage.getItem(`${this._alias}${name}`) !== null;
    }
}

export default MyLocalStorage;
