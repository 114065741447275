import Required from './components/forms/validation/rules/Required';

const validatorsMap = new Map<string, any>();
validatorsMap.set('required', Required);

const schemas: Map<string, (boolean | Record<string, any>)> = new Map();
schemas.set('main', {});
schemas.set('none', {});
schemas.set('test', {
    text_1: {
        required: true,
    },
    text_2: {
        required: true,
    },
    checkbox: {
        required: true,
    },
    radio: {
        required: true,
    },
    select_1: {
        required: true,
    },
    select_2: {
        required: true,
    },
    select_3: {
        required: true,
    },
    date_2: {
        required: true,
    },
});
schemas.set('assessment', {
    radio1: {
        required: {
            enable: true,
            message: 'Поставьте оценку',
        },
    },
});

export { schemas, validatorsMap };
