import _defaultsDeep from 'lodash/defaultsDeep';
import _throttle from 'lodash/throttle';
import Component, { ElementsType } from '../Component';
import { querySelector } from '../../utils/dom';
import {
    jsonInstance,
    LoadingResponse as BaseLoadingResponse,
} from '../../utils/ajax';
import { baseUrl } from '../../utils/helpers';

export const Events = {
    initialized: 'initialized',
    beforeLoad: 'beforeLoad',
    afterLoad: 'afterLoad',
    afterRequest: 'afterRequest',
} as const;

export type Options = {
    sendLockTime?: number;
    events: {
        [Events.initialized]?: () => void | string;
        [Events.beforeLoad]?: () => void | string;
        [Events.afterLoad]?: () => void | string;
        [Events.afterRequest]?: () => void | string;
    };
};

export const defaultOptions: Options = {
    sendLockTime: 300,
    events: {},
};

type UrlParamsAsObject = { [key in string]: string | number | boolean | UrlParamsAsObject };
type UrlParams = UrlParamsAsObject | FormData;

type LoadingResponseData = {
    success: boolean;
    html: string;
    pagination: {
        is_last_page: boolean;
        html?: string;
    };
};
type LoadingResponse = BaseLoadingResponse<LoadingResponseData>;

abstract class Pagination<T extends Options = Options> extends Component<T> {
    protected url: string;

    protected page: number;

    protected pageName: string;

    protected httpGetParams: UrlParams;

    protected targetContainer: HTMLElement;

    protected statuses = {
        isLoading: false,
        isLocked: false,
        isLastPage: false,
    };

    protected lastResponseData: LoadingResponseData | null;

    protected requestController: AbortController;

    protected sendViaAjaxCallback: ((clearAfterRequest: boolean) => void) | null;

    constructor(el: ElementsType, options: T = defaultOptions as T) {
        const normalizedOptions: T = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const {
            paginationUrl: url = '',
            paginationTargetContainer: targetContainerSelector = '',
            paginationPageName: pageName = 'page',
            paginationInitIsLastPage: initIsLastPage = 'false',
            paginationInitPage: initPage = '1',
        } = el.dataset;

        const targetContainer = querySelector<HTMLElement>(targetContainerSelector, el);

        if (!targetContainer) {
            throw new Error('Target block for pagination results is not found.');
        }

        this.targetContainer = targetContainer;

        this.url = url;

        this.page = initPage.length ? parseInt(initPage, 10) : 1;

        this.pageName = pageName;

        this.httpGetParams = {};

        this.lastResponseData = null;

        this.requestController = new AbortController();

        this.sendViaAjaxCallback = null;

        this.on(Events.initialized, () => {
            if (initIsLastPage === 'true') {
                this.setLastPage();
            }
        });
    }

    public async load(clearContainer = false): Promise<void> {
        if (this.sendViaAjaxCallback === null) {
            this.sendViaAjaxCallback = _throttle(async (clearAfterRequest: boolean = false) => {
                if (this.isLocked() || this.isLoading() || !this.getUrl()) {
                    throw new Error('Locked.');
                }

                this.requestController.abort();
                this.requestController = new AbortController();

                this.trigger(Events.beforeLoad);

                this.setLoading();

                try {
                    const queryGetParams = this.generateUrlParams();
                    const action = this.getAction();

                    const response: LoadingResponse = await jsonInstance.get(
                        `${action.origin}${action.pathname}`,
                        {
                            params: queryGetParams,
                            signal: this.requestController.signal,
                        },
                    );

                    this.trigger(Events.afterRequest);

                    const result = response.data;
                    this.lastResponseData = result;

                    if (!result.success) {
                        this.setNoLoading();
                        throw new Error('Error loading.');
                    }

                    if (result.pagination.is_last_page) {
                        this.setLastPage();
                    } else {
                        this.setNoLastPage();
                    }

                    if (clearAfterRequest) {
                        this.targetContainer.innerHTML = '';
                    }

                    this.targetContainer.insertAdjacentHTML('beforeend', result.html);
                } catch (err) {
                    console.log(err.message);
                    this.setNoLoading();
                    throw new Error('Error loading.');
                }

                this.setNoLoading();

                this.trigger(Events.afterLoad);
            }, this.options.sendLockTime, { trailing: true });
        }

        if (this.sendViaAjaxCallback) {
            await this.sendViaAjaxCallback(clearContainer);
        }
    }

    public incrementPage(): this {
        ++this.page;
        return this;
    }

    public decrementPage(): this {
        --this.page;
        return this;
    }

    public setHttpGetParams(obj: UrlParams = {}): this {
        if (obj instanceof FormData) {
            this.httpGetParams = obj;
        } else {
            const formData = new FormData();

            Object.entries(obj).forEach(([key, value]) => {
                formData.append(key, String(value));
            });

            this.httpGetParams = formData;
        }

        return this;
    }

    public getPage(): number {
        return this.page;
    }

    public setPage(page: number): this {
        this.page = page;
        return this;
    }

    public reset(): this {
        this.setPage(1);
        this.setNoLastPage();
        this.unlock();
        return this;
    }

    public setLastPage(): this {
        this.statuses.isLastPage = true;
        return this;
    }

    public setNoLastPage(): this {
        this.statuses.isLastPage = false;
        return this;
    }

    public isLastPage(): boolean {
        return this.statuses.isLastPage;
    }

    public setLoading(): this {
        this.statuses.isLoading = true;
        return this;
    }

    public setNoLoading(): this {
        this.statuses.isLoading = false;
        return this;
    }

    public isLoading(): boolean {
        return this.statuses.isLoading;
    }

    public lock(): this {
        this.statuses.isLocked = true;
        return this;
    }

    public unlock(): this {
        this.statuses.isLocked = false;
        return this;
    }

    public isLocked(): boolean {
        return this.statuses.isLocked;
    }

    public isHardLocked(): boolean {
        return this.statuses.isLocked || this.isLoading() || this.isLastPage();
    }

    public generateUrlParams(): URLSearchParams {
        const action = this.getAction();
        const queryGetParams = new URLSearchParams(action.search);

        queryGetParams.append(this.getPageName(), String(this.getPage()));

        if (this.httpGetParams instanceof FormData) {
            this.httpGetParams.forEach((value, key) => {
                if (value !== '') {
                    queryGetParams.append(key, String(value));
                }
            });
        }

        return queryGetParams;
    }

    public getUrl(): string {
        return this.url || '';
    }

    public getAction(): URL {
        return new URL(this.getUrl(), baseUrl());
    }

    public getPageName(): string {
        return this.pageName;
    }
}

export default Pagination;
