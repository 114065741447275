import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from '../../Component';
import { querySelector, querySelectorAll, closest } from '../../../utils/dom';

export const Events = {
    initialized: 'initialized',
    change: 'change',
    reset: 'reset',
    changeDisabled: 'changeDisabled',
} as const;

const ErrorPlace = {
    form: 'form',
    group: 'group',
    element: 'element',
} as const;

export const AllowedTypes = {
    switch: 'switch',
    text: 'text',
    textarea: 'textarea',
    select: 'select',
    checkbox: 'checkbox',
    radio: 'radio',
    flagGroup: 'flagGroup',
    date: 'date',
    smartSearch: 'smartSearch',
    search: 'search',
} as const;

export type AllowedTypesKeys = keyof typeof AllowedTypes;
export type AllowedTypesValues = typeof AllowedTypes[AllowedTypesKeys];

export type Options = {
    id: string;
    form: HTMLElement | null,
    formGroupClass: string;
    errorClass: string;
    errorMessageClass: string;
    errorsContainerClass: string;
    errorFormClass: string;
    errorsCommonContainerClass: string;
    events: {
        [Events.initialized]?: () => void;
        [Events.change]?: () => void;
        [Events.reset]?: () => void;
    };
};

export const defaultOptions: Options = {
    id: '',
    form: null,
    formGroupClass: 'js-form-group',
    errorClass: 'field--error',
    errorMessageClass: 'form-group__error',
    errorsContainerClass: 'js-field-errors-container',
    errorsCommonContainerClass: 'js-form-common-errors-container',
    errorFormClass: 'form--error',
    events: {},
};

abstract class AbstractFormField<T extends Options = Options> extends Component<T> {
    protected error: string | null;

    protected changed: boolean;

    protected formGroup: HTMLElement | null;

    protected errorContainer: HTMLElement | null;

    constructor(el: ElementsType, options: T = defaultOptions as T) {
        const normalizedOptions: T = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.error = null;
        this.changed = false;
        this.formGroup = closest<HTMLElement>(
            `.${this.options.formGroupClass}`,
            this.el,
        );

        const { formFieldErrorPlace = ErrorPlace.group } = this.el.dataset;

        switch (formFieldErrorPlace) {
            case ErrorPlace.form: {
                this.errorContainer = querySelector<HTMLElement>(
                    `.${this.options.errorsCommonContainerClass}`,
                    this.options.form || this.el,
                );
                break;
            }

            case ErrorPlace.element: {
                this.errorContainer = querySelector<HTMLElement>(
                    `.${this.options.errorsContainerClass}`,
                    this.el,
                );
                break;
            }

            case ErrorPlace.group:
            default: {
                this.errorContainer = querySelector<HTMLElement>(
                    `.${this.options.errorsContainerClass}`,
                    this.formGroup || this.el,
                );
                break;
            }
        }

        const form = options.form
            ?? (this.el.querySelector<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(
                'input, textarea, select',
            ))?.form;

        form?.addEventListener('reset', () => {
            this.trigger(Events.reset);
        });

        this.on(Events.reset, () => {
            this.changed = true;
        });

        this.on(Events.change, () => {
            this.changed = true;
        });
    }

    public setError(error: AbstractFormField['error'] = null): this {
        this.error = error || null;

        if (this.error) {
            this.el.classList.add(this.options.errorClass);

            if (this.errorContainer) {
                const id = this.getId();
                const el = document.createElement('div');
                el.classList.add(this.options.errorMessageClass);
                el.innerHTML = this.error;
                el.dataset.formFieldErrorFieldId = id;
                const errorsList = querySelectorAll<HTMLElement>(
                    `[data-form-field-error-field-id="${id}"]`,
                    this.errorContainer,
                );
                errorsList.forEach((errorsListItem) => errorsListItem.remove());
                this.errorContainer.append(el);
            }

            if (this.options.form) {
                this.options.form.classList.add(this.options.errorFormClass);
            }
        } else {
            if (this.errorContainer) {
                const id = this.getId();
                const errorsList = querySelectorAll<HTMLElement>(
                    `[data-form-field-error-field-id="${id}"]`,
                    this.errorContainer,
                );
                errorsList.forEach((errorsListItem) => errorsListItem.remove());
            }

            if (this.options.form) {
                this.options.form.classList.remove(this.options.errorFormClass);
            }

            this.el.classList.remove(this.options.errorClass);
        }

        return this;
    }

    public getError(): AbstractFormField['error'] {
        return this.error;
    }

    public clearError(): this {
        this.setError();
        return this;
    }

    public hasErrorStatus(): boolean {
        return this.el.classList.contains(this.options.errorClass);
    }

    public getId(): string {
        return this.options.id
            || this.el.id
            || this.el.dataset.formFieldId
            || this.getName();
    }

    public isChanged(): AbstractFormField['changed'] {
        return this.changed;
    }

    public abstract getName(): string;
    public abstract getValue(): string | string[] | [string, string][];
    public abstract setValue(value: string | string[] | [string, string][]): this;
    public abstract getType(): AllowedTypesValues;
    public abstract disabled(): this;
    public abstract notDisabled(): this;
    public abstract isDisabled(): boolean;
}

export default AbstractFormField;
