import _throttle from 'lodash/throttle';
import _defaultsDeep from 'lodash/defaultsDeep';
import Component, { ElementsType } from './Component';
import { querySelectorAll, addEventListener, querySelector } from '../utils/dom';
import { isMobile, isTablet } from '../utils/screen';

export type Options = {
    defaultList: string;
    resetBtnClass: string;
    controlClass: string;
    levelClass: string;
    currentListClass: string;
};

export const defaultOptions: Options = {
    defaultList: 'root',
    resetBtnClass: 'js-mobile-modal-reset',
    levelClass: 'js-mobile-modal-menu-level',
    controlClass: 'js-mobile-modal-menu-nav-go-to-list-btn',
    currentListClass: 'mobile-modal-menu__level--current',
};

class MobileMultilevelMenu extends Component<Options> {
    protected reseted: boolean;

    constructor(el: ElementsType, options: Options = defaultOptions) {
        const normalizedOptions: Options = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        this.reseted = false;

        const controlButtons = querySelectorAll<HTMLElement>(`.${this.options.controlClass}`);

        addEventListener(controlButtons, 'click', (event: Event): void => {
            event.preventDefault();

            const targetEl = event.currentTarget as HTMLElement;
            const listTargetId = targetEl.dataset.mobileModalMenuListIdTarget || '';

            this.goto(listTargetId);
        });

        const resetButtons = querySelectorAll<HTMLElement>(`.${this.options.resetBtnClass}`);

        addEventListener(resetButtons, 'click', (event: Event): void => {
            event.preventDefault();

            this.reset();
        });

        addEventListener(window, 'resize', _throttle(() => {
            if (!isMobile() && !isTablet() && !this.reseted) {
                if (!this.reseted) {
                    this.reset();
                    this.reseted = true;
                }
            } else if ((isMobile() || isTablet()) && this.reseted) {
                this.reseted = false;
            }
        }, 300));
    }

    public goto(id: string): void {
        const list = querySelector<HTMLElement>(
            `.${this.options.levelClass}[data-mobile-modal-menu-list-id="${id}"]`,
            this.el,
        );

        const prevList = querySelector<HTMLElement>(
            `.${this.options.currentListClass}`,
            this.el,
        );

        if (
            (!list || !prevList)
            || (list === prevList)
        ) {
            return;
        }

        prevList.classList.remove(this.options.currentListClass);
        list.classList.add(this.options.currentListClass);
    }

    public reset(): void {
        this.goto(this.options.defaultList);
    }
}

export default MobileMultilevelMenu;
