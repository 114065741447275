import mixitup from 'mixitup';
import Component, { Options } from './Component';
import { querySelector, querySelectorAll } from '../utils/dom';
import { baseUrl } from '../utils/helpers';

class News extends Component<Options> {
    constructor(el: HTMLElement, options: Options = {}) {
        super(el, options);

        this.init();
    }

    public makeNewsBtnUrl(categoryId: string): void {
        const newsBtn = querySelector<HTMLElement>(
            '.js-news-btn',
            this.el,
        );

        if (!newsBtn) {
            return;
        }

        const newsBtnUrl = new URL(newsBtn.getAttribute('href') || '', baseUrl());
        const queryGetParams = new URLSearchParams(newsBtnUrl.search);

        if (queryGetParams.has('category')) {
            queryGetParams.delete('category');
        }

        if (categoryId !== '0') {
            queryGetParams.append('category', categoryId);
        }

        const urlGetParams = queryGetParams.toString();

        const fullUrl = urlGetParams
            ? `${newsBtnUrl.origin}${newsBtnUrl.pathname}?${urlGetParams}`
            : `${newsBtnUrl.origin}${newsBtnUrl.pathname}`;

        newsBtn.setAttribute('href', fullUrl);
    }

    protected init(): void {
        const news = querySelectorAll<HTMLElement>(
            '.js-news-item',
            this.el,
        );

        if (!news) {
            return;
        }

        const newsSections = querySelectorAll<HTMLElement>(
            '.js-news-section',
            this.el,
        );

        mixitup(this.el, {
            callbacks: {
                onMixStart() {
                    if (!newsSections) {
                        return;
                    }

                    newsSections.forEach((newsSection) => {
                        if (newsSection.classList.contains('d-none')) {
                            newsSection.classList.remove('d-none');
                        }
                    });
                },
                onMixEnd() {
                    if (!newsSections) {
                        return;
                    }

                    newsSections.forEach((newsSection) => {
                        const newsSectionItems = querySelectorAll<HTMLElement>(
                            '.js-news-item',
                            newsSection,
                        );

                        const isHideSection = [...newsSectionItems].every((item) => item.style.display === 'none');

                        if (isHideSection) {
                            newsSection.classList.add('d-none');
                        }
                    });
                },
            },
        });
    }
}

export default News;
