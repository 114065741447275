import type { ElementsType, Options } from '../Component';
import Component from '../Component';

export type PollFormBlockOptions = Options<{
    index: number; // порядковый номер в цепочке блоков-шагов формы
}>;

/**
 * Базовый класс для работы с одним шагом формы
 */
class PollFormBlock extends Component<PollFormBlockOptions> {
    index = 0;

    private errorMessage: HTMLElement | null;

    constructor(el: ElementsType, options: PollFormBlockOptions) {
        super(el, options);
        this.index = options.index;
        this.errorMessage = el.querySelector('.poll-form-block__error');
        this.el.addEventListener('change', this.onChange);
    }

    public destroy(): void {
        this.el.removeEventListener('change', this.onChange);
    }

    hide() {
        this.el.hidden = true;
    }

    show() {
        this.el.hidden = false;
    }

    isHidden() {
        return this.el.hidden;
    }

    toggleVisibility(flag: boolean) {
        if (flag) {
            this.show();
        } else {
            this.hide();
        }
    }

    enable() {
        this.el.classList.remove('poll-form-block--disabled');
        this.enableControls(true);
    }

    disable() {
        this.el.classList.add('poll-form-block--disabled');
        this.enableControls(false);
    }

    toggle(flag: boolean) {
        if (flag) {
            this.enable();
        } else {
            this.disable();
        }
    }

    isDisabled() {
        return this.el.classList.contains('poll-form-block--disabled');
    }

    isEnabled() {
        return !this.isDisabled();
    }

    enableControls(flag: boolean) {
        this.el.querySelectorAll<HTMLFieldSetElement>('fieldset.poll-form__group').forEach((element) => {
            element.disabled = !flag;
        });
    }

    isValid() {
        const inputs = this.el.querySelectorAll('input, textarea, select');
        const form = document.createElement('form');
        inputs.forEach((input) => {
            const clonedInput = input.cloneNode(true);
            form.appendChild(clonedInput);
        });
        const formData = new FormData(form);
        return [...formData].some(([, value]) => value);
    }

    showError() {
        if (this.errorMessage) {
            this.errorMessage.hidden = false;
        }
    }

    hideError() {
        if (this.errorMessage) {
            this.errorMessage.hidden = true;
        }
    }

    onChange = () => {
        this.hideError();
    };
}

export default PollFormBlock;
