import _defaultsDeep from 'lodash/defaultsDeep';
import _first from 'lodash/first';
import Component, { ElementsType } from '../Component';
import BaseContentFilter, { Events as BaseContentFilterEvents } from './BaseContentFilter';
import Pagination, { Events as PaginationEvents } from '../pagination/Pagination';
import LoadByButtonPagination from '../pagination/LoadByButtonPagination';
import LoadByScrollPagination from '../pagination/LoadByScrollPagination';
import LoadByNavbarPagination from '../pagination/LoadByNavbarPagination';
import { querySelector, querySelectorAll } from '../../utils/dom';
import { AllowedResponseTypes } from '../forms/instances/BaseForm';

export const Events = {
    initialized: 'initialized',
} as const;

export type Options = {
    pagination?: Pagination,
    contentFilter?: BaseContentFilter,
    events: {
        [Events.initialized]?: () => void | string;
    };
};

export const ChangeUrl = {
    none: 'none',
    'only-filter': 'only-filter',
    all: 'all',
} as const;

export const defaultOptions: Options = {
    events: {},
};

class ContentWithFilter<T extends Options = Options> extends Component<T> {
    protected contentFilter: Options['contentFilter'];

    protected pagination: Options['pagination'];

    protected resultContainer: HTMLElement;

    protected changeUrl: keyof typeof ChangeUrl;

    protected initialUrl: URL;

    protected filterChanged: boolean;

    constructor(el: ElementsType, options: T = defaultOptions as T) {
        const normalizedOptions: T = _defaultsDeep(options, defaultOptions);

        super(el, normalizedOptions);

        const {
            contentWithFilterResultContainer: resultContainerSelector = '.js-result-container',
            contentWithFilterChangeUrl: changeUrl = ChangeUrl.none,
        } = this.el.dataset as {
            contentWithFilterResultContainer: string;
            contentWithFilterChangeUrl: keyof typeof ChangeUrl;
        };
        const resultContainer = querySelector<HTMLElement>(
            resultContainerSelector,
            el,
        );
        if (!resultContainer) {
            throw new Error('Result container not found.');
        }

        this.resultContainer = resultContainer;
        this.filterChanged = false;
        this.pagination = this.options.pagination || this.makeDefaultPagination();
        this.contentFilter = this.options.contentFilter || this.makeDefaultContentFilter();
        this.changeUrl = changeUrl;
        this.initialUrl = new URL(window.location.pathname, window.location.origin);

        if (this.contentFilter) {
            this.contentFilter.on(BaseContentFilterEvents.submit, (form: BaseContentFilter) => {
                if (this.pagination) {
                    if (this.pagination.isLocked() || this.pagination.isLoading()) {
                        this.filterChanged = true;
                    } else {
                        this.pagination.reset();
                        this.pagination.load(true);
                    }
                } else {
                    form.send();
                    this.updatePageUrl();
                }
            });
            this.contentFilter.on(
                BaseContentFilterEvents.endSend,
                (form: BaseContentFilter, response) => {
                    const result = response.data;
                    switch (form.getResponseType()) {
                        case AllowedResponseTypes.html: {
                            this.resultContainer.innerHTML = result;
                            break;
                        }

                        case AllowedResponseTypes.json:
                        default: {
                            this.resultContainer.innerHTML = result.html;
                        }
                    }
                },
            );
        }

        if (this.pagination) {
            this.pagination.on(PaginationEvents.beforeLoad, (pagination: Pagination) => {
                if (this.contentFilter) {
                    const formData = this.contentFilter.getFormData();
                    pagination.setHttpGetParams(formData);
                }
            });

            this.pagination.on(PaginationEvents.afterLoad, (pagination: Pagination) => {
                this.updatePageUrl();

                if (this.filterChanged) {
                    this.filterChanged = false;
                    pagination.reset();
                    pagination.load(true);
                }

                if (pagination instanceof LoadByNavbarPagination) {
                    pagination.updateNavbar();
                }
            });
        }

        this.trigger(Events.initialized);
    }

    public getPagination(): Options['pagination'] {
        return this.pagination;
    }

    protected makeDefaultPagination(): Options['pagination'] {
        let pagination: Pagination | undefined = _first(
            LoadByScrollPagination.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-content-filter-pagination-of-list-by-scroll',
                    this.el,
                ),
            }),
        );

        if (!pagination) {
            pagination = _first(
                LoadByButtonPagination.init({
                    elements: querySelectorAll<HTMLElement>(
                        '.js-content-filter-pagination-of-list-by-button',
                        this.el,
                    ),
                }),
            );
        }

        if (!pagination) {
            pagination = _first(
                LoadByNavbarPagination.init({
                    elements: querySelectorAll<HTMLElement>(
                        '.js-content-filter-pagination-of-list-by-navbar',
                        this.el,
                    ),
                }),
            );
        }

        return pagination;
    }

    protected makeDefaultContentFilter(): Options['contentFilter'] {
        return _first(
            BaseContentFilter.init({
                elements: querySelectorAll<HTMLElement>(
                    '.js-content-filter',
                    this.el,
                ),
            }),
        );
    }

    protected updatePageUrl(): void {
        if (this.changeUrl === ChangeUrl.none) {
            return;
        }

        const newUrl = new URL(this.initialUrl);
        const queryGetParamsFromNewUrl = new URLSearchParams(newUrl.search);

        if ([ChangeUrl.all, ChangeUrl['only-filter']].includes(this.changeUrl)) {
            if ((this.changeUrl === ChangeUrl.all) && this.pagination) {
                const page = this.pagination.getPage();
                const pageName = this.pagination.getPageName();
                queryGetParamsFromNewUrl.set(pageName, String(page));
            }

            if (this.contentFilter) {
                const formData = this.contentFilter.getFormData();
                const contentFilterGetParams = new URLSearchParams(formData as any);

                const allKeys = new Set(contentFilterGetParams.keys());

                contentFilterGetParams.forEach((value, name) => {
                    if (allKeys.has(name)) {
                        allKeys.delete(name);
                        queryGetParamsFromNewUrl.delete(name);
                    }
                    if (value !== '') {
                        queryGetParamsFromNewUrl.append(name, value);
                    }
                });
            }
        }

        newUrl.hash = this.initialUrl.hash;
        newUrl.search = queryGetParamsFromNewUrl.toString();

        window.history.replaceState({}, '', newUrl.toString());
    }

    // <div class="js-content-with-filter-change-page-marker"></div>
}

export default ContentWithFilter;
